//import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgregarProveedor } from "../../../Apis/FetchCatalogos";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Proveedor", successText: 'Proveedor agregado correctamente.' }

export const initValuesAp = {
    clave: "8030",
    empresa: "",
    calle: "",
    colonia: "",
    ciudad: "",
    cp: "",
    contacto: "",
    telefono: ""
};

export async function ImprimirContrato() {
    console.log("clic imprimir contrato proveedor: ");
}

export async function CrearProveedor(values) {
    const datosProv = {
        clave: values.clave,
        empresa: values.empresa,
        trial_calle_3: values.calle,
        colonia: values.colonia,
        ciudad: values.ciudad,
        cp: values.cp,
        telefono: values.telefono,
        fax: "",
        contacto: values.contacto,
        especial: "",
        mail: "",
        trial_web_12: ""
    };
    //await sleep(5000);
    await AgregarProveedor(datosProv);
    return true;
}


export const urlCatalogo = "/suvalsa/catalogos/proveedores";
