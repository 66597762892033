import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import UseCatPersonal from "./UsePersonal";
import useCatalogo from "../../../Hooks/UseCatalogos";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import CustomModal from "../../../componentes/Modals/CustomModal";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoPersonal = () => {
  const {
    tituloCatAgentes,
    columnsData,
    opcionesTabla,
    GetAgentes,
    idModalDelete,
    pathAltaPersonal,
    pathCatPersonal,
    actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  } = UseCatPersonal();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: personal,
    setData: setPersonal,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetAgentes,
    deleteData: actionDelete,
    idModalDelete,
    pathNavTo: pathAltaPersonal,
    pathNavBack: pathCatPersonal,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <CardNavigate title={tituloCatAgentes}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={personal}
            setData={setPersonal}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            id={idModalDelete}
            title={"Eliminar Agente"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar el Agente con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}??</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoPersonal;
