// Initialization for ES Users
import { useEffect, useState } from "react";
import { Select } from "tw-elements";
import { createOption } from "../utils/CommonUtils";

const useServicios = (selectServId=undefined) => {
    const [gettingData, setGettingData] = useState(false);
    const [servs, setServs] = useState([]);

    async function getServicios() {
        return [
            {
                id: 4,
                clave_semarnat: "CB",
                name_semarnat: "Completo biologico"
            },
            {
                id: 5,
                clave_semarnat: "TB",
                name_semarnat: "Transporte Biologico"
            },
            {
                id: 6,
                clave_semarnat: "TS",
                name_semarnat: "Transporte Urbano"
            },
            {
                id: 7,
                clave_semarnat: "CI",
                name_semarnat: "Completo Industrial"
            },
            {
                id: 1,
                clave_semarnat: "TI",
                name_semarnat: "Transportista Industrial"
            }
        ];
    }

    function agSelectOptServ(servs) {
        return servs !== null
          ? servs.map((serv) =>
            createOption(serv.id, serv.clave_semarnat, serv.clave_semarnat + '  ' + serv.name_semarnat)
          )
          : "";
      }

    useEffect(() => {
        console.log("Onteniendo lista de Servicios::: ");
        async function listarServicios() {
            setGettingData(true);
            const listaServ = await getServicios(); //ObtenerServicios();
            console.log("listaServ ", listaServ);
            setServs(listaServ);
            setGettingData(false);
        }

        if (servs !== null && servs.length === 0) listarServicios();
    }, [servs]);

    useEffect(() => {
        console.log("Setting Selects::: ");
        if(selectServId){
            const selectServ = document.querySelector("#" + selectServId);
            if (selectServ) Select.getOrCreateInstance(selectServ);
        }
    }, []);

    return {
        getServicios,
        servs,
        agSelectOptServ,
        gettingData
    }
}

export default useServicios;