// LogoutConfirmModal.jsx
import React, { useEffect } from "react";
import { Modal, initTE } from "tw-elements";

export default function LogoutConfirmModal({ isOpen, onClose, onConfirm }) {
  useEffect(() => {
    initTE({ Modal });
  }, []);

  if (!isOpen) {
    return null; // No renderiza nada si no está abierto
  }

  return (
    <div
      id="logoutConfirmModal"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/30"
    >
      <div className="bg-white rounded-lg shadow-lg w-96 p-6">
        <h2 className="text-xl font-semibold mb-4">¿Cerrar Sesión?</h2>
        <p className="text-lg md:text-base text-gray-700 mb-6">Confirma si deseas cerrar la sesión actual</p>
        <div className="flex flex-col-reverse md:flex-row justify-end gap-4">
          <button
            onClick={onClose}
            className="button-base bg-gray-300 hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            onClick={onConfirm}
            className="button-base bg-red-600 text-white hover:bg-red-700"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
}
