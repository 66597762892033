import React from 'react'

const EstatusFactura = () => {
  return (
    <div>
      EstatusFactura
    </div>
  )
}

export default EstatusFactura
