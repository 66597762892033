import React from 'react'

const CatalogoPlantas = () => {
  return (
    <div>
      CatalogoPlantas
    </div>
  )
}

export default CatalogoPlantas
