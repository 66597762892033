import React, { useRef } from 'react';
import { InputText } from '../../../componentes/Formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppStore } from '../../../Stores/useAppStore';
import CircleSpiner from '../../../componentes/Spinners/CircleSpiner';
import { useExecutePromise } from '../../../Hooks/UsePromiseAction';

export default function ChangePassword() {
  const { updatePassword } = useAppStore();

  const { runAction, loadingAction: executeAction } = useExecutePromise();

  // Esquema de validación con Yup
  const validationSchema = Yup.object({
    contrasenaAct: Yup.string()

      .required('La contraseña actual es requerida')
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/,
        'La contraseña debe contener al menos una mayúscula, una minúscula, un número y un carácter especial'
      ),
    contrasenaNueva: Yup.string()
      .required('La contraseña nueva es requerida')
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/,
        'La contraseña debe contener al menos una mayúscula, una minúscula, un número y un carácter especial'
      ),
    confContrasena: Yup.string()
      .required('La confirmación de la contraseña es requerida')
      .oneOf([Yup.ref('contrasenaNueva'), null], 'Las contraseñas no coinciden'),
  });

  const title = "Actualización de Contraseña";
  const pendingText = 'Actualizando, por favor espere.';
  const successText = "Contraseña actualizada correctamente";
  const errorText = useRef("Ocurrió un error inesperado. Por favor reinténtalo más tarde.")


  // Función para manejar la actualización de la contraseña
  const handleUpdatePassword = async (values, { resetForm }) => {
    await runAction({
      promiseAction: async () => {
        //values.contrasenaAct
        // Llamada a la API para actualizar la contraseña
        const response = await updatePassword(values.contrasenaAct, values.contrasenaNueva);

        if (!response) {
          errorText.current = "Contraseña no actualizada. Por favor consulta con el administrador."
          throw new Error('Error al actualizar la contraseña');
          // Limpiar el formulario después de una actualización exitosa
        } else {
          //se resetean valores del formulario y errorText para no perder el mensaje generico de error
          resetForm();
          errorText.current = "Ocurrió un error inesperado. Por favor reinténtalo más tarde."
        }
      },
      title,
      pendingText,
      successText,
      errorText
    });
  };


  return (
    <>
      {(executeAction) && <CircleSpiner />}
      <div className="h-5/6 md:h-4/5 w-full flex flex-col p-2 md:p-4 items-center justify-center bg-transparent">

        <div className="bg-suva-azul-10 shadow-lg min-h-[75vh] md:min-h-[50vh] rounded-lg p-1 md:p-8 max-w-4xl w-full flex flex-col items-center space-y-2 md:space-y-4 md:space-x-8">
          <div className="w-full flex">
            <h1 className="text-2xl font-bold text-start p-2">Cambio de Contraseña</h1>
          </div>
          <Formik
            initialValues={{
              contrasenaAct: '',
              contrasenaNueva: '',
              confContrasena: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleUpdatePassword}
          >
            {(props) => {
              const { isSubmitting } = props;
              return (
                <Form className="flex flex-col w-full md:w-1/2 p-2 gap-2">
                  <InputText
                    disabled={isSubmitting}
                    label="Contraseña Actual"
                    name="contrasenaAct"
                    type="password"
                    placeholder=""
                  />
                  <InputText
                    disabled={isSubmitting}
                    label="Contraseña Nueva"
                    name="contrasenaNueva"
                    type="password"
                    placeholder=""
                  />
                  <InputText
                    disabled={isSubmitting}
                    label="Confirmar Contraseña"
                    name="confContrasena"
                    type="password"
                    placeholder=""
                  />

                  {/* Botón de cambiar contraseña */}
                  <div className="mt-4 md:mt-8 text-center md:text-left">
                    <button
                      type="submit"
                      className="btn-secondary"
                    >
                      Cambiar Contraseña
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}