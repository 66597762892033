import React from 'react'
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import CustomModal from "../../../componentes/Modals/CustomModal";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import useCatalogo from "../../../Hooks/UseCatalogos";
import useCatClientes from "./UseClientes";

const CatalogoClientes = () => {

  const{
    tituloCatClientes,
    idModalDelete,
    pathAltaCliente,
    pathCatClientes,
    columnsData,
    opcionesTabla,
    GetClientes,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  } = useCatClientes()

  const {
    loading,
    loadingAction,
    optionsTable,
    data: clientes,
    setData: setClientes,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetClientes,
    idModalDelete,
    pathNavTo: pathAltaCliente,
    pathNavBack: pathCatClientes,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <CardNavigate title={tituloCatClientes}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={clientes}
            setData={setClientes}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            title={"Eliminar Cliente"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar el cliente con la</span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoClientes;
