import React from 'react'

const CobranzaPendienteRuta = () => {
  return (
    <div>
      CobranzaPendienteRuta
    </div>
  )
}

export default CobranzaPendienteRuta
