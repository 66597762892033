import React from 'react'

const RecoleccionesDatos = () => {
  return (
    <div>
      RecoleccionesDatos
    </div>
  )
}

export default RecoleccionesDatos
