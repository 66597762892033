import React from 'react'
import SalidaAlmacenForm from './Altas/SalidasAlmacen.Form'
import CardNavigate from '../../../componentes/Cards/CardNavigate'

const SalidasAlmacen = () => {
  return (
    <CardNavigate title="Alta de Salida en Almacen">
      <SalidaAlmacenForm />
    </CardNavigate>
  )
}

export default SalidasAlmacen
