// schemaActualizarCliente.jsx
import * as Yup from "yup";

const MSJ_DATO_REQ = "Este campo es obligatorio";
const MSJ_EMAIL_INV = "Debe ser un correo electrónico válido";
// Agrega más mensajes según sea necesario

const schemaActualizarCliente = Yup.object().shape({
    clave: Yup.string().required(MSJ_DATO_REQ),
    fnombre: Yup.string().required(MSJ_DATO_REQ),
    regimenfiscal: Yup.string().required(MSJ_DATO_REQ),
    usocfdi: Yup.string().required(MSJ_DATO_REQ),
    correoelectronico: Yup.string().email(MSJ_EMAIL_INV),
    //.required(MSJ_DATO_REQ),
    segundocorreoelectronico: Yup.string().email(MSJ_EMAIL_INV),
    telefono: Yup.string(),
    //.matches(/^\d{10}$/, "Debe ser un número de teléfono válido de 10 dígitos")
    //.required(MSJ_DATO_REQ),
    contacto: Yup.string().required(MSJ_DATO_REQ),
    status: Yup.string().required(MSJ_DATO_REQ),
    refbanco: Yup.string().required(MSJ_DATO_REQ),
    // Agrega validaciones adicionales para otros campos según sea necesario
});



export default schemaActualizarCliente;