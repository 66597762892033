//import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgregarNumerosDatosCtrl } from "../../../Apis/FetchCatalogos";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Números y Datos de Control", successText: 'Número y Dato de Control agregado correctamente.' }

export const initValuesAdnc = {
    clave: "8030",
    status: "",
    notas: "",
    dato: "",
    concepto: "",
};

export async function ImprimirContrato() {
    console.log("clic imprimir contrato datos-y-numeros-control: ");
}

export async function CrearDatosNumerosControl(values) {
    const datosNoDatos = {
        clave: values.clave,
        status: values.status,
        notas: values.notas,
        dato: values.dato,
        concepto: values.concepto,
    };
    //await sleep(5000);
    await AgregarNumerosDatosCtrl(datosNoDatos);
    return true;
}


export const urlCatalogo = "/suvalsa/catalogos/datos-y-numeros-control";
