//import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgregarVehiculo } from "../../../Apis/FetchCatalogos";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Vehículo", successText: 'Vehículo agregado correctamente.' }

export const initValuesAv = {
    clave: "",
    marca: "",
    modelo: "",
    anio: "",
    placas: "",
    chasis: "",
    motor: "",
    noine: "",
    permisosct: "",
    sust: "",
    arrastre: "",
    carga: "",
    dimCaja: "",
    dimUnidad: "",
    terMarca: "",
    terModelo: "",
    terSerie: "",
    rampaMarca: "",
    rampaCap: "",
    rampaSerie: "",
    cajaMarca: "",
    cajaTipo: "",
    cajaCap: "",
    cajaAcc: "",
    hidrolavadora: ""
};

export async function ImprimirContrato() {
    console.log("clic imprimir contrato vehiculo: ");
}

export async function CrearVehiculo(values) {
    const datosVehic = {
        CLAVE: values.clave,
        MARCA: values.marca,
        MODELO: values.modelo,
        AÑO: values.anio,
        PLACAS: values.placas,
        CHASIS: values.chasis,
        MOTOR: values.motor,
        NOINE: values.noine,
        SCT: values.permisosct,
        SUSTANCIAS: values.sust,
        ARRASTRE: values.arrastre,
        CARGA: values.carga,
        DIMCAJA: values.dimCaja,
        DIMUNIDAD: values.dimUnidad,
        TERMOMARCA: values.terMarca,
        TERMOMOD: values.terModelo,
        TERMOSERIE: values.terSerie,
        RAMPAMARCA: values.rampaMarca,
        RAMPACAP: values.rampaCap,
        RAMPASERIE: values.rampaSerie,
        CAJAMARCA: values.cajaMarca,
        CAJATIPO: values.cajaTipo,
        CAJACAP: values.cajaCap,
        CAJAACC: values.cajaAcc,
        HIDROLAV: values.hidrolavadora
    };
    //await sleep(3000);
    await AgregarVehiculo(datosVehic);
    return true;
}


export const urlCatalogo = "/suvalsa/catalogos/vehiculos";
