import React from 'react'

const ResumenSaldos = () => {
  return (
    <div>
      ResumenSaldos
    </div>
  )
}

export default ResumenSaldos
