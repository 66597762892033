import React from 'react';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import logo_plantilla from "../../imagenes/logo_plantilla.png";

const Header = ({ familia, pageNumber, totalPages }) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

    return (
        <View style={styles.header}>
            <View style={styles.leftSection}>
                <Image style={styles.logo} src={logo_plantilla} />
                <View style={styles.headerText}>
                    <Text style={styles.title}>Sistema de Administración de Suvalsa</Text>
                    <Text style={styles.textSize}>Reporte de clientes por Familia: {familia}</Text>
                    <Text style={styles.textSize}>Este reporte ha sido generado el día: {formattedDate}</Text>
                </View>
            </View>
            <Text style={styles.pageNumber}>
                Página {pageNumber} de {totalPages}
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    leftSection: {
        flexDirection: 'row'
    },
    logo: {
        width: 80,
        height: 80
    },
    headerText: {
        marginLeft: 20,
        flexGrow: 1,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    textSize: {
        fontSize: 13
    },
    pageNumber: {
        fontSize: 12,
        alignSelf: 'flex-end', // Asegura que el número de página esté alineado a la derecha
    }
});

export default Header;
