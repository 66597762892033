import React from 'react'

const GastosAutoViaticos = () => {
  return (
    <div>
      GastosAutoViaticos
    </div>
  )
}

export default GastosAutoViaticos
