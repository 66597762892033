import React, { useMemo, useRef, useState } from "react";
import useManifiestos from "./useManifiestos";
import AltaManifiestosTable from "./AltaManifiestos.table";
import Accordion from "../../../componentes/Accordion/Accordion";
import { InputText } from "../../../componentes/Formik";
import { Form, Formik } from "formik";
import schemaAltaManifiestosYdocsVi from "../Schemas/SchemaAltaManifiestos";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CustomModal from "../../../componentes/Modals/CustomModal";
import AgregarInsumoForm from "./AgregarInsumo.Form";
import ConsultarSalidasTable from "./ConsultarSalidas.table";
import "react-toastify/dist/ReactToastify.css";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import { InputTextForm } from "../../../componentes/Formik/InputText";

const AltaManifiestosForm = () => {
    const [loadingPage, setLoadingPage] = useState(false);
    const {
        getDataFunction,
        BuscarRuta,
        loadingData,
        loadingAction,
        data,
        rowSelection,
        setRowSelection,
        columnFilters,
        setColumnFilters,
        dataRowSelected,
        setData,
        elaborarMasivo,
        lisatPaquetesInsumos,
        listaClientesMasivo, setListaClientesMasivo,
        idModalGenerarMasivos,
        idModalAgregarInsumo,
        idModalSalidas,
        addSalida,
        onSubmitAltaManifestos,
        buttonsGroup
    } = useManifiestos();

    const [clave, setClave] = useState();
    let fInicio = useRef("");
    let oper = useRef("");
    let aux = useRef("");
    let noEco = useRef("");
    let duracion = useRef("");
    let nombreRef = useRef("");

    const initValuesAdd = {
        ruta: "",
        nombre: "",
        dias: "",
        fInicio: "",
        oper: "",
        aux: "",
        noEco: "",
        operador: "",
        auxiliar: "",
        vehiculo: ""
    };

    const columnsMasiv = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "Id",
                enableColumnFilter: false
            },
            {
                accessorKey: "clave",
                header: "Clave",
                enableColumnFilter: true,
                filterFn: "includesString",
                size: 50,
                enableSorting: true
            },
            {
                accessorKey: "ruta",
                header: "Ruta",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "orden",
                header: "Orden",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "servicio",
                header: "Servicio",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "dia",
                header: "Día",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "fvisita",
                header: "Fecha visita",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "salida",
                header: "Salida",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
        ],
        []
    );

    async function refreshData(validateForm, setTouched, resetForm, clave, fechaIni) {
        setClave(clave)
        fInicio.current = fechaIni

        setLoadingPage(true);
        const initValuesRutaForm = {
            nombre: "",
            dias: "",
            oper: "",
            operador: "",
            aux: "",
            auxiliar: "",
            noEco: "",
            vehiculo: "",
            id: "",
        }
        const formErrors = await validateForm();
        if (!formErrors.clave && !formErrors.fInicio) {
            // No hay errores, ejecutar obtenerManifiestos
            await BuscarRuta(clave)
                .then(async (data) => {
                    nombreRef.current = data.nombre;
                    duracion.current = data.duracion
                    oper.current = data.oper
                    aux.current = data.aux
                    noEco.current = data.noEco
                    if (clave !== "" && data.id !== "") await getDataFunction(clave, fechaIni);
                    else setData([]);
                    // Actualizar initialValues con los valores recibidos para siguientes comparaciones
                    resetForm(
                        {
                            values: {
                                clave,
                                fInicio: fechaIni,
                                nombre: data.nombre,
                                dias: data.duracion,
                                oper: data.oper,
                                operador: data.operador,
                                aux: data.aux,
                                auxiliar: data.auxiliar,
                                noEco: data.noEco,
                                vehiculo: data.vehiculo,
                                id: data.id,
                            }
                        });
                });
        } else {
            //se limpia el formulario
            resetForm(
                {
                    values: {
                        ...initValuesRutaForm,
                        clave, fInicio: fechaIni
                    }
                })
            //se limpia la tabla
            setData([]);
            // Marcar todos los campos como tocados para mostrar errores
            setTouched({
                clave: true,
                fInicio: true,
                // nombre: true,
                // dias: true,
                // oper: true,
                // operador: true,
                // aux: true,
                // auxiliar: true,
                // noEco: true,
                // vehiculo: true,
            });
            // Mostrar notificación de error
            //toast.error("Por favor, corrige los errores en el formulario.");
        }
        setLoadingPage(false);
    }

    // useEffect(()=>{
    //     const isNotValidManifest = Object.keys(rowSelection).length > 0 &&
    //         (!dataRowSelected?.manifiesto &&
    //             !(
    //                 dataRowSelected?.servicio === "CB" ||
    //                 dataRowSelected?.servicio === "CI" ||
    //                 dataRowSelected?.servicio === "TI")
    //         ) ?  true : false
    //         if(isNotValidManifest){
    //         //se deben marcan cas casillas en rojo indicando que son incorrectas
    //             setData(prevData => {
    //     return prevData.map( row => {
    //       let newRow = {...row}
    //       let errors= {
    //         manifiesto: {
    //             hasError: false,
    //             message: '',
    //         },
    //       }
    //       if(!row.manifiesto || (
    //         row.servicio === "CB" || row.servicio === "CI" || row.servicio === "TI")
    //       ){
    //         errors.manifiesto.hasError = true
    //         errors.manifiesto.message = "Dato requerido."
    //         newRow= {
    //           ...row, 
    //           ['manifiesto']: '', ['errors']: errors
    //         }
    //       }
    //       return newRow
    //     })
    //     }
    //         )
    // }
    // },[rowSelection, dataRowSelected])

    return (
        <>
            {(loadingData || loadingAction || loadingPage) && <CircleSpiner />}
            <Formik
                initialValues={initValuesAdd}
                validationSchema={schemaAltaManifiestosYdocsVi}
                onSubmit={onSubmitAltaManifestos}
            >
                {(props) => {
                    const { isSubmitting, setFieldValue, validateForm, setTouched, resetForm, values } = props;

                    return (
                        <Form className="flex flex-col w-full md:p-2 gap-2">
                            <Accordion
                                titulo="Datos del Viaje"
                                idTarget="collapseManifDocsVForm"
                                expanded="true"
                            >
                                <div className="grid-3">
                                    <InputTextForm
                                        disabled={isSubmitting || loadingData}
                                        label="Clave ruta"
                                        name="clave"
                                        type="number"
                                        placeholder=""
                                        onBlurAction={
                                            async () => {
                                                await refreshData(validateForm, setTouched, resetForm, values.clave, values.fInicio)
                                            }
                                        }
                                        onKeyDownAction={
                                            async () => {
                                                await refreshData(validateForm, setTouched, resetForm, values.clave, values.fInicio)
                                            }
                                        }
                                    />
                                    <InputTextForm
                                        disabled={isSubmitting || loadingData}
                                        label="Fecha de Inicio"
                                        type="date"
                                        placeholder=""
                                        name="fInicio"
                                        onBlurAction={async () =>
                                            setFieldValue("fInicio", values.fInicio)
                                                .then(async () => await refreshData(validateForm, setTouched, resetForm, values.clave, values.fInicio)
                                                )
                                        }
                                        onKeyDownAction={
                                            async () => {
                                                await refreshData(validateForm, setTouched, resetForm, values.clave, values.fInicio)
                                            }
                                        }
                                    />
                                    <InputText
                                        disabled
                                        label="Nombre"
                                        name="nombre"
                                        type="text"
                                        placeholder=""
                                    />
                                    <InputText
                                        disabled
                                        label="Días"
                                        name="dias"
                                        type="number"
                                        placeholder=""
                                    />
                                    <InputText
                                        disabled={true}
                                        label="Operador"
                                        name="oper"
                                        type="text"
                                        placeholder=""
                                    />

                                    <InputText
                                        disabled={true}
                                        label="Nombre Operador"
                                        name="operador"
                                        type="text"
                                        placeholder=""
                                    />
                                    <InputText
                                        disabled={true}
                                        label="Auxiliar"
                                        name="aux"
                                        type="text"
                                        placeholder=""
                                    />
                                    <InputText
                                        disabled={true}
                                        label=""
                                        name="auxiliar"
                                        type="text"
                                        placeholder=""
                                    />
                                    <InputText
                                        disabled={true}
                                        label="No.Eco."
                                        name="noEco"
                                        type="text"
                                        placeholder=""
                                    />
                                    <InputText
                                        disabled={true}
                                        label="Vehículo"
                                        name="vehiculo"
                                        type="text"
                                        placeholder=""
                                    />
                                    <button
                                        disabled={isSubmitting}
                                        type="button"
                                        className="btn-secondary m-3"
                                        onClick={async () => {
                                            await refreshData(validateForm, setTouched, resetForm, values.clave, values.fInicio)
                                        }}
                                    >
                                        Buscar Manifiestos
                                    </button>
                                </div>
                            </Accordion>
                            <Accordion
                                titulo={`Manifiestos en la Ruta ${values.clave ?? ""}`}
                                idTarget="collapseClientesManifForm"
                                expanded={data.length > 0 ? "true" : "false"}
                                disabled={data.length <= 0}
                            >
                                <div className="flex flex-col w-full gap-2">
                                    <AltaManifiestosTable
                                        clave={clave}
                                        fInicio={fInicio}
                                        rutaData={{ duracion: duracion }}
                                        data={data}
                                        setData={setData}
                                        getData={getDataFunction}
                                        rowSelection={rowSelection}
                                        setRowSelection={setRowSelection}
                                        columnCustomFilters={columnFilters}
                                        setCustomColumnFilters={setColumnFilters}
                                    />
                                    <span className=" text-sm text-red-500 font-bold text-left">
                                        {Object.keys(rowSelection).length > 0 &&
                                            (!dataRowSelected?.manifiesto &&
                                                !(
                                                    dataRowSelected?.servicio === "CB" ||
                                                    dataRowSelected?.servicio === "CI" ||
                                                    dataRowSelected?.servicio === "TI")
                                            ) ? "* Por favor, captura el número de manifiesto correspondiente" : ""
                                        }
                                    </span>
                                </div>
                            </Accordion>
                            <ButtonGroup
                                buttonPrimary={buttonsGroup.buttonPrimary}
                                buttonSecondary={buttonsGroup.buttonSecondary}
                                buttonTertiary={buttonsGroup.getButtonTertiary(clave, fInicio)}
                                buttonCuatriary={buttonsGroup.buttonCuatriary}
                                buttonSextenary={buttonsGroup.buttonSextenary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                                buttonSevenary={buttonsGroup.buttonSevenary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                                buttonEightary={buttonsGroup.buttonEightary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                                buttonNovenarary={buttonsGroup.buttonNovenarary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                                buttonDecenary={buttonsGroup.buttonDecenary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                                buttonUndecenary={buttonsGroup.buttonUndecenary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                                buttonDuocenary={buttonsGroup.buttonDuocenary(clave, fInicio.current, nombreRef.current, duracion.current, oper.current, aux.current, noEco.current)}
                            />

                        </Form>
                    );
                }}
            </Formik>
            <CustomModal
                id={idModalAgregarInsumo}
                title={"Elaborar Manifiesto"}
                withForm
            >
                <AgregarInsumoForm
                    ruta={clave}
                    datosManif={{
                        noManifiesto: dataRowSelected?.manifiesto,
                        familia: dataRowSelected?.familia,
                        cliente: dataRowSelected?.clave,
                        orden: dataRowSelected?.orden,
                        servicio: dataRowSelected?.servicio,
                        fvisita: dataRowSelected?.fvisita,
                        planta: dataRowSelected?.planta,
                        rutaplanta: dataRowSelected?.rutaplanta
                    }}
                    datosViaje={{
                        fInicio: fInicio.current,
                        oper: oper.current,
                        aux: aux.current,
                        veh: noEco.current,
                        duracion: duracion.current
                    }}
                    actionUpdateTable={addSalida}
                    setLoading={setLoadingPage}
                    idModal={idModalAgregarInsumo}
                />
            </CustomModal>
            <CustomModal
                id={idModalGenerarMasivos}
                title={"Elaborar Manifiestos Masivos"}
                withForm
            >
                <CustomGenericTable
                    columnsData={columnsMasiv}
                    data={listaClientesMasivo}
                    optionsTable={{ ofText: "Clientes de" }}
                    setData={setListaClientesMasivo}
                />
                <div className="w-full grid flex-row md:flex md:flex-row-reverse md:justify-start gap-4">
                    <ButtonGroup
                        buttonPrimary={
                            {
                                action: () => elaborarMasivo(listaClientesMasivo, lisatPaquetesInsumos, fInicio.current, async () => {
                                    await getDataFunction(clave, fInicio.current)
                                }),
                                type: "button",
                                label: "Elaborar"
                            }
                        }
                    />
                </div>
            </CustomModal>
            <CustomModal
                id={idModalSalidas}
                title={`Salida de insumos - Clave ${dataRowSelected?.clave}`}
                withForm
            >
                <ConsultarSalidasTable
                    noSalida={dataRowSelected?.salida} noManifiesto={dataRowSelected?.manifiesto}
                    setLoading={setLoadingPage}
                />
            </CustomModal>
        </>
    );
};

export default AltaManifiestosForm;
