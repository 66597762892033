// useTable.js
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { includesString, betweenNumbers, equals } from "./filterFns"; // Asegúrate de que la ruta es correcta
import { handleCellEdit, handleCellEditValid, handleKeyDown } from "./virtualTable";
import { MemoInput } from "../components/Cell/CellInput";
import { MemoSelect } from "../components/Cell/CellSelect";
import CellInputValid from "../components/Cell/InputValid/CellInputValid";

export const useTable = ({
  isSelected = false,
  rowSelection,
  setRowSelection,
  setData,
  data,
  columns,
  defaultColumn,
  globalFilter,
  conditionalSelection,
  isMultiRowSelection,
  columnFilters,
  setColumnFilters, // Añadido para manejar cambios de filtro
  sorting,
  setSorting
}) => {
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [columnVisibility, setColumnVisibility] = useState({
    id: false // hide this column by default
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0, // initial page index
    pageSize: 1000, // default page size
  });

  const [rowDefaultSelection, setDefaultRowSelection] = useState({});

  // Crear un ref para 'data' y actualizarlo cuando 'data' cambie
  const dataTableRef = useRef(data);

  useEffect(() => {
    dataTableRef.current = data;
  }, [data]);

  /**
   * reCreateColumns
   * Se encarga de reconstruir las celdas de la tabla, para ello debe de validar las propiedades definidas en el objeto columnas
   * cell: indica que ya cuenta con una definicion propia de celda por lo que si viene esta propiedad no la cambia y la toma como llega
   * columnType: Permite saber que tipo de celda es y asi crear el elemento para la celda, si es select, crea un componente Select, de lo contrario es un input de tipo: number, text, date
   *            - Este parametro es requerido -
   * readOnly: Indica si es readOnly, si no viene, se toma en true y se crea un comonente no editable y por ende sin onChange
   *          de lo contrario si es false, se crea un Input con onChange ( handleCellEditTable ) para poder actualizar el valor en la tabla
   * Considerar que si readOnly = false, siempre se va a crearse un input con su onChange
   */
  const reCreateColumns = useCallback(() => {
    return columns.map(col => {
      //si no trae la celda predefinida, se carga dependiendo si es editable o no el campo
      if (!col.cell) {
        if (col.columnType === 'select') {
          return {
            ...col,
            cell: ({ column: { id: columnId }, row, getValue }) => (
              <MemoSelect
                options={col.selectOptions}
                value={col.selectOptions.find(option => option.value === getValue())?.value || ''}
                onChange={(value) => handleCellEditTable(setData, row.original.id, columnId, value)}
                onKeyDown={(e) => handleKeyDownTable(e, dataTableRef, table)}
              />
            )
          }
        }
        if (col.readOnly === undefined || col.readOnly) {
          return {
            ...col,
            cell: ({ getValue }) => (
              <MemoInput
                readOnly={col.readOnly ?? true}
                type={col.columnType}
                value={getValue() || ''}
                onKeyDown={(e) => handleKeyDownTable(e, dataTableRef, table)}
              />
            )
          }
        } else {
          return {
            ...col,
            cell: ({ column: { id: columnId }, row, getValue }) => {
              if (!col.withValidate)
                return (
                  <MemoInput
                    type={col.columnType}
                    min={col.min}
                    value={getValue() || ''}
                    onChange={(e) => handleCellEditTable(setData, row.original.id, columnId, e.target.value)}
                    onKeyDown={(e) => handleKeyDownTable(e, dataTableRef, table)}
                  />
                )
              else
                return (
                  <CellInputValid
                    columnId={columnId}
                    row={row}
                    type={col.columnType}
                    min={col.min}
                    value={getValue() || ''}
                    onKeyDown={(e) => handleKeyDownTable(e, dataTableRef, table)}
                    onChange={(e) => col.handleCellEditValid ? col.handleCellEditValid(row.original.id, columnId, e.target.value)
                      : handleCellEditValidTable(setData, col.columnType, row.original.id, columnId, e.target.value)}
                  />
                )
            }
          }
        }
      } else
        return col
    })
  }, [columns])

  /**
   * 
   */
  const customColumns = useMemo(() => {
    if (isSelected)
      return [
        {
          id: "select",
          // para multiselect
          header: ({ table }) =>
            isMultiRowSelection ? (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler()
                }}
              />
            ) : (
              <></>
            ),
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler()
                }}
              />
            </div>
          )
        },
        ...reCreateColumns()
      ];
    else {
      return reCreateColumns()
    }
  }, [columns, isMultiRowSelection, isSelected]);

  const table = useReactTable({
    data,
    columns: customColumns,
    defaultColumn: defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      rowSelection: rowSelection ?? rowDefaultSelection,
      columnVisibility,
      columnFilters, // Pasar columnFilters desde el padre
      sorting,
      pagination
    },
    onPaginationChange: setPagination, // Actualizar el estado de paginación
    enableMultiRowSelection: isMultiRowSelection ?? false,
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: (row) => conditionalSelection ? conditionalSelection(row) : true, // o habilitar la selección condicionalmente por fila
    onRowSelectionChange: setRowSelection ?? setDefaultRowSelection,
    autoResetPageIndex,
    enableSortingRemoval: false, // deshabilitar la capacidad de eliminar ordenamiento en columnas (siempre none -> asc -> desc -> asc)
    onSortingChange: setSorting, // Opcionalmente controlar el estado de sorting en tu propio scope para fácil acceso
    filterFns: {
      includesString: includesString, // Registrar la función de filtro para strings
      betweenNumbers: betweenNumbers, // Registrar la función de filtro para números
      equals: equals,
    },
    onColumnFiltersChange: setColumnFilters, // Añadir esta línea para actualizar el estado del padre
    meta: {
      updateData: (rowIndex, columnId, value, rowsData = undefined) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        if (rowsData) {
          setData(rowsData)
        } else {
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...(old[rowIndex] ?? []),
                  [columnId]: value
                };
              }
              return row;
            })
          );
        }
      }
    },
    debugTable: true
  });


  const handleKeyDownTable = useCallback(handleKeyDown, [table])
  const handleCellEditTable = useCallback(handleCellEdit, [setData])
  const handleCellEditValidTable = useCallback(handleCellEditValid, [setData])

  const getData = useCallback(() => {
    return table.getRowModel().rows
  }, [table])

  function addData(newData) {
    setData(prevState => ([
      ...prevState,
      ...newData
    ]));
  }

  return {
    table,
    getData,
    addData
  };
};

function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip];
}

export default useTable;
