import React, { useState } from "react";
import { getUsersByEmail } from "../../Apis/FetchUsers"; // Importa la función

const ResetPasswordModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState(""); // Estado para el correo
  const [password, setPassword] = useState(""); // Estado para la nueva contraseña
  const [confirmPassword, setConfirmPassword] = useState(""); // Estado para confirmar la contraseña
  const [loading, setLoading] = useState(false); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para mostrar/ocultar confirmación

  if (!isOpen) return null;

  const handleSubmit = async () => {
    setLoading(true);
    setError(null); // Limpiar errores previos

    try {
      const response = await getUsersByEmail(email); // Llama a la API
      if (response.dataEmail && response.dataEmail.length > 0) {
        console.log("Usuario encontrado:", response.dataEmail);
        alert("Se ha enviado un correo para restablecer la contraseña.");
        onClose(); // Cierra el modal tras el éxito
      } else {
        setError("No se encontró ningún usuario con ese correo."); // Manejo de error si no se encuentra el usuario
      }
    } catch (error) {
      setError("Ocurrió un error al procesar la solicitud. Inténtalo nuevamente."); // Error de la API
    } finally {
      setLoading(false); // Finalizar la carga
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-red-500">
          X
        </button>
        <h2 className="text-lg font-bold">Restablecer Contraseña</h2>
        <p>Ingresa tu correo para restablecer tu contraseña.</p>
        <input
          type="email"
          placeholder="Ingrese su correo"
          className="border rounded p-2 w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Actualiza el estado del correo
        />
        <p>Ingresa nueva contraseña</p>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"} // Cambia el tipo según el estado
            placeholder="Ingrese su contraseña"
            className="border rounded p-2 w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Actualiza el estado de la contraseña
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)} // Alterna la visibilidad de la contraseña
            className="absolute right-2 top-2 text-gray-500"
          >
            {showPassword ? "Ocultar" : "Mostrar"}
          </button>
        </div>
        <p>Ingresa de nuevo tu contraseña</p>
        <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"} // Cambia el tipo según el estado
            placeholder="Ingrese de nuevo su contraseña"
            className="border rounded p-2 w-full"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)} // Actualiza el estado de confirmación
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Alterna la visibilidad de la confirmación
            className="absolute right-2 top-2 text-gray-500"
          >
            {showConfirmPassword ? "Ocultar" : "Mostrar"}
          </button>
        </div>
        {password !== confirmPassword && confirmPassword && ( // Validación de coincidencia
          <p className="text-red-500 mt-2">Las contraseñas no coinciden.</p>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>} {/* Muestra el error si ocurre */}
        <button
          onClick={handleSubmit}
          className="mt-4 bg-indigo-500 text-white rounded p-2 w-full"
          disabled={loading} // Deshabilita el botón mientras está cargando
        >
          {loading ? "Enviando..." : "Enviar Solicitud"} {/* Muestra un texto de carga */}
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
