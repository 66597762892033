import { useMemo, useState } from "react";

import { createOption } from "../../utils/CommonUtils";
import { useExecutePromise } from "../../Hooks/UsePromiseAction";
import { CrearSalida, ObtenerMaxSalida } from "../../Apis/FetchSalidas";
import CustomToastify from "../../componentes/Toasts/CustomToast";
import dayjs from "dayjs";
import { ActualizarSalidaById, ObtenerClientesManifiestosPorFecha } from "../../Apis/FetchManifiestos";
import { columnSize } from "../../Constantes";
import { ActualizarInsumo, BuscarInsumoPorClave } from "../../Apis/FetchInsumos";

// Hook principal para manejar la lógica de los manifiestos
const useAlmacenAlta = () => {
  // Definición de los estados necesarios
  const [dataRowsInsumos, setDataRowsInsumos] = useState({});
  const [dataRowsClients, setDataRowsClients] = useState({});
  //clientes ruta
  const [columnCustomFiltersClients, setCustomColumnFiltersClients] = useState("");
  const [rowSelectionClientes, setRowSelectionClientes] = useState({});
  const opcionesTablaClientes = { ofText: "Clientes en Ruta de" };
  const [dataManifests, setDataManifests] = useState([])

  const columnasClientes = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      // {
      //     accessorKey: "orden",
      //     header: "Ord.",
      //     enableColumnFilter: false,
      //     enableSorting: true,
      //     size: 50 //set column size for this column
      //     //footer: props => props.column.id,
      // },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: true
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "familia",
        header: "Familia",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "servicio",
        header: "Servicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  // Definición de los IDs de los modales utilizados
  const idModalSelectClients = "modalSelClients";

  const tipos = {
    V: 'Venta',
    D: 'Devolución',
    P: 'Préstamo',
    O: 'Otro',
    C: 'Cancelada',
  }

  function addSelectTipo() {
    return [
      createOption('1v', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.V)], tipos.V),
      createOption('2d', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.D)], tipos.D),
      createOption('3p', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.P)], tipos.P),
      createOption('4o', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.O)], tipos.O),
      createOption('5c', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.C)], tipos.C),
    ]
  }

  const { runAction, loadingAction } = useExecutePromise();
  const title = 'Alta de Paquete de Salidas del Almacén'
  const pendingText = 'Enviando Datos del paquete con Insumos, por favor espere.'
  const successText = "Alta de Paquete. Datos del paquete de Salidas con Insumos agregados correctamente."
  const errorText = "Ocurrió un error inesperado. Por favor reinténtalo más tarde."

  async function onSaveSal(values) {
    if (!validateDataSave()) {
      CustomToastify({
        title: "Valores incorrectos", message: "Por favor valida las cantidades de los insumos antes de guardar.", type: 'warning'
      })
      return false;
    }

    await runAction({
      promiseAction: async () => {
        const dataSalidaRuta = {
          ruta: values.ruta,
          fInicio: values.fInicio,
          tipo: values.tipo,
          //nombre: values.nombre,
          //nombreRuta: values.nombreRuta,
          referencia: values.referencia,
          manif: values.manif,
        };
        //await ActualizarRutaCliente(datosActRuta);
        await ProcesarClienteRuta(dataSalidaRuta);
      }, title, pendingText, successText, errorText
    })
  }

  async function ObtenerClientesSinSalida(ruta, fecha) {
    const clientesManif = await ObtenerClientesManifiestosPorFecha(ruta, fecha);
    let cRuta = [];

    let respuestaCl = {
      clientes: [],
      existen: false
    }
    if (clientesManif && clientesManif.length > 0) {
      //solo se filtran si aun no se les asigna salida y cuenten con su manifiesto
      const clientesFiltrados = clientesManif.filter(c => c.clave !== "" && c.salida === "")
      cRuta = clientesFiltrados.map((r) => ({
        orden: r.orden,
        manif: r.clave,
        clave: r.cliente,
        nombre: r.nombre,
        familia: r.fam,
        ciudad: r.ciudad,
        servicio: r.serv,
        id: r.id
      }))
      respuestaCl = {
        clientes: cRuta,
        existen: true
      }
    }
    setDataManifests(cRuta)
    return respuestaCl
  }

  async function ProcesarClienteRuta(dataSalidaRuta) {
    for (let client of dataRowsClients) {
      const folio = await ObtenerMaxSalida();
      const fecha = dayjs(dataSalidaRuta.fInicio).format("YYYY-MM-DD");
      //aqui debe existir una condicion de que si no se obtiene el max de salida, no se agregue
      if (folio.maxNumero) {
        //"yyyy-MM-DD");
        const noSalida = Number(folio.maxNumero) + 1;
        //llamada del API que agrega el folio de salida al manifiesto
        //console.log(client.id, {salida: noSalida})
        //await sleep(100)
        await ActualizarSalidaById(client.id, { salida: noSalida })
          .then(async () => {
            for (let ins of dataRowsInsumos) {
              const cant = Number(ins.cantidad);
              //si se capturo un valor numerico y es mayor a 0
              if (!isNaN(cant) && cant > 0) {
                const currentIns = await BuscarInsumoPorClave(ins.clave)
                //si se encontro el insumo, se crea la salida, si no, deberia de saltarse este insumo
                if (currentIns === null) {
                  return
                }
                const bodyData = {
                  numero: noSalida,
                  fecha: fecha, //"2024-05-10",
                  tipo: dataSalidaRuta.tipo,
                  insumo: currentIns.id,
                  cantidad: cant,
                  unidad: currentIns.unidad,
                  referencia: dataSalidaRuta.referencia,//values.ruta + client.familia + ins.clave,
                  familia: client.familia,
                  cliente: client.clave,
                  manif: client.manif,
                  factura: "",
                  usom: "",
                  status: "1",
                  ruta: dataSalidaRuta.ruta,
                };
                //console.log(bodyData)
                // await sleep(100)
                await CrearSalida(bodyData)
                  .then(async () => {
                    //  console.log(ins.clave, {
                    //    existen: currentIns.existen - cant
                    //  })
                    await ActualizarInsumo(ins.clave, {
                      existen: currentIns.existen - cant
                    });
                  });
              }
            }
          })
      } else {
        //console.log("no se recupero el max de salidas");
        return false;
      }
    }
  }

  function validateDataSave() {
    let validate = true;
    for (let ins of dataRowsInsumos) {
      const cant = Number(ins.cantidad);
      //si se capturo un valor numerico y es mayor a 0
      if (isNaN(cant) || (!isNaN(cant) && cant <= 0)) {
        validate = false;
      }
    }

    return validate;
  }

  // Retorno del hook con todas las funciones y estados necesarios
  return {
    loadingAction,
    ObtenerClientesSinSalida,
    dataManifests,
    setDataManifests,
    onSaveSal,
    addSelectTipo,
    dataRowsInsumos,
    dataRowsClients,
    setDataRowsClients,
    setDataRowsInsumos,
    idModalSelectClients,
    setCustomColumnFiltersClients,
    columnCustomFiltersClients,
    setRowSelectionClientes,
    rowSelectionClientes,
    opcionesTablaClientes,
    columnasClientes,
  };
};

export default useAlmacenAlta;