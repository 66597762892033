import FetchApi, { FetchApiPost } from "./ConfigFetch";

const URL_RECOLECCIONES_API = 'https://suvalsa-home.ddns.net:8070/api/recolecta/'


export async function AgregarRecoleccion(data) {
  let responseAdd = null;
  responseAdd = await FetchApiPost(
    URL_RECOLECCIONES_API,
    JSON.stringify(data)
  );

  if (!responseAdd.headerResponse.statusOk) {
    throw new Error(responseAdd.bodyResponse.error)
  }

  return responseAdd;
}

//https//suvalsa-home.ddns.net:8070/api/recolecta/filter?ruta=15&fruta=2025-01-01
export async function ObtenerManifiestosRecolectaPorRutaFecha(ruta, fecha) {
  let dataRec = []

  dataRec = await FetchApi(URL_RECOLECCIONES_API.concat("filter").concat("?ruta=" + ruta + "&fruta=" + fecha))
  return dataRec.data;
}