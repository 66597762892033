import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logoPlantilla from "../../imagenes/logo_plantilla.png"; // Asegúrate de que esta ruta sea válida
const styles = StyleSheet.create({
    page: {
        padding: 5,
        fontSize: 10,
        fontFamily: 'Helvetica',
    },
    TFm: {
        fontSize: 8,
        textAlign: 'justify',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
        padding: 5,
    },
    body: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 0.3, // Reduce la separación entre secciones
        padding: 5,
    },
    secundaryBody: {
        flexDirection: 'row',
        justifyContent: 'flex-start', // Asegura que no haya separación adicional
        justifyContent: 'center',
        marginBottom: 0.3, // Reduce la separación entre secciones
        padding: 5,
    },
    logo: {
        width: 50,
        height: 50,
        marginRight: 10,
    },
    headerLeft: {
        flex: 3,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
    },
    headerCenter: {
        flex: 1,
        textAlign: 'center',
        marginLeft: 20,
    },
    headerRight: {
        flex: 1,
        textAlign: 'right',
    },
    tableContainer: {
        marginTop: .03,
        marginBottom: .03,
        border: '1 solid black',
        borderRadius: 5,
        padding: 5,
        width: '80%', // Asegura un tamaño adecuado
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1 solid black',
        height: 10, // Mantén la altura consistente
    },
    tableHeader: {
        flex: 1,
        fontWeight: 'bold',
        fontSize: 6,
        backgroundColor: '#f2f2f2',
        textAlign: 'center',
        justifyContent: 'center', // Centra el contenido verticalmente
        alignItems: 'center', // Centra el contenido horizontalmente
        display: 'flex', // Asegura que el contenedor sea flexible
        borderRight: '1 solid black',
        borderLeft: '1 solid black',
        borderTop: '1 solid black',
        padding: 0, // Elimina padding
    },
    tableCell: {
        flex: 1,
        fontSize: 6,
        textAlign: 'center',
        justifyContent: 'center', // Centra el contenido verticalmente
        alignItems: 'center', // Centra el contenido horizontalmente
        display: 'flex', // Asegura que el contenedor sea flexible
        borderRight: '1 solid black',
        padding: 0, // Elimina padding
    },
    tableCellFirst: {
        flex: 1,
        fontSize: 6,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderLeft: '1 solid black', // Sin borde derecho para la última columna
        padding: 0,
    },
    tableCellLast: {
        flex: 1,
        fontSize: 6,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRight: '1 solid black', // Sin borde derecho para la última columna
        padding: 0,
    },
    tableRowLast: {
        borderBottom: '1 solid black', // Elimina el borde inferior de la última fila
    },
});



// Componente principal
const ReporteGastosViajePDF = ({ datosReporte }) => {
    console.log('datos recibidos desde el boton', datosReporte);
    const defaultData = {
        rutaF: 'Ruta 15 - Default',
        fechaInicio: '01/12/2024',
        fechaFin: '31/12/2024',
        vehiculo: 'EXT',
        marca: '. .',
        placas: '.',
        fechaGeneracion: '27/11/2024',
        horaGeneracion: '15:13:04',
    };

    const data = datosReporte || defaultData;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.headerLeft}>
                        <Image style={styles.logo} src={logoPlantilla} />
                        <View style={{ marginLeft: 10, alignSelf: 'flex-start' }}>
                            <Text>Sistema de Administración de Suvalsa</Text>
                            <Text>Reporte de Gastos de Viaje</Text>
                            <Text>{data.rutaF} del: {data.fechaInicio} al {data.fechaFin}</Text>
                            <Text>Vehículo: {data.vehiculo} | Marca: {data.marca} | Placas: {data.placas}</Text>
                        </View>
                    </View>

                    <View style={styles.headerCenter}>
                        <Text>Página: 1</Text>
                        <Text>Fecha: {data.fechaGeneracion}</Text>
                        <Text>Hora: {data.horaGeneracion}</Text>
                    </View>

                    <View style={styles.headerRight}>
                        {/* Tabla en el encabezado */}
                        <View style={styles.tableContainer}>
                            {/* Encabezados */}
                            <View style={styles.tableRow}>
                                <Text style={styles.tableHeader}>Kms</Text>
                                <Text style={[styles.tableHeader, styles.tableCellLast]}>% Combustible</Text>
                            </View>
                            {/* Fila de datos */}
                            <View style={styles.tableRow}>
                                <Text style={styles.tableCell}>Inicial</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableCell}>Final</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                            <View style={[styles.tableRow, styles.tableRowLast]}>
                                <Text style={styles.tableCell}>Total</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        </View>
                    </View>
                </View>


                {/* PrimeraSeccion */}
                <View style={styles.body}>
                    {/* Contenedor para las tablas de Alimentos y Hoteles en dos columnas */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        {/* Tabla de Alimentos */}
                        <View style={[styles.tableContainer, { flex: 1, marginRight: 5 }]}>
                            <Text>Alimentos</Text>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Lugar</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                            </View>
                            {/* Filas de datos */}
                            {[...Array(7)].map((_, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCellLast}></Text>
                                </View>
                            ))}
                            {/* Fila de Total */}
                            <View style={[styles.tableRow, styles.tableRowLast]}>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}>Total</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        </View>

                        {/* Tabla de Hoteles */}
                        <View style={[styles.tableContainer, { flex: 1, marginLeft: 5 }]}>
                            <Text>Hoteles</Text>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Lugar</Text>
                                <Text style={styles.tableHeader}>Factura</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                            </View>
                            {[...Array(7)].map((_, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableCellFirst}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCellLast}></Text>
                                </View>
                            ))}
                            <View style={[styles.tableRow, styles.tableRowLast]}>
                                <Text style={styles.tableCellFirst}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}>Total</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        </View>
                    </View>
                </View>


                {/* SegundaSeccion */}
                <View style={styles.secundaryBody}>
                    <View style={[styles.tableContainer, { width: '100%' }]}>
                        <Text>Combustible</Text>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableHeader}>Fecha</Text>
                            <Text style={styles.tableHeader}>ENTRA</Text>
                            <Text style={styles.tableHeader}>SALE</Text>
                            <Text style={styles.tableHeader}>Kilometraje</Text>
                            <Text style={styles.tableHeader}>PROVEEDOR</Text>
                            <Text style={styles.tableHeader}>POBLACION</Text>
                            <Text style={styles.tableHeader}>FACTURA</Text>
                            <Text style={styles.tableHeader}>LITROS</Text>
                            <Text style={styles.tableHeader}>TC</Text>
                            <Text style={styles.tableHeader}>EFECTIVO</Text>
                            <Text style={styles.tableHeader}>TARJETA</Text>
                        </View>
                        {[...Array(10)].map((_, index) => (
                            <View style={styles.tableRow} key={index}>
                                <Text style={styles.tableCellFirst}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        ))}
                        <View style={[styles.tableRow, styles.tableRowLast]}>
                            <Text style={styles.tableCellFirst}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}>Total</Text>
                            <Text style={styles.tableCellLast}></Text>
                        </View>
                    </View>
                </View>



                {/* TercerSeccion */}
                <View style={styles.secundaryBody}>
                    <View style={[styles.tableContainer, { width: '100%' }]}>
                        <Text>Casetas</Text>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableHeader}>Fecha</Text>
                            <Text style={styles.tableHeader}>Lugar</Text>
                            <Text style={styles.tableHeader}>Importe</Text>
                            <Text style={styles.tableHeader}>Fecha</Text>
                            <Text style={styles.tableHeader}>Lugar</Text>
                            <Text style={styles.tableHeader}>Importe</Text>
                            <Text style={styles.tableHeader}>Fecha</Text>
                            <Text style={styles.tableHeader}>Lugar</Text>
                            <Text style={styles.tableHeader}>Importe</Text>
                            <Text style={styles.tableHeader}>Fecha</Text>
                            <Text style={styles.tableHeader}>Lugar</Text>
                            <Text style={styles.tableHeader}>Importe</Text>
                        </View>
                        {[...Array(3)].map((_, index) => (
                            <View style={styles.tableRow} key={index}>
                                <Text style={styles.tableCellFirst}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        ))}
                        <View style={[styles.tableRow, styles.tableRowLast]}>
                            <Text style={styles.tableCellFirst}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}></Text>
                            <Text style={styles.tableCell}>Total</Text>
                            <Text style={styles.tableCellLast}></Text>
                        </View>
                    </View>
                </View>


                {/* CuartaSeccion */}
                <View style={styles.body}>
                    {/* Contenedor para las tablas de Alimentos y Hoteles en dos columnas */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        {/* Tabla de Alimentos */}
                        <View style={[styles.tableContainer, { flex: 1, marginRight: 5 }]}>
                            <Text>Reparaciones y Mantenimiento</Text>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Concepto</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                            </View>
                            {/* Filas de datos */}
                            {[...Array(5)].map((_, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableCellFirst}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCellLast}></Text>
                                </View>
                            ))}
                            {/* Fila de Total */}
                            <View style={[styles.tableRow, styles.tableRowLast]}>
                                <Text style={styles.tableCellFirst}></Text>
                                <Text style={styles.tableCell}>Total</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        </View>

                        {/* Tabla de Hoteles */}
                        <View style={[styles.tableContainer, { flex: 1, marginLeft: 5 }]}>
                            <Text>Imprevistos</Text>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Concepto</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Concepto</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                            </View>
                            {[...Array(5)].map((_, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableCellFirst}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCellLast}></Text>
                                </View>
                            ))}
                            <View style={[styles.tableRow, styles.tableRowLast]}>
                                <Text style={styles.tableCellFirst}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}>Total</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        </View>
                    </View>
                </View>

                {/* QuintaSeccion */}
                <View style={styles.body}>
                    {/* Contenedor para la tabla Depositos */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        {/* Tabla de Depositos */}
                        <View style={[styles.tableContainer, { flex: 1, marginRight: 5 }]}>
                            <Text>Reparaciones y Mantenimiento</Text>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                                <Text style={styles.tableHeader}>Fecha</Text>
                                <Text style={styles.tableHeader}>Importe</Text>
                            </View>
                            {/* Filas de datos */}
                            {[...Array(8)].map((_, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableCellFirst}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCell}></Text>
                                    <Text style={styles.tableCellLast}></Text>
                                </View>
                            ))}
                            {/* Fila de Total */}
                            <View style={[styles.tableRow, styles.tableRowLast]}>
                                <Text style={styles.tableCellFirst}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}></Text>
                                <Text style={styles.tableCell}>Total</Text>
                                <Text style={styles.tableCellLast}></Text>
                            </View>
                        </View>

                        {/* Tabla de Totales */}
                        <View style={[styles.tableContainer, { flex: 1, marginLeft: 5 }]}>
                            <Text>Imprevistos</Text>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableHeader}>Concepto</Text>
                                <Text style={styles.tableHeader}>Total</Text>
                            </View>
                            {/* Filas de datos */}
                            {[
                                "Total Importe Combustible",
                                "Importe por Km",
                                "Total Km Recorridos",
                                "Litros de Combustible",
                                "Rendimiento Km/Litro",
                                "Déposito de la Semana",
                                "Diferencia Semana Pasada",
                                "Gastos de la Semana",
                                "Diferencia Ruta Actual"
                            ].map((concepto, index) => (
                                <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableCell}>{concepto}</Text> {/* Concepto */}
                                    <Text style={styles.tableCell}></Text> {/* Total vacío para cada fila */}
                                </View>
                            ))}
                        </View>
                    </View>
                </View>

                {/* SextaSeccion */}
                <View style={styles.secundaryBody}>
                    <View style={[styles.tableContainer, { width: '100%' }]}>
                        <Text>Observaciones</Text>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableHeader}></Text>

                        </View>
                        {[...Array(2)].map((_, index) => (
                            <View style={styles.tableRow} key={index}>
                                <Text style={styles.tableCellFirst}></Text>
                                <Text style={styles.tableCell}></Text>

                            </View>
                        ))}

                    </View>
                </View>

                {/*Ultimo contenedor*/}
                <View style={styles.secundaryBody}>
                    <Text style={styles.TFm}>Manifiesto bajo protesta de decir verdad que los gastos que presento en esta cuenta los realicé desarrollando en trabajo que la empresa </Text>
                </View>
                <View style={styles.secundaryBody}>
                    <Text style={styles.TFm}>Operador Responsable _______________________________ Firma ________________________________ Fecha __________________</Text>
                </View>
                <View style={styles.secundaryBody}>
                    <Text style={styles.TFm}>Operador Auxiliar y/o Personal de Apoyo _______________________________ Firma ________________________________ Fecha __________________</Text>
                </View>
                <View style={styles.secundaryBody}>
                    <Text style={styles.TFm}>Recibe ______________________ Revisa ________________________ Captura__________________________ Autoriza _______________________</Text>
                </View>
                <View style={styles.secundaryBody}>
                    <Text style={styles.TFm}>Fecha ______________________ Fecha ________________________ Fecha _______________________ Fecha_______________________</Text>
                </View>
            </Page>
        </Document>
    );
};

export default ReporteGastosViajePDF;