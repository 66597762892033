import React, { useEffect, useMemo, useState } from "react";
import { Modal, initTE } from "tw-elements";
import { CustomGenericVirtualTable } from "../../../componentes/Tables/CustomTable";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import useCatClientes from "../../Catalogos/Clientes/UseClientes";
import useCatalogo from "../../../Hooks/UseCatalogos";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";

export default function AgregarClientesTable(props) {
  const [dataRowSelectionClientes, setDataRowSelectionClientes] = useState({});

  const [columnFilters, setColumnFilters] = useState([]); // Inicializar como array
  const [sorting, setSorting] = useState([]);

  const { GetClientes } = useCatClientes();
  const opcionesTablaClientes = { ofText: "Clientes de", multiSelect: true };

  // Definir las columnas con celdas editables y filtros
  const columnsData = useMemo(() => [
    {
      accessorKey: "id",
      header: "Id",
      enableColumnFilter: false,
      size: 50,
    },
    {
      accessorKey: "clave",
      header: "Clave",
      enableColumnFilter: true,
      filterFn: "includesString",
      enableSorting: true,
      size: 100,
      isFrozen: true, // Columna fija
      columnType: 'number',
    },
    {
      accessorKey: "nombre",
      header: "Nombre",
      enableColumnFilter: true,
      filterFn: "includesString",
      enableSorting: true,
      size: 450,
      columnType: "text",
    },
    {
      accessorKey: "unidadnegocio",
      header: "Unidad Negocio",
      enableColumnFilter: true,
      filterFn: "includesString",
      enableSorting: true,
      size: 150,
      columnType: "text",
    },
    {
      accessorKey: "familia",
      header: "Familia",
      enableColumnFilter: false,
      filterFn: "includesString",
      enableSorting: true,
      size: 150,
      columnType: "text",
    },
    {
      accessorKey: "ciudad",
      header: "Ciudad",
      enableColumnFilter: false,
      filterFn: "includesString",
      enableSorting: true,
      size: 400,
      columnType: "text",
    },
    {
      accessorKey: "servicio",
      header: "Servicio",
      enableColumnFilter: false,
      filterFn: "includesString",
      enableSorting: true,
      size: 100,
      columnType: "text",
    },

    {
      accessorKey: "ruta",
      header: "Ruta",
      enableColumnFilter: false,
      filterFn: "includesString",
      enableSorting: true,
      size: 100,
      columnType: "text",
    },

  ], []);

  const {
    loading,
    loadingAction,
    data: clientes,
    setData: setClientes,
    GetRowsData: GetRowsDataClientes,
    rowSelection,
    setRowSelection,
    getDataSelected
  } = useCatalogo({
    getData: GetClientes,
    dataRowSelection: dataRowSelectionClientes,
    setDataRowSelection: setDataRowSelectionClientes
  });

  /**
   * Funcion encargada de enviar los datos de los nuevos clientes que se deben agregar a la ruta y actualiza la tabla de clientes en ruta
   * @param {*} datasSelected : es el estado de la seleccion en la tabla, contiene los indices seleccionados
   */
  async function SendNewData() {

    if (props.actionUpdateTable) {
      //se mandarian las nuevas filas para que se agreguen
      await props.actionUpdateTable(dataRowSelectionClientes);
    }
  }

  function actionCancelar() {
    const myModal = new Modal(document.getElementById(props.idModalClientes));
    if (myModal) Modal.getOrCreateInstance(myModal).hide();
  }

  function actionAgregar() {
    SendNewData();
    actionCancelar();
  }

  const buttonsGroup = {
    buttonPrimary: {
      action: actionAgregar,
      disabled: !getDataSelected(),
      type: "button",
      label: "Agregar",
      modalDismiss: true
    },
    buttonSecondary: {
      action: actionCancelar,
      disabled: false,
      type: "button",
      label: "Cancelar",
      modalDismiss: true
    }
  };

  useEffect(() => {
    const myModal = document.getElementById(props.idModalClientes);
    initTE({ Modal });

    myModal.addEventListener("show.te.modal", () => {
      setRowSelection({})
    });
  }, [props.idModalClientes]);

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <span className="w-full flex text-left">
        Seleciona un cliente de la lista y posteriormente da clic en en botón
        AGREGAR para enviarlo a la lista de clientes por ruta.
      </span>

      <div className="flex flex-col w-full gap-2">
        <CustomGenericVirtualTable
          columnsData={columnsData}
          data={clientes}
          optionsTable={opcionesTablaClientes}
          setData={setClientes}
          getData={GetRowsDataClientes}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          sorting={sorting}
          setSorting={setSorting}
          //conditionalSelection={conditionalSelection}
          isTableSelected={true}
          isMultiRowSelection={true}
          setDataRowSelection={setDataRowSelectionClientes}
        />

        <ButtonGroup {...buttonsGroup} />
      </div>
    </>
  );
}
