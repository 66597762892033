import React from 'react';
import PrevisualizarBitacora from '../../../componentes/PDFServices/PrevisualizarBitacora';

const ReportesManifiestos = () => {
  return (
    <div style={{ height: '100vh', width: '100vw', margin: 0, padding: 0 }}>
      <PrevisualizarBitacora />
    </div>
  );
};

export default ReportesManifiestos;