// AuthApi.js

import FetchApi, { FetchApiPut } from "./ConfigFetch";

const URL_USUARIOS = 'https://suvalsa-home.ddns.net:8091/'
const METHOD_REESTABLECER = "api/restablecimiento/"

///usuariosLogin/ver/{id}
//GET
export async function obtenerUsuarioLogin(email) {
    let userLogg = {};
  
    userLogg = await FetchApi(URL_USUARIOS.concat("usuariosLogin/email/",email));
  
    return userLogg?.data[0] ?? {};
  }

//put https://localhost:8091/usuariosLogin/update/{id}
export async function actualizarUsuarioLogin(data){
    try {
        // Pasar encabezados personalizados sin 'Authorization'
        const customHeaders = {
            // "Authorization": undefined // No se establece
        };

        const response = await FetchApiPut(
            URL_USUARIOS.concat(METHOD_REESTABLECER),
            data,
            customHeaders
        );

        return response; // Contiene 'headerResponse' y 'bodyResponse'
    } catch (error) {
        console.error("Error in UpdateUser:", error);
        throw error;
    }
}
