import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// Initialization for ES Users
import { Modal, initTE } from "tw-elements";
import useTable from '../../../../componentes/Tables/VirtualTable/useTable';
import CircleSpiner from '../../../../componentes/Spinners/CircleSpiner';
import CellInputValid from '../../../../componentes/Tables/components/Cell/InputValid/CellInputValid';
import InsumosTable from '../../../Catalogos/Insumos/components/table/Insumos.table';
import CustomModal from '../../../../componentes/Modals/CustomModal';
import { CustomGenericVirtualTable } from '../../../../componentes/Tables/CustomTable';

export default function EntradaAlmacenTable({
    folio,
    data,
    setData,
    setDataRowSelection,
    dataRowSelection
}) {
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const idModalSelectInsumos = "modalSelectInsumos";

    const columns = useMemo(() => [
        {
            accessorKey: "id",
            header: "Id",
            enableColumnFilter: false,
            size: 50,
        },
        {
            accessorKey: "clave",
            header: "Clave",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            isFrozen: true,
            columnType: 'number',
        },
        {
            accessorKey: "nombre",
            header: "Nombre",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 450,
            columnType: "text",
        },
        {
            accessorKey: "Modelo",
            header: "modelo",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text",
        },
        {
            accessorKey: "cantidad",
            header: "Cantidad",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: false,
            size: 120,
            columnType: "number",
            min: "0",
            readOnly: false,
            cell: ({ column: { id: columnId }, row, getValue }) => (
                <CellInputValid
                    readOnly={false}
                    columnId={columnId}
                    row={row}
                    type={"number"}
                    min={"0"}
                    value={getValue() || ''}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => handleCellEditValid(row.original.id, columnId, e.target.value)}
                />
            )
        },
        {
            accessorKey: "unidad",
            header: "Unidad",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "text",
        },
        {
            accessorKey: "acciones",
            header: "",
            enableColumnFilter: false,
            filterFn: "includesString",
            enableSorting: false,
            size: 65,
            columnType: "text",
            cell: ({ row }) => (
                <button
                    type="button"
                    onClick={() => handleRemoveRow(row.index)}
                    className="text-red-500 hover:text-red-700 text-center w-full"
                >
                    ✕
                </button>
            ),
        }
    ], []);

    const { table } = useTable({
        isSelected: false,
        setData,
        data,
        rowSelection,
        setRowSelection,
        columns,
        defaultColumn: {},
        columnFilters,
        setColumnFilters,
        sorting,
        setSorting,
    });

    const handleCellEditValid = useCallback((id, field, value) => {
        setData(prevData => {
            const rowIndex = prevData.findIndex(item => item.id === id);
            if (rowIndex === -1) return prevData;
            let updatedRow = { ...prevData[rowIndex], [field]: value };
            const updatedErrors = { ...(updatedRow.errors || {}) };
            if (field === 'cantidad') {
                const cantidad = Number(value);
                if (isNaN(cantidad) || cantidad <= 0) {
                    updatedErrors.cantidad = {
                        hasError: true,
                        message: 'La cantidad debe ser mayor a 0',
                    };
                } else {
                    updatedErrors.cantidad = {
                        hasError: false,
                        message: '',
                    };
                }
            }
            updatedRow.errors = updatedErrors;
            const newData = [...prevData];
            newData[rowIndex] = updatedRow;
            return newData;
        });
    }, [setData]);

    // Crear un ref para 'data' y actualizarlo cuando 'data' cambie
    const dataRef = useRef(data);

    // Función para manejar eventos de teclado con acceso al data actualizado
    const handleKeyDown = useCallback((e) => {
        const key = e.key;

        if (!["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(key)) {
            return;
        }

        e.preventDefault();

        // Encontrar la celda actual
        const currentElement = e.target;
        const parentCell = currentElement.closest('div[data-row][data-col]');
        if (!parentCell) return;

        const rowIndex = parseInt(parentCell.getAttribute('data-row'), 10);
        const colIndex = parseInt(parentCell.getAttribute('data-col'), 10);

        // Verificar que rowIndex y colIndex son números válidos
        if (isNaN(rowIndex) || isNaN(colIndex)) {
            console.warn("rowIndex o colIndex no son números válidos:", rowIndex, colIndex);
            return;
        }

        let newRow = rowIndex;
        let newCol = colIndex;

        const totalRows = dataRef.current.length;
        const allColumns = table.getVisibleFlatColumns(); // Asegúrate de usar solo columnas visibles
        const totalCols = allColumns.length;

        switch (key) {
            case "ArrowUp":
                newRow = (rowIndex - 1 + totalRows) % totalRows;
                break;
            case "ArrowDown":
                newRow = (rowIndex + 1) % totalRows;
                break;
            case "ArrowLeft":
                newCol = (colIndex - 1 + totalCols) % totalCols;
                break;
            case "ArrowRight":
                // Implementar wrap-around horizontal
                if (colIndex === totalCols - 1) {
                    newCol = 0; // Mover al inicio de la misma fila
                } else {
                    newCol = colIndex + 1;
                }
                break;
            default:
                break;
        }

        // Implementar el wrap-around en columnas izquierda
        if (key === "ArrowRight" && colIndex === totalCols - 1) {
            newCol = 0; // Mover al inicio de la misma fila
        }
        if (key === "ArrowLeft" && colIndex === 0) {
            newCol = totalCols - 1; // Mover al final de la misma fila
        }

        // Encontrar la celda objetivo
        const targetCell = document.querySelector(
            `[data-row="${newRow}"][data-col="${newCol}"] input, [data-row="${newRow}"][data-col="${newCol}"] select`
        );

        if (targetCell) {
            targetCell.focus();
        } else {
            console.warn(`No se encontró el elemento en data-row="${newRow}" data-col="${newCol}"`);
        }
    }, [table]);

    const handleRemoveRow = (index) => {
        setData(prevData => {
            const newData = [...prevData];
            newData.splice(index, 1);
            return newData;
        });
    };

    const handleModalClose = useCallback(() => {
        console.log(dataRowSelection)
        setData(prevData => {

            const newData = [...prevData];
            dataRowSelection.forEach(insumo => {
                if (!newData.some(item => item.id === insumo.id)) {
                    newData.push(insumo);
                }
            });
            return newData;
        });
    }, [dataRowSelection]);

    useEffect(() => {
        // Inicializar el modal con los eventos
        initTE({ Modal });
    }, []);


    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    useEffect(() => {
        initTE({ Modal });
    }, []);

    return (
        <>
            {(loadingData) && <CircleSpiner />}
            {/* <VirtualPlainTable
                table={table}
                optionsTable={{}}
                isTableSelected={false}
            /> */}
            <CustomGenericVirtualTable
                columnsData={columns}
                data={data}
                optionsTable={{}}
                setData={setData}
                getData={()=> data}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                withPagination={false}
            />

            <CustomModal
                id={idModalSelectInsumos}
                title={`Agregar Insumo - Folio ${folio ?? ""}`}
                withForm
            >
                <div className="flex flex-col w-full gap-2">
                    <InsumosTable isSelected={true} multiSelected={true} setDataRowSelection={setDataRowSelection} idModalSelectInsumos={idModalSelectInsumos}/>
                    <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <button
                            type="button"
                            className="btn-secondary"
                            data-te-modal-dismiss
                            onClick={handleModalClose}
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            </CustomModal>
            <button
                type='button'
                onClick={() => {
                    const myModal = new Modal(document.getElementById(idModalSelectInsumos));
                    myModal.show();
                }}
                className='btn-tertiary mt-3'
            >
                + Agregar Insumo
            </button>
        </>
    );
}