import React from 'react'
import CardNavigate from '../../../componentes/Cards/CardNavigate'
import EntradaAlmacenForm from './Altas/EntradasAlmacen.Form'

const EntradasAlmacen = () => {
  return (
    <CardNavigate title="Alta de Entrada en Almacen">
      <EntradaAlmacenForm />
    </CardNavigate>
  )
}

export default EntradasAlmacen
