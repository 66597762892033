// RecoleccionMafiestosTable.jsx
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTable } from "../../../../../componentes/Tables/VirtualTable/useTable";
import VirtualTable from "../../../../../componentes/Tables/VirtualTable/VirtualTable.table";
import CircleSpiner from "../../../../../componentes/Spinners/CircleSpiner";
import { ObtenerAgentes } from "../../../../../Apis/FetchCatalogos";

const RecoleccionMafiestosTable = ({
    columnCustomFilters,
    setCustomColumnFilters, // Añadir esta prop
    data,
    isSelected = false,
    rowSelection,
    setRowSelection,
    setData,
    getData,
}) => {
    const [loadingData, setLoadingData] = useState(false);
    const [agentesOPE, setAgentesOPE] = useState([])

    const statusAtencionOptions = useMemo(() => [
        { key: 'stA1', value: 'O', label: 'Original' },
        { key: 'stA2', value: 'P', label: 'Pendiente' }
    ], []);

    const firmaOptions = useMemo(() => [
        { key: 'FIRMA', value: 'FIRMA', label: 'FIRMA' },
        { key: 'SIN_FIRMA', value: 'SIN FIRMA', label: 'SIN FIRMA' }
    ], []);

    // Definir las columnas con celdas editables y filtros
    const columns = useMemo(() => [
        {
            accessorKey: "id",
            header: "Id",
            enableColumnFilter: false,
            size: 50,
        },
        {
            accessorKey: "fila",
            header: "No.Fila",
            enableColumnFilter: false,
            enableSorting: true,
            size: 80,
            isFrozen: true, // Columna fija
            columnType: "text"
        },
        {
            accessorKey: "clave",
            header: "Clave",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            isFrozen: true, // Columna fija
            columnType: "text",
        },
        {
            accessorKey: "operador",
            header: "Operador",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: 'select',
            selectOptions: agentesOPE,
        },
        {
            accessorKey: "unidadNegocioInt",
            header: "Unidad Negocio Interna",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 200,
            columnType: "text",
            readOnly: false
        },
        {
            accessorKey: "ruta",
            header: "Ruta",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "text",
        },
        {
            accessorKey: "statusConsultorio",
            header: "Estatus Consultorio",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text",
            readOnly: false
        },
        {
            accessorKey: "statusAtencion",
            header: "Estatus Atención",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: 'select',
            selectOptions: statusAtencionOptions,
        },
        {
            accessorKey: "unidadNegocio",
            header: "Unidad Negocio",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text",
            readOnly: false
        },
        {
            accessorKey: "nombre",
            header: "Nombre",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text",
        },
        {
            accessorKey: "localidad",
            header: "Localidad",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text",
        },
        {
            accessorKey: "estado",
            header: "Estado",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text",
        },
        {
            accessorKey: "nra",
            header: "NRA",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "text",
            readOnly: false
        },
        {
            accessorKey: "manifiesto",
            header: "No. Manifiesto",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text"
        },
        {
            accessorKey: "fechaAtencion",
            header: "Fecha Atención Mes Anterior",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 200,
            columnType: 'date',
            readOnly: false
        },
        {
            accessorKey: "fvisita",
            header: "Fecha de Servicio",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 200,
            columnType: 'date',
            readOnly: false
        },
        {
            accessorKey: "sangreBI5",
            header: "Sangre BI 5",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "patologicoBI3",
            header: "Patológico BI 3",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "noAnatomicoBI4",
            header: "No. Anatómico BI 4",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "punzocortanteBI2",
            header: "Punzocortante BI 2",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "cultivosCepasBI5",
            header: "Cultivos y Cepas BI 5",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "liquidoRevelador",
            header: "Líquido Revelador",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "medicinaCaducada",
            header: "Medicina Caducada",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "totalResiduos",
            header: "Total(Residuos)",
            enableColumnFilter: false,
            enableSorting: true,
            size: 150,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "personaQuienEntrega",
            header: "Persona Quien Entrega",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 200,
            readOnly: false,
            columnType: "text",
        },
        {
            accessorKey: "noBolsasRojas55x60",
            header: "No. Bolsas Rojas 55x60",
            enableColumnFilter: false,
            enableSorting: true,
            size: 200,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "noContenedoresPunzocortante",
            header: "No. Contenedores de Punzocortante",
            enableColumnFilter: false,
            enableSorting: true,
            size: 250,
            readOnly: false,
            columnType: "number",
            min: "0",
        },
        {
            accessorKey: "firmaQuienRecibio",
            header: "Firma de Quien Recibio",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 200,
            readOnly: false,
            columnType: 'select',
            selectOptions: firmaOptions,
        },
        {
            accessorKey: "observaciones",
            header: "Observaciones",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 200,
            readOnly: false,
            columnType: "text",
        },
    ], [agentesOPE, statusAtencionOptions]);

    // Definir estado para sorting
    const [sorting, setSorting] = useState([]);

    // Inicializar la tabla usando useTable
    const { table } = useTable({
        isSelected,
        setData,
        data,
        rowSelection,
        setRowSelection,
        columns,
        defaultColumn: {},
        columnFilters: columnCustomFilters,
        setColumnFilters: setCustomColumnFilters, // Pasar esta función
        sorting, // Pasar el estado de sorting
        setSorting, // Pasar el actualizador de sorting
    });

    useEffect(() => {
        table?.resetRowSelection();
    }, [data, table]);

    useEffect(() => {
        
    async function listarAgentes() {
        setLoadingData(true);
        console.log("Onteniendo lista de Agentes::: ");
        const operadores = await ObtenerAgentes()
            .then(listaAgent => {
                const ags = listaAgent.filter((ags) => ags.puesto === 'OPE')
                return ags.map((ag) => {
                    return { key: ag.id, value: ag.clave, label: ag.clave + " " + ag.nombre }
                })
            })
        setAgentesOPE(operadores);
        setLoadingData(false);
    }
    
        if (!agentesOPE.length)
            listarAgentes()
    }, [])

    return (
        <>
            {loadingData && <CircleSpiner />}
            <VirtualTable
                table={table}
                actionRefreshData={getData}
                optionsTable={{ ofText: "Manifiestos Recolectados de" }}
                isTableSelected={isSelected}
            />
        </>
    );
};

export default RecoleccionMafiestosTable;
