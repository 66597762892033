// Dashboard.js
import React, { useState } from "react";
//import AccordionMap from "../../componentes/Accordion/AccordionMap";
import MyMap from './MyMap';
import Accordion from "../../componentes/Accordion/Accordion";

const Dashboard = () => {
    const [expandedAccordions, setExpandedAccordions] = useState({
        collapseVehiculosActivos1: false,
    });

    const handleAccordionChange = (accordion) => {
        setExpandedAccordions((prev) => ({
            ...prev,
            [accordion]: !prev[accordion],
        }));
    };

    return (
        <div className="" style={{ alignItems: 'center' }}>
            {/* <AccordionMap
                titulo="Vehículos activos"
                idTarget="collapseVehiculosActivos1"
                expanded={expandedAccordions.collapseVehiculosActivos1}
                onClick={() => handleAccordionChange("collapseVehiculosActivos1")}
            >
                {expandedAccordions.collapseVehiculosActivos1 && (
                    <div style={{ height: '700px', width: '100%', borderRadius: '10px', overflow: 'hidden', width: '100%' }}>
                        <MyMap />
                    </div>
                )}
            </AccordionMap> */}
            <Accordion
                titulo="Vehículos activos"
                idTarget="collapseVehiculosActivos1"
                expanded={expandedAccordions.collapseVehiculosActivos1 ? "true" : "false"}
                onCloseAction={() => handleAccordionChange("collapseVehiculosActivos1")}
              >
                {expandedAccordions.collapseVehiculosActivos1 && (

                    <div style={{ height: '700px', width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                        <MyMap />
                    </div>
                )}
              </Accordion>
        </div>
    );
};

export default Dashboard;
