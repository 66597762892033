import React, { useEffect, useMemo, useState } from "react";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import { useCallback } from "react";
import { useAppStore } from "../../../Stores/useAppStore";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import { CustomInputText } from "../../../componentes/Formik/InputText";
import { TrashIcon } from '@heroicons/react/24/solid';
import { useExecutePromise } from "../../../Hooks/UsePromiseAction";
import dayjs from "dayjs";

const SalidasManifiestoTable = (
    { onLoadSalidas = false, noSalida, noManifiesto, editMode = false, manifestData }
) => {
    const msjErrorQuantity = 'La cantidad debe ser mayor a 0'
    const [loading, setLoading] = useState(false);
    const setShowSalidas = useAppStore(state => state.setShowSalidas);
    const getSalidas = useAppStore(state => state.getSalidas);
    const deleteSalida = useAppStore(state => state.deleteSalida);
    const updateSalida = useAppStore(state => state.updateSalida);
    const salidas = useAppStore(state => state.salidas);
    const saveSalidas = useAppStore(state => state.saveSalidas);
    //HOOK utilizado para crear la tabla ya que trabaja de esa manera y no puede ser manejado con el state de SalidasInsumosStore
    const [dataSalidas, setDataSalidas] = useState(salidas);
    const [columnCustomFilters, setCustomColumnFilters] = useState("");
    const [rowSelection, setRowSelection] = useState({});

    const opcionesTabla = { ofText: "Salidas de" };

    const { runAction, loadingAction } = useExecutePromise();
  const title = "Actualización de Manifiesto";
  const pendingText = "Enviando manifiesto, por favor espere.";
  const successText = "Datos de manifiestos y Viaje actualizados correctamente.";
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

    const actualizarManifiesto = async() => {
        await runAction({
            promiseAction: async () => {
                const bodyGenManif = {
                    id: manifestData.datosManif.id,
                    clave: noManifiesto,
                    tipo: "O",
                    ruta: manifestData.ruta,
                    fruta: dayjs(manifestData.datosViaje.fInicio, "YYYY-MM-DD"), //.format('YYYY-MM-DD'),//"2024-07-01",
                    cliente: manifestData.datosManif.cliente,
                    fvisita: manifestData.datosManif.fvisita, //"2006-05-04 00:00:00",
                    orden: manifestData.datosManif?.orden.toString(),
                    salida: noSalida.toString(),
                    status: "",
                    fam: manifestData.datosManif.familia,
                    serv: manifestData.datosManif.servicio,
                    operador: manifestData.datosViaje.oper,
                    auxiliar: manifestData.datosViaje.aux,
                    vehiculo: manifestData.datosViaje.veh,
                    duracion: manifestData.datosViaje.duracion,
                    factura: "",
                    planta: manifestData.datosManif.planta,
                    rutaplanta: manifestData.datosManif.rutaplanta
                  };

                await saveSalidas(noSalida, noManifiesto, bodyGenManif)
            },
            title,
            pendingText,
            successText,
            errorText
          });
    }

    const handleDeleteRow = useCallback((row) => {
        deleteSalida(row.original.clave)
    }, [deleteSalida]);

    //   const handleEditRow = useCallback((rowIndex) => {
    //     // Aquí puedes agregar la lógica de edición que prefieras.
    //     console.log(`Edit row ${rowIndex}`);
    //   }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "Id",
                enableColumnFilter: false
                //footer: props => props.column.id,
                //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
            },
            {
                accessorKey: "clave",
                header: "Clave",
                enableColumnFilter: true,
                filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
                //filterFn: 'customFilter', //using our custom function filter
                //filterFn: "fuseFilterFn"
                //footer: props => props.column.id,
                size: 50, //set column size for this column
                enableSorting: true
            },
            {
                accessorKey: "insumo",
                header: "Insumo",
                enableColumnFilter: false,
                enableSorting: false,
                //footer: props => props.column.id,
                size: 150 //set column size for this column
            },
            {
                accessorKey: "cantidad",
                header: "Cantidad",
                enableColumnFilter: false,
                enableSorting: false,
                size: 60 //set column size for this column
                //footer: props => props.column.id,
            },
            {
                accessorKey: "unidad",
                header: "Unidad",
                enableColumnFilter: false,
                //footer: props => props.column.id,
                enableSorting: false,
                size: 50 //set column size for this column
            },
            // editMode ?
            {
                id: "actions",
                header: "",
                size: 30, //set column size for this column
                cell: ({ row }) => (
                    <div className="flex p-0 justify-end pr-1">
                        {/* <button
                      className="bg-transparent hover:bg-blue-100 px-2 py-1 rounded flex items-center"
                      onClick={() => handleEditRow(row.index)}
                    >
                      <PencilSquareIcon className="h-5 w-5 text-blue-500 hover:text-blue-400" />
                    </button> */}
                        <button
                            className="bg-transparent hover:bg-red-100 py-1 rounded flex items-center"
                            onClick={() => handleDeleteRow(row)}
                        >
                            <TrashIcon className="h-5 w-5 bg-transparent text-red-500 hover:text-red-400" />
                        </button>
                    </div>
                )
            }
            //:{},
        ],
        []
    );

    const TableCell = (getValue, row, id) => {
        const [value, setValue] = useState("");
        const [onError, setOnError] = useState(false);
        let initialValue = getValue();

        const isCantidad = id === "cantidad";
        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
            //como se maneja ya en un useEffect el setData, 
            //solo se actualiza sobre el state para que automaticamente lo tome el Table
            //table.options.meta?.updateData(row.index, id, value);
            updateSalida({
                ...row.original,
                cantidad: value
            })
        };
        //establecer el valor de inicio, cuando se recuperan del servicio
        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);

        //cada que cambia el valor se valida que sea mayor a 0 en el campo cantidad
        useEffect(() => {
            if (isCantidad) {
                if (value <= 0) {
                    setOnError(true)
                } else setOnError(false)
            }
        }, [value]);
        //dependiendo de la validacion se setea como input o no
        return isCantidad && editMode ? (
            <>
                <input
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        listenerValidaNumber(e, setOnError);
                    }}
                    onBlur={onBlur}
                    type={"number"}
                    className={`${onError ? 'dataError' : ''}`}
                />
                {
                    onError ?
                        <span className="dataError-Msj">{msjErrorQuantity}</span>
                        : ''
                }
            </>
        ) : (
            <span>{value}</span>
        );
    };

    /**
     * Funcion que valida si el numero ingresado es correcto, establece formato para la celda y Flag del mensaje de error
     * @param {*} e evento onValueChange
     * @param {*} setOnError hook para controlar la bandera de error sobre la celda o campo
     */
    const listenerValidaNumber = (e, setOnError) => {
        let isDataValid = false;
        let curval = Number(e.target.value);
        setTimeout(function () {
            // revisar si el valor es mayor a 0
            if (!isNaN(curval) && curval > 0) {
                isDataValid = true;
            }
            if (isDataValid) {
                e.target.classList.remove("border-[1px]");
                e.target.classList.remove("border-red-600");
            } else {
                e.target.innerHTML = "";
                e.target.classList.add("border-[1px]");
                e.target.classList.add("border-red-600");
            }
            setOnError(!isDataValid);
        }, 100);
    };

    const defaultColumn = {
        cell: ({ getValue, row, column: { id } }) => {
            return TableCell(getValue, row, id);
        }
    };

    const GetDataSalidas = useCallback(async () => {
        setLoading(true)
        await getSalidas(noSalida, noManifiesto);
        setLoading(false)
    }, [noSalida, noManifiesto, getSalidas, setLoading])

    useEffect(() => {
        if (onLoadSalidas) {
            GetDataSalidas();
        }
    }, [onLoadSalidas, GetDataSalidas])

    //controla cuando el useState de dataSalidas se dedbe actualizar, ya que no es transparente con el state de salidasInsumosStore
    useEffect(() => {
        setDataSalidas(salidas)
    }, [salidas])

    return (
        <>
            {(loading || loadingAction) && <CircleSpiner />}
            <div className="flex flex-col w-full gap-2">
                <div className="w-full md:w-1/4">
                    <CustomInputText
                        disabled={loading}
                        label="Buscar por clave"
                        name="claveSearch"
                        type="number"
                        placeholder=""
                        onChange={async (e) => {
                            setCustomColumnFilters([
                                {
                                    id: "clave",
                                    value: e.target.value
                                }
                            ]);
                        }}
                    />
                </div>
                <CustomGenericTable
                    columnsData={columns}
                    data={dataSalidas}
                    optionsTable={opcionesTabla}
                    setData={setDataSalidas}
                    getData={GetDataSalidas}
                    defaultColumn={defaultColumn}
                    columnCustomFilters={columnCustomFilters}
                    isTableSelected={false}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                />
                <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                    <div className="w-full grid  md:flex md:justify-end gap-4">
                        {
                            editMode && (
                                <>
                                    <button
                                        type="button"
                                        className="btn-primary"
                                        onClick={() => actualizarManifiesto()}
                                        disabled={!(salidas && salidas.length > 0)}
                                    >
                                        Guardar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn-secondary"
                                        onClick={() => setShowSalidas(false)}
                                    >
                                        Agregar Insumo
                                    </button>

                                </>

                            )
                        }
                        <button
                            type="button"
                            className={`${editMode ? "btn-tertiary" : "btn-primary"}`}
                            data-te-modal-dismiss
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SalidasManifiestoTable;
