import { actualizarUsuarioLogin, obtenerUsuarioLogin } from "../Apis/AuthApi";
import { validarUsuario } from "../Apis/FetchUsers";
import CustomToastify from "../componentes/Toasts/CustomToast";
import { deleteItem, setItem } from "../utils/LocalStorage";

const initialUserDataLogin = {
    prifile: {},
    login: {
        email: '',
        password: '',
        token: '',
    },
    data: {
        id: '',
        name: '',
        user_name: '',
        type: ''
    }
}

export const createAuthSlice = (set, get) =>
({
    user: {
        ...initialUserDataLogin
    },
    // Método para limpiar/resetear el usuario
    cleanUser: () => {
        set(() => ({
            user: { ...initialUserDataLogin }, // Resetea el estado del usuario al valor inicial
        }));
    },
    doLogin: async (email, password) => {
        const response = await validarUsuario({ email, password });
        //si es valido el usuario
        if (response.headerResponse.statusOk && response.headerResponse.statusCode === 200) {
            const dataUser = await get().getUserLoginData(email)
            setItem("user", dataUser.login.email ?? "");
            setItem("userName", dataUser.data.user_name ?? "");
            set(() => ({
                user: { ...dataUser }
            }))
            get().setUser(email)
            return true
        } else {
            get().showToast("Error de Autenticación", "Credenciales incorrectas. Por favor, verifica tu usuario y contraseña.")
        }

        return false
    },

    logout: () => {
        deleteItem("user");
        deleteItem("userName");
        //limpia el state
        get().cleanUser(); // Limpia los datos del usuario
        get().cleanApp();//limpia datos de la app
        if (typeof window !== "undefined")
            window.location.replace('/')
    },

    getUserLoginData: async (email) => {
        console.log(email)
        const dataUser = await obtenerUsuarioLogin(email);

        let currentDataUser = {
            login: {
                email: dataUser.email,
                password: '',
                token: '',
            },
            data: {
                id: dataUser.id,
                name: dataUser.name,
                user_name: dataUser.user_name,
                type: dataUser.type,//aqui se puede personalizar y proteger las rutas dependiendo el tipo de usuario
            }
        };
        return currentDataUser;
    },

    updateUserLogin: async (id, data) => {
        //api updateUser
        const apiRespUser = await actualizarUsuarioLogin(id, data)
        //mostrar notificacion de cambio, se podria validar la respuesta y en base a eso lanzar el mensaje
        if (apiRespUser.headerResponse.statusOk && apiRespUser.headerResponse.statusCode === 200) {
            console.log('Cambio de contrasena OK')
        }
    },
    showToast: (title, message, type) => {
        CustomToastify({
            title,
            message,
            type: type ?? "error",
        });
    }
})