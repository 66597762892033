import React from "react";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import CustomModal from "../../../componentes/Modals/CustomModal";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import useCatalogo from "../../../Hooks/UseCatalogos";
import useCatRutasPlantas from "./UseRutaPlanta";

const CatalogoRutasPlanta = () => {
  const {
    tituloCatRutasPlanta,
    idModalDelete,
    pathAltaRutasPlanta,
    pathCatRutasPlanta,
    columnsData,
    opcionesTabla,
    GetRutasPlanta,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  } = useCatRutasPlantas();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: rutasPlanta,
    setData: setRutasPlantas,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetRutasPlanta,
    idModalDelete,
    pathNavTo: pathAltaRutasPlanta,
    pathNavBack: pathCatRutasPlanta,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <CardNavigate title={tituloCatRutasPlanta}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={rutasPlanta}
            setData={setRutasPlantas}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            title={"Eliminar Ruta"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar la ruta con la</span>
            <h1>planta {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoRutasPlanta;
