import { ActualizarContrasena, getProfileByEmail, validarUsuario } from "../Apis/FetchUsers";
import { sleep } from "../utils/CommonUtils";

export const createUserSlice = (set, get) =>
({

    getUser: () => {
        const dataUser = get().user
        return { ...dataUser }
    },

    getDataUser: () => {
        return get().user.data
    },

    getUserProfileData: async (email) => {
        const dataUserProfile = await getProfileByEmail(email);
        return dataUserProfile;
    },

    setUser: async (email) => {
        const dataUserProfile = await get().getUserProfileData(email)
        set(() => ({
            user: {
                ...get().user,
                profile: { ...dataUserProfile?.dataEmail[0] }
            }
        }))
    },
    updatePassword: async (currentPassword, newPassword) => {
        //se valida la contrasena con el servicio de validacion login
        const responseValidacion = await validarUsuario({ email: get().user.login.email, password: currentPassword });
        if (responseValidacion.headerResponse.statusOk && responseValidacion.headerResponse.statusCode === 200) {
            //api updateUser
            await sleep(2000)
            const apiRespUser = await ActualizarContrasena(get().user.login.email, newPassword)
            //mostrar notificacion de cambio, se podria validar la respuesta y en base a eso lanzar el mensaje
            if (apiRespUser.headerResponse.statusOk && apiRespUser.headerResponse.statusCode === 200) {
                return true
            }
            return false
        } else
            return false
    },
})