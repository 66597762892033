import React from 'react'

const EmitirCancelarFactura = () => {
  return (
    <div>
      EmitirCancelarFactura
    </div>
  )
}

export default EmitirCancelarFactura
