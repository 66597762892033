import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import CustomModal from "../../../componentes/Modals/CustomModal";
import useCatDatosNumeros from "./UseDatosNumeros";
import useCatalogo from "../../../Hooks/UseCatalogos";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoDatosNumeros = () => {
  const {
    tituloCatNumerosDatosControl,
    idModalDelete,
    pathAltaDatosNum,
    pathCatDatosNum,
    columnsData,
    opcionesTabla,
    GetDatosNumeros,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  } = useCatDatosNumeros();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: datosNumeros,
    setData: setDatosNumeros,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetDatosNumeros,
    idModalDelete,
    pathNavTo: pathAltaDatosNum,
    pathNavBack: pathCatDatosNum,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}

      <CardNavigate title={tituloCatNumerosDatosControl}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={datosNumeros}
            setData={setDatosNumeros}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            title={"Eliminar Número y Dato de Control"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar el Registro con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoDatosNumeros;
