import React from 'react'
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import useCatProveedores from "./useCatProveedores";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CustomModal from "../../../componentes/Modals/CustomModal";
import useCatalogo from "../../../Hooks/UseCatalogos";

const CatalogoProveedores = () => {
  const {
    tituloCatProveedores,
    idModalDelete,
    pathAltaProv,
    pathCatProv,
    columnsData,
    opcionesTabla,
    GetProveedores,
    dataRowSelection,
    setDataRowSelection,
    propsModal,
    actionDelete,
    notification
  } = useCatProveedores();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: proveedores,
    setData: setProveedores,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetProveedores,
    idModalDelete,
    pathNavTo: pathAltaProv,
    pathNavBack: pathCatProv,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <CustomModal
        id={propsModal.id}
        title={propsModal?.title ?? "Confirmar Eliminación"}
        actionAccept={confirmarEliminacion}
      >
        <h1>
          {propsModal?.setMessage
            ? propsModal?.setMessage(getDataSelected())
            : "¿Deseas eliminar este registro?"}
        </h1>
      </CustomModal>
      <CardNavigate title={tituloCatProveedores}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            columnsData={columnsData}
            data={proveedores}
            optionsTable={optionsTable}
            setData={setProveedores}
            getData={GetRowsData}
            isTableSelected={true}
            isMultiRowSelection={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup {...buttonsGroup} />
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoProveedores;
