import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import BitacoraHorasServicio from './BitacoraHorasServicio'; // Ruta del componente

const PrevisualizarBitacora = () => {
    const datos = {
        nombreconductor: "Juan Pérez",
        vehiculo: "Camión 123",
        placas: "ABC-123",
        auxiliar: "Carlos López",
        origen: "Ciudad de México",
        destino: "Guadalajara",
        fechaInicio: "2024-12-01",
        fechaFin: "2024-12-05",
        nomanifiestos: "150",

    };

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PDFViewer style={{ width: '100%', height: '100%' }}>
                <BitacoraHorasServicio data={datos} />
            </PDFViewer>
        </div>
    );
};

export default PrevisualizarBitacora;