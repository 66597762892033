import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaAltaFamilia = Yup.object({
  clave: Yup.string().required(MSJ_DATO_REQ),
  nombre: Yup.string().required(MSJ_DATO_REQ),
});

export default schemaAltaFamilia;
