import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { InputText } from '../../../../componentes/Formik'; // Asegúrate de importar tu componente
import Accordion from '../../../../componentes/Accordion/Accordion';
import { ButtonGroup } from '../../../../componentes/Button/GenericButton';
import * as Yup from 'yup';
import EntradaAlmacenTable from '../../Entradas/Altas/EntradaAlmacen.table';
// Esquema de validación
const validationSchema = Yup.object().shape({
  numeroMovimiento: Yup.string().required('Campo requerido'),
  fechaMovimiento: Yup.date().required('Campo requerido'),
  surtirA: Yup.string().required('Campo requerido'),
  referenciaSalida: Yup.string().required('Campo requerido'),
  // items: Yup.array().of(
  //   Yup.object().shape({
  //     clave: Yup.string().required('Requerido'),
  //     nombre: Yup.string().required('Requerido'),
  //     modelo: Yup.string().required('Requerido'),
  //     cantidad: Yup.number().required('Requerido').min(1, 'Mínimo 1'),
  //     unidad: Yup.string().required('Requerido')
  //   })
  // ),
  observaciones: Yup.string(),
  entrego: Yup.string().required('Campo requerido'),
  recibio: Yup.string().required('Campo requerido')
});

const initialValues = {
  numeroMovimiento: '',
  fechaMovimiento: '',
  surtirA: '',
  referenciaSalida: '',
  observaciones: '',
  entrego: '',
  recibio: ''
};

export default function SalidaAlmacenForm() {

  const [data, setData] = useState([ ]);

  //hook para saber que insumosfueron seleccionados
  const [dataRowsInsumos, setDataRowsInsumos] = useState({});

  function handleSubmit(e) {
    e.preventDefault();
    // Llamar a tu API o servicio con la info:
    // {numeroMovimiento, fechaMovimiento, proveedor, facturaReferencia, items, etc.}
  }

  useEffect(()=> {
    console.log('data:',data)
  },[data])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col w-full p-2 gap-2">
          <Accordion titulo="Datos del Movimiento - Salida" idTarget="encabezado" expanded="true">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputText
                label="Número de Movimiento"
                name="numeroMovimiento"
                placeholder="Ej. 001234"
              />

              <InputText
                label="Fecha"
                name="fechaMovimiento"
                type="date"
              />

              <InputText
                label="Surtir a"
                name="surtirA"
                placeholder="Área o departamento"
              />

              <InputText
                label="Referencia"
                name="referenciaSalida"
                placeholder="Pedido/orden/etc."
              />
            </div>
          </Accordion>

          <Accordion titulo="Detalle de Insumos" idTarget="detalle" expanded="true">
            <EntradaAlmacenTable data={data} setData={setData} setDataRowSelection={setDataRowsInsumos} dataRowSelection={dataRowsInsumos}/>
            
          </Accordion>

          <Accordion titulo="Información Adicional" idTarget="adicional" expanded="true">
            <div className="grid grid-cols-1 gap-4">
              <InputText
                label="Observaciones"
                name="observaciones"
                as="textarea"
                rows="3"
                placeholder="Detalles adicionales..."
              />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  label="Entregó"
                  name="entrego"
                  placeholder="Nombre y Firma"
                />

                <InputText
                  label="Recibió"
                  name="recibio"
                  placeholder="Nombre y Firma"
                />
              </div>
            </div>
          </Accordion>

          <ButtonGroup
            buttonPrimary={{
              type: "submit",
              label: "Guardar Salida",
              disabled: isSubmitting
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
