import React, { useCallback, useEffect, useMemo, useState } from 'react';
// Initialization for ES Users
import { Modal, initTE } from "tw-elements";
import { CustomGenericVirtualTable } from '../../../../../componentes/Tables/CustomTable';
import { ObtenerInsumos } from '../../../../../Apis/FetchCatalogos';
import CircleSpiner from '../../../../../componentes/Spinners/CircleSpiner';

export default function InsumosTable({ autoLoad = true, isSelected = false, multiSelected = false, setDataRowSelection, idModalSelectInsumos }) {
    const [loadingPage, setLoadingPage] = useState(false);
    const [insumos, setInsumos] = useState([]);
    const [rowSelectionInsumos, setRowSelectionInsumos] = useState({});
    const [columnCustomFilters, setColumnCustomFilters] = useState([]);
    const [sorting, setSorting] = useState([]);

    const opcionesTablaInsumos = { ofText: "Insumos de" };

    const columns = useMemo(() => [
        {
            accessorKey: "id",
            header: "Id",
            enableColumnFilter: false,
            size: 50,
        },
        {
            accessorKey: "clave",
            header: "Clave",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            isFrozen: true,
            columnType: 'number',
        },
        {
            accessorKey: "nombre",
            header: "Nombre",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 450,
            columnType: "text",
        },
        {
            accessorKey: "cantidad",
            header: "Cantidad",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: false,
            size: 120,
            columnType: "number",
            min: "0",
            readOnly: false,
            withValidate: true,
            
        },
        {
            accessorKey: "existencias",
            header: "Existencias",
            enableColumnFilter: false,
            filterFn: "includesString",
            enableSorting: false,
            size: 100,
            columnType: "number",
        },
        {
            accessorKey: "modelo",
            header: "Modelo",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 250,
            columnType: "text",
        },
        {
            accessorKey: "unidad",
            header: "Unidad",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "text",
        },
        {
            accessorKey: "tipo",
            header: "Tipo",
            enableColumnFilter: false,
            filterFn: "includesString",
            enableSorting: false,
            size: 100,
            columnType: "text",
        }
    ], []);

    const GetDataInsumos = useCallback(async () => {
        setLoadingPage(true);
        const data = await ObtenerInsumos();
        let cInsumos = [];
        if (data !== null && data.length > 0) {
            cInsumos = data.map((res) => ({
                id: res.id,
                clave: res.clave,
                nombre: res.nombre,
                modelo: res.modelo,
                tipo: res.tipo,
                existencias: res.existen ?? "",
                unidad: res.unidad,
                cantidad: 0
            }));
        }
        setInsumos(cInsumos);
        setLoadingPage(false);
    }, []);

    useEffect(() => {
        if (autoLoad) {
            GetDataInsumos();
        }
    }, [autoLoad]);

    useEffect(() => {
        console.log('rowSelectionInsumos:',rowSelectionInsumos)
        setRowSelectionInsumos({})
        setDataRowSelection({})
    }, [])

    
    const handleModalOpen = () => {
        // Limpiar selecciones previas o realizar otras acciones necesarias
        console.log('dataRowSelection:',rowSelectionInsumos)
        setDataRowSelection({});
        setRowSelectionInsumos({});
    };

    useEffect(() => {
        // Inicializar el modal con los eventos
        initTE({ Modal });
        const modal = document.getElementById(idModalSelectInsumos);

        // Agregar listener para el evento 'hide.te.modal'
        if (modal) {
            modal.addEventListener('show.te.modal', handleModalOpen);
        }

        // Cleanup
        return () => {
            if (modal) {
                modal.removeEventListener('show.te.modal', handleModalOpen);
            }
        };
    }, []);


    return (
        <>
            {(loadingPage) && <CircleSpiner />}

            <CustomGenericVirtualTable
                columnsData={columns}
                data={insumos}
                optionsTable={opcionesTablaInsumos}
                setData={setInsumos}
                getData={GetDataInsumos}
                rowSelection={rowSelectionInsumos}
                setRowSelection={setRowSelectionInsumos}
                columnFilters={columnCustomFilters}
                setColumnFilters={setColumnCustomFilters}
                sorting={sorting}
                setSorting={setSorting}
                isTableSelected={isSelected}
                isMultiRowSelection={multiSelected}
                setDataRowSelection={setDataRowSelection}
            />
        </>
    );
}