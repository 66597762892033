//import { PDFDownloadLink } from "@react-pdf/renderer";
import { CrearFamilia as AgregarFamilia  } from "../../../Apis/FetchFamilias";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Familia", successText: 'Familia agregada correctamente.' }

export const urlCatalogo = "/suvalsa/catalogos/familias";

export const initValuesAf = {
    clave: "",
    nombre: "",
};

export async function ImprimirContrato() {
    console.log("clic imprimir contrato familias: ");
}

export async function CrearFamilia(values) {
    const datosFam = {
        clave: values.clave,
        nombre: values.nombre
    };
    // await sleep(4000);
    await AgregarFamilia(datosFam);
    return true;
}
