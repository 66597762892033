import React from 'react'

const ReporteRutaVehiculo = () => {
  return (
    <div>
      ReporteRutaVehiculo
    </div>
  )
}

export default ReporteRutaVehiculo
