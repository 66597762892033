import React from 'react'

const GastosDatos = () => {
  return (
    <div>
      GastosDatos
    </div>
  )
}

export default GastosDatos
