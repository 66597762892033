
const initialAppState = {
    menuSideNav: {
        show: false,
        menuActive : ""
    }
}

export const createAppSlice = (set, get) =>
({
    app: {
        ...initialAppState
    },
    // Método para limpiar/resetear el usuario
    cleanApp: () => {
        set(() => ({
            app: { ...initialAppState }, // Resetea el estado del usuario al valor inicial
        }));
    },
    showMenu: async(show) => {
        console.log(show)
        set(() => ({
            app: {
                ...get().app,
                menuSideNav: {
                    ...get().app.menuSideNav,
                    show
                }
            }
        }))
    },
    setAcive: (menuItem) => {
        console.log(menuItem)
        set(() => ({
            app: {
                ...get().app,
                menuSideNav: {
                    ...get().app.menuSideNav,
                    menuActive: menuItem
                }
            }
        }))
    },
})