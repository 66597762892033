import { useState } from "react";
import {
  onPromiseToast,
  renderToastContainer
} from "../componentes/Toasts/CustomToast";

/**
 * Hook para ejecutar acciones y utiliza Toastify para mostrar notificaciones de ejecucion y exito o error del proceso a ejecutar
 * @returns 
 */
export const useExecutePromise = () => {
  const [loadingAction, setLoadingAction] = useState(false);

  async function runAction({
    promiseAction,
    title,
    pendingText,
    successText,
    errorText,
    onCloseAction
  }) {
    try {
      setLoadingAction(true);
      await onPromiseToast({
        promiseAction,
        title,
        pendingText,
        successText,
        errorText,
        onCloseAction
      });
    } catch (error) {
      console.log("error inesperado:");
      console.warn(error);
    } finally {
      setLoadingAction(false);
    }
  }

  return {
    runAction,
    loadingAction,
    renderToastContainer
  };
};
