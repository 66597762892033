import React, { useEffect, useState } from 'react';
// Initialization for ES Users
import { Select } from "tw-elements";
import { Formik, Form } from 'formik';
import { InputText, Select as SelectF } from '../../../../componentes/Formik'; // Asegúrate de importar tu componente
import Accordion from '../../../../componentes/Accordion/Accordion';
import { ButtonGroup } from '../../../../componentes/Button/GenericButton';
import * as Yup from 'yup';
import EntradaAlmacenTable from './EntradaAlmacen.table';
import { CrearMovimientoInsumo } from '../../../../Apis/FetchInsumos';
import { useAppStore } from '../../../../Stores/useAppStore';
import UseAgentesSelect from '../../../../Hooks/UseAgentes';
import CircleSpiner from '../../../../componentes/Spinners/CircleSpiner';
import { createOption, obtenerFechaActual } from '../../../../utils/CommonUtils';
import { ObtenerProveedores } from '../../../../Apis/FetchCatalogos';
// Esquema de validación
const validationSchema = Yup.object().shape({
  numeroMovimiento: Yup.string().required('Campo requerido'),
  fechaMovimiento: Yup.date().required('Campo requerido'),
  proveedor: Yup.string().required('Campo requerido'),
  facturaReferencia: Yup.string().required('Campo requerido'),
  // items: Yup.array().of(
  //   Yup.object().shape({
  //     clave: Yup.string().required('Requerido'),
  //     nombre: Yup.string().required('Requerido'),
  //     modelo: Yup.string().required('Requerido'),
  //     cantidad: Yup.number().required('Requerido').min(1, 'Mínimo 1'),
  //     unidad: Yup.string().required('Requerido')
  //   })
  // ),
  observaciones: Yup.string(),
  entrego: Yup.string().required('Campo requerido'),
  recibio: Yup.string().required('Campo requerido')
});

const initialValues = {
  numeroMovimiento: '',
  fechaMovimiento: '',
  proveedor: '',
  facturaReferencia: '',
  observaciones: '',
  entrego: '',
  recibio: '',
  destino:'',
  aQuienSeSurte:''
};


export default function EntradaAlmacenForm() {

  const { getDataUser } = useAppStore();
  const [data, setData] = useState([ ]);
  const { gettingData : gettingAgents,  agregarSelectAllAg} = UseAgentesSelect("selectRecibio");
  const [proveedores, setProveedores] = useState([]);

  const selectProv = "selectProveedor"

  //hook para saber que insumosfueron seleccionados
  const [dataRowsInsumos, setDataRowsInsumos] = useState({});

  async function handleSubmit(e) {
    e.preventDefault();
    // Llamar a tu API o servicio con la info:
    // {numeroMovimiento, fechaMovimiento, proveedor, facturaReferencia, items, etc.}
    const movimientos = dataRowsInsumos.map(insumo => ({
      //numeroMovimiento: e.numeroMovimiento,
      //fechaMovimiento: e.fechaMovimiento,
      clave: insumo.clave,
      cantidad: insumo.cantidad,//con 3 decimales
      fecha_Creacion: e.fechaMovimiento,//Fecha de creacion del registro
      proveedor: e.proveedor,//proveedores del catalogo proveedores
      idFactura: e.facturaReferencia,
      observaciones : e.observaciones,
      quienEntrego : e.entrego,
      quienRecibio : e.recibio,// Se consulta el catalogo de agentes (Personal suvalsa)
      tipoTicket: "E",//tipo Entrada
      destino: e.destino,
      aQuienSeSurte: e.aQuienSeSurte,//"Sucursal Acapulco". Se coloca el personal que esta solciitando el insumo de una matriz 
      quienGeneroTicket: getDataUser().id,//Id del usuario
      nombreGeneroTicket: getDataUser().name,
      status: "A",
      idUsuarioUltMod: 1

    }));
    console.log('movimientos:',movimientos)
    // for(const movimiento of movimientos){
    //   const responseMovimiento = await CrearMovimientoInsumo(movimiento);
    //   console.log('responseMovimiento:',responseMovimiento)
    //   if(responseMovimiento.headerResponse.statusOk){
    //     console.log('Movimiento creado correctamente')
    //   }else{
    //     console.log('Error al crear el movimiento')
    //   }
    // }
  }

  function agSelectOptProveedores() {
    return proveedores !== null
      ? proveedores.map((proveedor) =>
        createOption(proveedor.id, proveedor.clave, proveedor.clave + '   ' + proveedor.empresa)
      )
      : "";
  }

  const listarProveedores = async () => {
    const proveedores = await ObtenerProveedores();
    console.log("lista de provedores: ", proveedores);

  if (proveedores) {
    setProveedores(
      proveedores.map((proveedor) => ({
        clave: proveedor.clave,
        id: proveedor.id,
        empresa: proveedor.empresa,
        // calle: proveedor.trial_calle_3,
        // colonia: proveedor.colonia,
        // ciudad: proveedor.ciudad,
        // cp: proveedor.cp,
        // contacto: proveedor.contacto,
        // tel: proveedor.telefono
    })));  
  }
} 

  useEffect(()=> {
    console.log('data:',data)
  },[data])

  useEffect(()=> {
    listarProveedores();
    const selectProveedor = document.querySelector(`#${selectProv}`);
    if (selectProveedor) Select.getOrCreateInstance(selectProveedor);
  },[])

  return (
    <>
      {(gettingAgents) && <CircleSpiner />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col w-full p-2 gap-2">
            <Accordion titulo="Datos del Movimiento - Entrada" idTarget="encabezado" expanded="true">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  label="Número de Movimiento"
                  name="numeroMovimiento"
                  placeholder="Ej. 001234"
                  disabled={true}
                />

                <InputText
                  label="Fecha"
                  name="fechaMovimiento"
                  type="date"
                  value={obtenerFechaActual()}
                />

                {/* <InputText
                  label="Proveedor"
                  name="proveedor"
                  placeholder="Nombre del proveedor"
                /> */}
                <SelectF
                      id={selectProv}
                      disabled={isSubmitting}
                      className="pt-[0.1235rem]"
                      data-te-select-init
                      label="Proveedor"
                      name="proveedor"
                    >
                      <option hidden defaultValue value=""></option>
                      {agSelectOptProveedores()}
                    </SelectF>

                <InputText
                  label="Factura/Referencia"
                  name="facturaReferencia"
                  placeholder="Núm. de Factura/Ref"
                />
                <InputText
                  label="Destino"
                  name="destino"
                  placeholder=""
                />

                <InputText
                  label="A Quien Se Surte"
                  name="aQuienSeSurte"
                  placeholder=""
                />
              </div>
            </Accordion>

            <Accordion titulo="Detalle de Insumos" idTarget="detalle" expanded="true">
              <EntradaAlmacenTable data={data} setData={setData} setDataRowSelection={setDataRowsInsumos} dataRowSelection={dataRowsInsumos}/>
              
            </Accordion>

            <Accordion titulo="Información Adicional" idTarget="adicional" expanded="true">
              <div className="grid grid-cols-1 gap-4">
                <InputText
                  label="Observaciones"
                  name="observaciones"
                  as="textarea"
                  rows="3"
                  placeholder="Cualquier detalle adicional..."
                />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputText
                    label="Entregó"
                    name="entrego"
                    placeholder="Nombre y Firma"
                  />

                    {/* <InputText
                      label="Recibió"
                      name="recibio"
                      placeholder="Nombre y Firma"
                    /> */}
                  <SelectF
                          id="selectRecibio"
                          disabled={isSubmitting}
                          className="pt-[0.1235rem]"
                          data-te-select-init
                          label="Recibió"
                          name="recibio"
                        >
                          <option hidden defaultValue value=""></option>
                          {agregarSelectAllAg()}
                        </SelectF>
                </div>
              </div>
            </Accordion>

            <ButtonGroup
              buttonPrimary={{
                type: "submit",
                label: "Guardar Entrada",
                disabled: isSubmitting
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
