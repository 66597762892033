//import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgregarTrabajo } from "../../../Apis/FetchCatalogos";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Trabajo Realizado", successText: 'Trabajo Realizado agregado correctamente.' }

export const initValuesAtr = {
    clave: "",
    trabajo: "",
};

export async function ImprimirContrato() {
    console.log("clic imprimir contrato Trabajos: ");
}

export async function CrearTrabajo(values) {
    const datosTrab = {
        clave: values.clave,
        trabajo: values.trabajo,
    };
    //await sleep(5000);
    await AgregarTrabajo(datosTrab)
    return true;
}


export const urlCatalogo = "/suvalsa/catalogos/trabajos-realizados";
