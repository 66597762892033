// BitacoraDocument.jsx
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// -------------------------------------------------------------------
// 1. Datos completos (85 items)
// -------------------------------------------------------------------
const itemsTabla = [
    { id: 0, texto: 'REVISION INTERNA', section: true },
    { id: 1, texto: 'Indicador Presión de Aceite', section: false },
    { id: 2, texto: 'Indicador Presion Aire Vacio', section: false },
    { id: 3, texto: 'Dispositivo advertencia poco aire/vacio', section: false },
    { id: 4, texto: 'TABLERO DE INSTRUMENTOS', section: true },
    { id: 5, texto: 'Velocímetro', section: false },
    { id: 6, texto: 'Calentador / Desempañador', section: false },
    { id: 7, texto: 'Batería', section: false },
    { id: 8, texto: 'Motor', section: false },
    { id: 9, texto: 'LUCES TABLERO', section: true },
    { id: 10, texto: 'Direccionales', section: false },
    { id: 11, texto: 'Estacionamiento', section: false },
    { id: 12, texto: 'Galibo (Altura)', section: false },
    { id: 13, texto: 'Interior', section: false },
    { id: 14, texto: 'Advertencia', section: false },
    { id: 15, texto: 'Demarcadoras', section: false },
    { id: 16, texto: 'ACCESORIOS', section: true },
    { id: 17, texto: 'Claxón / Corneta', section: false },
    { id: 18, texto: 'Cinturon de Seguridad [minimo operador]', section: false },
    { id: 19, texto: 'Parabrisas sin fisuras', section: false },
    { id: 20, texto: 'Limpiadores', section: false },
    { id: 21, texto: 'Espejo Retrovisor [2]', section: false },
    { id: 22, texto: 'Volante [sin juego excesivo]', section: false },
    { id: 23, texto: 'Freno de Pie', section: false },
    { id: 24, texto: 'Frenos de Emergencia', section: false },
    { id: 25, texto: 'FRENTE [EXTERIOR]', section: true },
    { id: 26, texto: 'Defensas [sujeción]', section: false },
    { id: 27, texto: 'Faros principales [color/funcionamiento]', section: false },
    { id: 28, texto: 'Llantas [no renovadas eje delantero]', section: false },
    { id: 29, texto: 'Rines sin fisuras', section: false },
    { id: 30, texto: 'Birlos completos', section: false },
    { id: 31, texto: 'Guardafangos [Loderas]', section: false },
    { id: 32, texto: 'LUCES EXTERIORES', section: true },
    { id: 33, texto: 'Demarcadoras', section: false },
    { id: 34, texto: 'Direccionales', section: false },
    { id: 35, texto: 'Galibo', section: false },
    { id: 36, texto: 'Advertencia', section: false },
    { id: 37, texto: 'LADO IZQUIERDO', section: true },
    { id: 38, texto: 'Tanque de combustible sin fugas', section: false },
    { id: 39, texto: 'Luces de advertencia', section: false },
    { id: 40, texto: 'Cinta Reflejante', section: false },
    { id: 41, texto: 'Llanta de Refacción', section: false },
    { id: 42, texto: 'Llanta [Desgaste y aire]', section: false },
    { id: 43, texto: 'Rines sin fisuras', section: false },
    { id: 44, texto: 'Birlos completos', section: false },
    { id: 45, texto: 'Sistema de Aseguramiento/Sujecion carga', section: false },
    { id: 46, texto: 'Carteles de Identificación Riesgo (2)', section: false },
    { id: 47, texto: 'LADO DERECHO', section: true },
    { id: 48, texto: 'Tanque de combustible sin fugas', section: false },
    { id: 49, texto: 'Luces de advertencia', section: false },
    { id: 50, texto: 'Cinta Reflejante', section: false },
    { id: 51, texto: 'Llanta [Desgaste y aire]', section: false },
    { id: 52, texto: 'Rines sin fisuras', section: false },
    { id: 53, texto: 'Birlos completos', section: false },
    { id: 54, texto: 'Sistema de Aseguramiento/Sujecion carga', section: false },
    { id: 55, texto: 'Carteles de Identificación Riesgo (2)', section: false },
    { id: 56, texto: 'PARTE POSTERIOR', section: true },
    { id: 57, texto: 'LUCES DE', section: true },
    { id: 58, texto: 'Altura', section: false },
    { id: 59, texto: 'Direccionales', section: false },
    { id: 60, texto: 'Estacionamiento', section: false },
    { id: 61, texto: 'Gálibo', section: false },
    { id: 62, texto: 'Advertencia', section: false },
    { id: 63, texto: 'Frenado', section: false },
    { id: 64, texto: 'Marcha atrás', section: false },
    { id: 65, texto: 'Reflejantes', section: false },
    { id: 66, texto: 'Guardafango (lodera)', section: false },
    { id: 67, texto: 'Dispositivo aseguramiento', section: false },
    { id: 68, texto: 'Cerradura puertas', section: false },
    { id: 69, texto: 'Defensas', section: false },
    { id: 70, texto: 'Escape', section: false },
    { id: 71, texto: 'PARTE INFERIOR', section: true },
    { id: 72, texto: 'Frenos sin fuga', section: false },
    { id: 73, texto: 'Muelles suspension', section: false },
    { id: 74, texto: 'Chásis sin fisuras', section: false },
    { id: 75, texto: 'Líneas de aire', section: false },
    { id: 76, texto: 'Líneas eléctricas', section: false },
    { id: 77, texto: 'Diferenciales sin fugas', section: false },
    { id: 78, texto: 'Transmisión sin fugas', section: false },
    { id: 79, texto: 'ÁREA COMBUSTIÓN INTERNA', section: true },
    { id: 80, texto: 'Motor', section: false },
    { id: 81, texto: 'Radiador', section: false },
    { id: 82, texto: 'Batería', section: false },
    { id: 83, texto: 'Bandas', section: false },
    { id: 84, texto: 'DEL EQUIPO DE EMERGENCIAS', section: true },
    { id: 85, texto: 'Placas Circulación', section: false },
];
// -------------------------------------------------------------------
// 2. Configuración de columnas (6 grupos B/M/NA como en el PDF original)
// -------------------------------------------------------------------
const numRepeticiones = 6;
const columnasEvaluacion = Array.from({ length: numRepeticiones }, (_, i) => ({
    id: i + 1,
    labels: ['B', 'M', 'NA'],
}));

// -------------------------------------------------------------------
// 3. Estilos mejorados para landscape
// -------------------------------------------------------------------
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 10,
        fontSize: 8,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
        alignItems: 'flex-start',
    },
    titleContainer: {
        flex: 1,
        paddingLeft: 10,
    },
    title: {
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 2,
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 8,
        textAlign: 'center',
        marginBottom: 3,
    },
    companyInfo: {
        fontSize: 7,
        marginBottom: 3,
    },
    mainSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    evaluationHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        marginBottom: 2,
    },
    systemColumn: {
        width: '40%',
        borderRightWidth: 1,
        padding: 2,
    },
    checkColumn: {
        width: '60%',
        flexDirection: 'row',
    },
    checkGroup: {
        width: '16.66%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        borderRightWidth: 1,
    },
    checkBox: {
        width: '33.33%',
        textAlign: 'center',
        paddingVertical: 2,
        borderLeftWidth: 0.5,
    },
    itemRow: {
        flexDirection: 'row',
        borderBottomWidth: 0.5,
        minHeight: 14,
        alignItems: 'center',
    },
    sectionHeader: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
    },
    xSection: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
    },
    signatureSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    }
});

// -------------------------------------------------------------------
// 4. Componente principal (formato original del PDF)
// -------------------------------------------------------------------
const ReporteDiarioFallas = ({
    origen = "________",
    destino = "________",
    fecha = "___/___/___",
    operador = "_________________"
}) => {
    const renderPage = (items, pageNumber) => (
        <Page size="A4" orientation="portrait" style={styles.page} key={pageNumber}>
            {/* Encabezado superior */}
            <View style={styles.headerRow}>
                <Text style={{ fontSize: 8 }}>S.A. DE C.U. Veintoluc EXT Marcar . Placas .</Text>
                <Text style={{ fontSize: 8 }}>D Derecha   NA No Aplica   X No Aceptable</Text>
            </View>

            {/* Información de la empresa */}
            <View style={styles.companyInfo}>
                <Text>Nombre Transportista: SUVALSA SA DE CV</Text>
                <Text>José Vasconcelos 39 Edif 1 Depto 004 PB Mza D Santísima Trinidad Tlalpan, México, DF</Text>
                <Text>Teléfonos: [55] 54852800 [744] 4508813</Text>
            </View>

            {/* Sección principal */}
            <View style={styles.mainSection}>
                <View>
                    <Text>Tipo de Servicio: SERVICIO PÚBLICO FEDERAL</Text>
                    <Text>Tipo de Vehículo: CAMIÓN C-2 UNITARIO</Text>
                    <Text>Dimensiones de Vehículo y Caja: ______________</Text>
                </View>
                <View>
                    <Text>Origen: {origen} Destino: {destino}</Text>
                    <Text>Tipo Licencia: E   Vigencia Licencia: __________</Text>
                    <Text>Nombre del Operador: {operador}</Text>
                    <Text>Día: ___ Fecha: {fecha}</Text>
                </View>
            </View>

            {/* Cabecera de la tabla */}
            <View style={styles.evaluationHeader}>
                <View style={[styles.systemColumn, { padding: 2 }]}>
                    <Text>SISTEMA A EVALUAR</Text>
                </View>
                <View style={styles.checkColumn}>
                    {columnasEvaluacion.map((col, idx) => (
                        <View key={col.id} style={styles.checkGroup}>
                            {col.labels.map((label) => (
                                <View key={label} style={styles.checkBox}>
                                    <Text style={{ fontSize: 7 }}>{label}</Text>
                                </View>
                            ))}
                        </View>
                    ))}
                </View>
            </View>

            {/* Items de la tabla */}
            {items.map((item) => (
                <View key={item.id} style={styles.itemRow}>
                    <View style={[styles.systemColumn, item.section && styles.sectionHeader]}>
                        <Text style={{ fontSize: 7 }}>
                            {item.section ? item.texto : `${item.id}. ${item.texto}`}
                        </Text>
                    </View>
                    <View style={styles.checkColumn}>
                        {columnasEvaluacion.map((col) => (
                            <View key={col.id} style={styles.checkGroup}>
                                {col.labels.map((label) => (
                                    <View key={label} style={styles.checkBox} />
                                ))}
                            </View>
                        ))}
                    </View>
                </View>
            ))}

            {/* Sección final */}
            <View style={styles.xSection}>
                <Text style={{ fontSize: 8 }}>X No Aceptable: </Text>
                {[...Array(6)].map((_, i) => (
                    <Text key={i} style={{ fontSize: 8, marginLeft: 10 }}>[  ]</Text>
                ))}
            </View>

            <View style={styles.signatureSection}>
                <Text style={{ fontSize: 8 }}>Firma del Operador: _________________________</Text>
                <Text style={{ fontSize: 8 }}>km por Día: __________</Text>
            </View>
        </Page>
    );

    return (
        <Document>
            {renderPage(itemsTabla.slice(0, 43), 1)}  {/* Primera página con items 1-43 */}
            {renderPage(itemsTabla.slice(43), 2)}      {/* Segunda página con items 44-85 */}
        </Document>
    );
};

export default ReporteDiarioFallas;
