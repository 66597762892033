import React, { useEffect, useMemo, useState } from "react";
import {ObtenerSalidasManif} from "../../../Apis/FetchSalidas"
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import { useCallback } from "react";

const ConsultarSalidasTable = (props) => {
  const { noSalida, noManifiesto, setLoading } = { ...props };
  const [dataSalidas, setDataSalidas] = useState([]);

  const opcionesTabla = { ofText: "Salidas de" };
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: true
      },
      {
        accessorKey: "insumo",
        header: "Insumo",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 150 //set column size for this column
      },
      {
        accessorKey: "cantidad",
        header: "Cantidad",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "unidad",
        header: "Unidad",
        enableColumnFilter: false,
        //footer: props => props.column.id,
        enableSorting: false,
        size: 100 //set column size for this column
      },
    ],
    []
  );

  const GetDataSalidas = useCallback(async () => {
    setLoading(true)
    const data = await ObtenerSalidasManif(noSalida, noManifiesto );
    let cInsumos = [];
    if (data !== null && data.length > 0) {
      cInsumos = data.map((res) => {
        const insumo = {
          id: res.salida.id,
          clave: res.salida.insumo,
          insumo: res.modelo,
          cantidad: res.salida.cantidad,
          //tipo: res.tipo,
          unidad: res.salida.unidad,
        };
        return insumo;
      });
    }
    setDataSalidas(cInsumos);
    setLoading(false)
  },[noManifiesto, noSalida, setLoading])

  useEffect(() => {
    if((noSalida && noManifiesto) && noSalida !== "" && noSalida !== ""){
      GetDataSalidas();
    }else setDataSalidas([]);
    
  }, [noSalida, noManifiesto, GetDataSalidas]);

  return (
    <div className="flex flex-col w-full gap-2">
      <CustomGenericTable
        columnsData={columns}
        data={dataSalidas}
        optionsTable={opcionesTabla}
        setData={setDataSalidas}
        getData={GetDataSalidas}
      />
      <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
        <div className="w-full grid flex-row md:flex md:flex-row-reverse md:justify-start gap-4">
          <button
            type="button"
            className="btn-primary"
            data-te-modal-dismiss
          >
            Salir
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsultarSalidasTable;
