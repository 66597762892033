import React from "react";

const Accordion = (props) => {
  const hId = 'head'.concat(crypto.randomUUID())
  return (
    <div id={crypto.randomUUID()}>
      <div className="rounded-t-lg dark:border-neutral-600 dark:bg-neutral-800">
        {(props.expanded === "true" || !props.expanded) ? (
          <>
            <h2 className="mb-0 bg-transparent" id={hId}>
              <button id={`btn-${props.idTarget}`}
                className="group relative flex w-full items-center 
                          rounded-t-[15px] border-0 bg-suva-azul-200 px-5 py-4 text-left text-base md:text-lg tracking-wide text-primary-600 transition hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white"
                type="button"
                data-te-collapse-init
                data-te-target={`#${props.idTarget}`}
                aria-expanded="true"
                aria-controls={props.idTarget}
                disabled={props.disabled ?? false}
                onClick={ props.onCloseAction ? props.onCloseAction : ()=>{}}
              >
                {props.titulo}
                <span className="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[text-suva-azul-corporativo] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-8 w-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </button>
            </h2>
            <div
              id={props.idTarget}
              className="!visible bg-suva-azul-suave"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby={hId}
            >
              <div className="px-1 md:px-5 pt-4 pb-1">{props.children}</div>
            </div>
          </>
        ) : (
          <>
            <h2 className="mb-0 bg-transparent" id={hId}>
              <button
                className="group relative flex w-full items-center 
                          rounded-b-[15px] rounded-t-[15px] border-0 bg-blue-100 px-5 py-4 text-left text-base md:text-lg text-primary-600 transition hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white"
                type="button"
                data-te-collapse-init
                data-te-collapse-collapsed
                data-te-target={`#${props.idTarget}`}
                aria-expanded="false"
                aria-controls={props.idTarget}
                disabled={props.disabled ?? false}
                onClick={ props.onCloseAction ? props.onCloseAction : ()=>{}}
              >
                {props.titulo}
                <span className="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[text-suva-azul-corporativo] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-8 w-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </button>
            </h2>
            <div
              id={props.idTarget}
              className="!visible hidden bg-suva-azul-suave"
              data-te-collapse-item
              aria-labelledby={hId}
            >
              <div className="px-1 md:px-5 pt-4 pb-1">{props.children}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Accordion;
