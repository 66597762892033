import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa'; // Avatar de React Icons
import { useAppStore } from '../../../Stores/useAppStore';

const Profile = () => {
  const navigate = useNavigate();
  const user = useAppStore((state) => state.user);

  // Datos de ejemplo del usuario (puedes reemplazarlos con datos dinámicos)
  console.log(user)
  const userData = {
    nombre: user.profile.nombre,
    telefono: user.profile.telefono ?? '+52          ',
    email: user.profile.mail,
    usuario: user.data.user_name,
    rol: user.profile.puesto ?? user.data.type === '1' ? 'Administrador' : '',
  };

  // Función para navegar a la página de cambio de contraseña
  const handleChangePassword = () => {
    navigate('/suvalsa/cuenta/cambio-contrasena');
  };

  return (
    <div className="h-5/6 md:h-4/5 w-full flex flex-col p-2 md:p-4 items-center justify-center bg-transparent">
      <div className="bg-suva-azul-10 shadow-lg rounded-lg p-1 md:p-8 max-w-4xl w-full flex flex-col items-center md:items-start space-y-2 md:space-y-4 md:space-x-8">
        <div className='w-full flex'>
          <h1 className='text-2xl font-bold text-start p-2'>Perfil</h1>
        </div>
        <div className='w-full flex flex-col md:flex-row items-center gap-2'>
          {/* Avatar */}
          <div className="flex-shrink-0 my-0 m-auto">
            <FaUserCircle className="w-24 h-24 md:w-36 md:h-36 text-suva-azul-200" />
          </div>
          {/* Información del usuario */}
          <div className="flex-grow">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className=" text-xl font-bold">Nombre</p>
                <p className='text-xl'>{userData.nombre}</p>
              </div>
              <div>
                <p className="text-xl font-bold">Usuario</p>
                <p className='text-xl'>{userData.usuario}</p>
              </div>
              <div>
                <p className="text-xl font-bold">Email</p>
                <p className='text-xl'>{userData.email}</p>
              </div>
              <div>
                <p className="text-xl font-bold">Tipo de cuenta</p>
                <p className='text-xl'>{userData.rol}</p>
              </div>
              <div>
                <p className="text-xl font-bold">Teléfono</p>
                <p className='text-xl'>{userData.telefono}</p>
              </div>
            </div>

          </div>

        </div>
        {/* Botón de cambiar contraseña */}
        <div className="mt-4 md:mt-8 text-center md:text-left">
          <button type='button'
            onClick={handleChangePassword}
            className="btn-secondary"
          >
            Cambiar Contraseña
          </button>
        </div>

      </div>
    </div>
  );
};

export default Profile;