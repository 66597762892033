//import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgregarResiduo } from "../../../Apis/FetchCatalogos";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Residuo", successText: 'Residuo agregado correctamente.' }

export const initValuesAr = {
    clave: "",
    nombre: "",
    uni: "",
    tipo: "",
    titulo: "",
    clasificacion: "",
    clvine: "",
    sistemadfinal: "",
    empresadfinal: "",
    autorizacion: "",
    codigo: ""
  };

export async function ImprimirContrato() {
    console.log("clic imprimir contrato residuos: ");
}

export async function CrearResiduo(values) {
    const datosResi = {
        clave: values.clave,
        nombre: values.nombre,
        unidad: values.uni,
        tipo: values.tipo,
        titulo: values.titulo,
        repine1: values.clasificacion,
        repine2: values.clvine,
        repine3: values.sistemadfinal,
        repine4: values.empresadfinal,
        repine5: values.autorizacion,
        codpel: values.codigo
      };
    //await sleep(5000);
    await AgregarResiduo(datosResi);
    return true;
}


export const urlCatalogo = "/suvalsa/catalogos/residuos";
