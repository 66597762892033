import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initTE, Collapse, Ripple } from "tw-elements";
import { useAppStore } from "../../Stores/useAppStore";
import ButtonShowMenu from "../Button/ButtonShowMenu";
// Ejemplo con heroicons (https://heroicons.com/):
import { UserCircleIcon } from "@heroicons/react/24/solid";
import LogoutConfirmModal from "./componets/LogoutModal";


export default function InternalHeader() {
  const getDataUser = useAppStore((state) => state.getDataUser);
  const { logout, showMenu } = useAppStore();
  const app = useAppStore(state => state.app);

  const userName = getDataUser().user_name;
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  // Referencia al contenedor del dropdown
  const dropdownRef = useRef(null);

  function onShowHide() {
    showMenu(!app.menuSideNav.show)
  }

  useEffect(() => {
    initTE({ Collapse, Ripple });
  }, []);

  // Maneja la apertura/cierre del dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Cerrar el dropdown cuando se hace clic fuera
  useEffect(() => {
    function handleClickOutside(event) {
      // Si el menú está abierto y el clic fue fuera del dropdownRef
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <header className="flex flex-col md:flex-row min-h-16 bg-primary-gradient justify-between w-full gap-2 shadow-lg px-2">
      <div className="flex justify-normal md:justify-end items-center gap-2 md:gap-4">
        {/* Botón para abrir/cerrar sidebar en móvil */}
        {!app.menuSideNav.show && (
          <ButtonShowMenu onShowHide={onShowHide} />
        )}

        <div className="flex md:flex-row items-start md:items-center gap-2">
          <span className="hidden md:block header-title">Bienvenido/a a Suvalsa</span>
          <span className="md:hidden header-title">Hola</span>
          <span className="font-bold text-xl text-slate-50">{userName}</span>
        </div>
      </div>

      <nav
        className="relative flex items-center justify-end pb-2 md:pb-0 bg-transparent text-blue-700 focus:text-neutral-700 dark:bg-neutral-600 dark:text-neutral-200 md:flex-wrap"
        data-te-navbar-ref
      >
        <div className="flex md:w-full flex-wrap items-end justify-between">
          <div
            className="!visible grow basis-[100%] items-center lg:!flex lg:basis-auto"
            id="navbarSupportedContentY"
            data-te-collapse-item
          >
            <ul className="mr-auto flex flex-row gap-4 items-center justify-end" data-te-navbar-nav-ref>
              <li data-te-nav-item-ref>
                <button
                  onClick={() => navigate("/suvalsa/home")}
                  className="header-link"
                  data-te-nav-link-ref
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Dashboard Principal
                </button>
              </li>

              {/* Ícono de Avatar: Se oculta en pantallas pequeñas con `hidden md:block` */}
              <li
                className="relative hidden md:block"
                data-te-nav-item-ref
              >
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="header-link flex items-center gap-1"
                >
                  <UserCircleIcon className="w-8 h-8 text-white" />
                </button>

                {/* Dropdown (visible si isDropdownOpen = true) */}
                {isDropdownOpen && (
                  <div ref={dropdownRef}
                    className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md">
                    <button
                      onClick={() => {
                        setIsDropdownOpen(false);
                        navigate("/suvalsa/cuenta");
                      }}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      Perfil
                    </button>
                    <button
                      onClick={() => {
                        setIsDropdownOpen(false);
                        navigate("/suvalsa/cuenta/cambio-contrasena");
                      }}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      Cambiar Contraseña
                    </button>
                    <button
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setShowLogoutModal(true);
                      }}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600"
                    >
                      Cerrar Sesión
                    </button>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Modal de confirmación de logout */}
      <LogoutConfirmModal
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        onConfirm={logout}
      />
    </header>
  );
}
