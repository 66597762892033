import React, { useEffect } from "react";
// Initialization for ES Users
import { Ripple, initTE } from "tw-elements";
import EtiquetaPagina from "../Funcionales/EtiquetaPagina";

const CardNavigate = (props) => {
  useEffect(() => {
    initTE({ Ripple });
  }, []);
  return (
    <>
      <div className="block w-full rounded-lg bg-transparent shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 mt-2">
        <EtiquetaPagina texto={props.title}/>
        <span className="block border-primary-200 border-[1.5px] w-full"></span>
        <div className="px-2 pb-2">
            { props.subtitle ? <h5 className="mb-2 text-xl font-medium leading-tight dark:text-neutral-50">
                {props.subtitle}
              </h5> : ''
            }
            {
                props.description ? 
                <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    {props.description}
                </p>
                : ''
            }
            {
                props.children ? (
                <>  { props.children}
                </>
                ) : ''
            }
          {
           props.callback && props.callback.action ? 
                <button onClick={props.callback.action}
                type="button"
                href="#"
                className="btn-primary"
                data-te-ripple-init
                data-te-ripple-color="light"
                >
                    {props.callback.text}
                </button> : ''
          }
          
        </div>
      </div>
    </>
  );
};

export default CardNavigate;
