// Función para manejar eventos de teclado con acceso al data actualizado
export const handleKeyDown = (e, dataRef, table) => {
    const key = e.key;

    if (!["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(key)) {
        return;
    }

    e.preventDefault();

    // Encontrar la celda actual
    const currentElement = e.target;
    const parentCell = currentElement.closest('div[data-row][data-col]');
    if (!parentCell) return;

    const rowIndex = parseInt(parentCell.getAttribute('data-row'), 10);
    const colIndex = parseInt(parentCell.getAttribute('data-col'), 10);

    // Verificar que rowIndex y colIndex son números válidos
    if (isNaN(rowIndex) || isNaN(colIndex)) {
        console.warn("rowIndex o colIndex no son números válidos:", rowIndex, colIndex);
        return;
    }

    let newRow = rowIndex;
    let newCol = colIndex;

    const totalRows = dataRef.current.length;
    const allColumns = table.getVisibleFlatColumns(); // Asegúrate de usar solo columnas visibles
    const totalCols = allColumns.length;

    switch (key) {
        case "ArrowUp":
            newRow = (rowIndex - 1 + totalRows) % totalRows;
            break;
        case "ArrowDown":
            newRow = (rowIndex + 1) % totalRows;
            break;
        case "ArrowLeft":
            newCol = (colIndex - 1 + totalCols) % totalCols;
            break;
        case "ArrowRight":
            // Implementar wrap-around horizontal
            if (colIndex === totalCols - 1) {
                newCol = 0; // Mover al inicio de la misma fila
            } else {
                newCol = colIndex + 1;
            }
            break;
        default:
            break;
    }

    // Implementar el wrap-around en columnas izquierda
    if (key === "ArrowRight" && colIndex === totalCols - 1) {
        newCol = 0; // Mover al inicio de la misma fila
    }
    if (key === "ArrowLeft" && colIndex === 0) {
        newCol = totalCols - 1; // Mover al final de la misma fila
    }

    // Encontrar la celda objetivo
    const targetCell = document.querySelector(
        `[data-row="${newRow}"][data-col="${newCol}"] input, [data-row="${newRow}"][data-col="${newCol}"] select`
    );

    if (targetCell) {
        targetCell.focus();
    } else {
        console.warn(`No se encontró el elemento en data-row="${newRow}" data-col="${newCol}"`);
    }
}

// Función optimizada para manejar la edición de celdas
export const handleCellEdit = (setData, id, field, value) => {
    setData(prevData => {
        console.log(prevData, id)
        const rowIndex = prevData.findIndex(item => item.id === id);
        console.log(rowIndex)
        if (rowIndex === -1) return prevData; // Fila no encontrada

        // Crear una copia de la fila modificada
        let updatedRow = { ...prevData[rowIndex], [field]: value };
        // Crear una copia del arreglo de datos con la fila actualizada
        const newData = [...prevData];
        newData[rowIndex] = updatedRow;
        console.log(newData)
        return newData;
    })
}

export const handleCellEditValid = (setData, type, id, field, value) => {
    setData(prevData => {
        const rowIndex = prevData.findIndex(item => item.id === id);
        if (rowIndex === -1) return prevData;
        let updatedRow = { ...prevData[rowIndex], [field]: value };
        const updatedErrors = { ...(updatedRow.errors || {}) };
        if (type === 'number') {
            const cantidad = Number(value);
            if (isNaN(cantidad) || cantidad <= 0) {
                updatedErrors[field] = {
                    hasError: true,
                    message: 'El valor debe ser mayor a 0',
                };
            } else {
                updatedErrors[field] = {
                    hasError: false,
                    message: '',
                };
            }
        }
        updatedRow.errors = updatedErrors;
        const newData = [...prevData];
        newData[rowIndex] = updatedRow;
        return newData;
    });
}