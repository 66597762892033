// Importación de librerías y componentes necesarios
import { useEffect, useRef, useState } from "react";
import { Modal, initTE } from "tw-elements";
import {
  Generarmanifiesto,
  ObtenerClientesManifiestos,
  ObtenerClientesManifiestosPorFecha,
  ObtenerClientesManifiestosPorFechaNative,
  ObtenerMaxManifiesto
} from "../../../Apis/FetchManifiestos";
import { BuscarRutaClave } from "../../../Apis/FetchRutas";
import { ObtenerAgentes, ObtenerVehiculos } from "../../../Apis/FetchCatalogos";
import dayjs from "dayjs";
//import { ObtenerSalidasManif } from "../../../Apis/FetchSalidas";
import AltaManifiestosPdf from "../../../componentes/PDFServices/AltaManifiestosPdf";
import { pdf } from '@react-pdf/renderer';
import { ActualizarSalidasManif, ObtenerSalidasFechaRuta } from "../../../Apis/FetchSalidas";
import { ActualizarInsumo, BuscarInsumoPorClave } from "../../../Apis/FetchInsumos";
import { useExecutePromise } from "../../../Hooks/UsePromiseAction";
import generarPDFGastosViaje from "../../../componentes/PDFServices/GeneraReporteGastosPDF";
import BitacoraRuta from "../../../componentes/PDFServices/BitacoraRuta";
import ReporteDiarioFallas from "../../../componentes/PDFServices/ReporteDiarioFallas";
import RelacionRuta from "../../../componentes/PDFServices/RelacionRuta";
import CartaResponsiva from "../../../componentes/PDFServices/CartaResponsiva";
import ReporteDiario from "../../../componentes/PDFServices/ReporteDiario";
import BitacoraHorasServicio from "../../../componentes/PDFServices/BitacoraHorasServicio";
import CustomToastify from "../../../componentes/Toasts/CustomToast";

export async function GenerarRutaManifiesto(noSalida, ruta, datosManif, datosViaje, listInsumos) {
  let noManif = await ObtenerMaxManifiesto();
  if (noManif !== null) {
    if (noManif === "") noManif = 0;
    else noManif = Number(noManif.claveFinal);
  }
  //aqui debe existir una condicion de que si no se obtiene el max de salida, no se agregue
  if (noManif >= 0) {
    let isNoManif =
      datosManif.servicio === "CB" ||
      datosManif.servicio === "CI" ||
      datosManif.servicio === "TI";
    if (isNoManif) noManif += 1;

    const noManifiesto = isNoManif ? noManif.toString() : datosManif.noManifiesto;

    for (let ins of listInsumos) {
      const cant = Number(ins.cantidad);
      const currentIns = await BuscarInsumoPorClave(ins.insumo)
      //si se encontro el insumo, se crea la salida, si no, deberia de saltarse este insumo
      if (currentIns === null) {
        return
      }

      //si se capturo un valor numerico y es mayor a 0
      if (!isNaN(cant) && cant > 0) {
        console.log(
          "se agregara la salida para ",
          ins.insumo,
          "con folio ",
          noSalida
        );

        // console.log('bodyData ActualizarInsumo:', ins.insumo, {
        //   existen: (currentIns.existen ?? 0) - cant
        // })
        await ActualizarInsumo(ins.insumo, {
          existen: (currentIns.existen ?? 0) - cant
        });
      }
    }
    //console.log('actualizando salida')
    if (noSalida && noSalida !== null && noSalida !== '') {
      //console.log(noManifiesto)
      await ActualizarSalidasManif(noSalida, noManifiesto);
    }
    //aqui si se crea la salida, se continua con la generacion del manifiesto
    const bodyGenManif = {
      clave: noManifiesto,
      tipo: "O",
      ruta: ruta,
      fruta: dayjs(datosViaje.fInicio, "YYYY-MM-DD"), //"2026-01-13T06:00:00.000Z",
      cliente: datosManif.cliente,
      fvisita: datosManif.fvisita, //"2006-05-04 00:00:00",
      orden: datosManif?.orden.toString(),
      salida: noSalida ? noSalida.toString() : '',
      status: "",
      fam: datosManif.familia,
      serv: datosManif.servicio,
      operador: datosViaje.oper,
      auxiliar: datosViaje.aux,
      vehiculo: datosViaje.veh,
      duracion: datosViaje.duracion,
      factura: "",
      planta: datosManif.planta,
      rutaplanta: datosManif.rutaplanta
    };
    //console.log("bodyGenManif:",bodyGenManif)
    await Generarmanifiesto(bodyGenManif);
  } else {
    console.log("no se recupero el max de salidas o de manifietos");
    return false;
  }
}

async function generarBitacoraRuta(ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo) {
  console.log('Generando bitacora para la ruta:', ruta, 'y fecha:', fecha);
  console.log('Fecha de inicio:', fInicio);

  try {
    const manifiestos = await ObtenerClientesManifiestosPorFechaNative(ruta, fecha);
    console.log('Manifiestos obtenidos para generar el reporte:', manifiestos);

    const manifiestosFiltrados = manifiestos.filter(manifiesto => {
      return manifiesto.manifClave !== null && manifiesto.manifClave !== "";
    });
    console.log('Manifiestos filtrados:', manifiestosFiltrados);


    // Ordenar los manifiestos filtrados por 'manifClave' de menor a mayor
    const manifiestosOrdenados = manifiestosFiltrados.sort((a, b) => {
      return a.manifClave.localeCompare(b.manifClave); // Ordenar como cadenas
    });

    console.log('Manifiestos ordenados:', manifiestosOrdenados);

    if (manifiestos.length === 0) {
      console.log('No se encontraron manifiestos para la clave y fecha proporcionadas.');
      return;
    }

    // Generar el PDF utilizando el componente BitacoraRuta
    const pdfInstanceBitacora = pdf(
      <BitacoraRuta
        data={{
          manifiestos: manifiestosOrdenados,
          ruta: ruta,
          fecha: fecha,
          fInicio: dayjs(fInicio).add(duracion, 'day').format("DD-MM-YYYY"),

          nombre: nombre,
          duracion: duracion,
          operador: operador,
          auxiliar: auxiliar,
          vehiculo: vehiculo
        }}
      />
    );
    const blobBitacora = await pdfInstanceBitacora.toBlob(); // Crear el blob para BitacoraRuta


    // Descargar el archivo PDF generado para BitacoraRuta
    const linkBitacora = document.createElement('a');
    linkBitacora.href = URL.createObjectURL(blobBitacora);
    linkBitacora.download = 'bitacora_ruta.pdf'; // Nombre del archivo para BitacoraRuta
    linkBitacora.click(); // Iniciar la descarga
    console.log('Reporte generado exitosamente en bitacoraruta.pdf');

  } catch (error) {
    console.error('Error al generar la bitacora:', error);
  }
}

async function generarReporteConClaveYFecha(ruta, fecha) {
  console.log('Generando reporte para la clave:', ruta, 'y fecha:', fecha);

  try {
    // Consultar los manifiestos con la clave y fecha proporcionadas
    const manifiestos = await ObtenerClientesManifiestosPorFechaNative(ruta, fecha);
    console.log('Manifiestos obtenidos para generar el reporte:', manifiestos);

    const manifiestosFiltrados = manifiestos.filter(manifiesto => {
      return manifiesto.manifClave !== null && manifiesto.manifClave !== "";
    });
    console.log('Manifiestos filtrados:', manifiestosFiltrados);


    // Ordenar los manifiestos filtrados por 'manifClave' de menor a mayor
    const manifiestosOrdenados = manifiestosFiltrados.sort((a, b) => {
      return a.manifClave.localeCompare(b.manifClave); // Ordenar como cadenas
    });

    console.log('Manifiestos ordenados:', manifiestosOrdenados);

    if (manifiestos.length === 0) {
      console.log('No se encontraron manifiestos para la clave y fecha proporcionadas.');
      return;
    }




    // Generar el PDF utilizando el componente AltaManifiestosPdf
    const pdfInstanceAlta = pdf(<AltaManifiestosPdf datos={manifiestosOrdenados} />);
    const blobAlta = await pdfInstanceAlta.toBlob(); // Crear el blob para AltaManifiestosPdf



    // Descargar el archivo PDF generado para AltaManifiestosPdf
    const linkAlta = document.createElement('a');
    linkAlta.href = URL.createObjectURL(blobAlta);
    linkAlta.download = 'manifiestosRuta.pdf'; // Nombre del archivo para AltaManifiestosPdf
    linkAlta.click(); // Iniciar la descarga



    console.log('Reporte generado exitosamente en output.pdf');

  } catch (error) {
    console.error('Error al generar el reporte:', error);
  }
}


// Función para calcular el día de visita basado en la fecha de inicio y fecha de visitaimport { ObtenerSalidasManif } from "../../../Apis/FetchSalidas";

function obtenerDiaVisita(fInicio, fVisita) {
  let diaVisita = "";
  try {
    if (!fInicio || !fVisita) return "";

    const dateIni = dayjs(fInicio);
    const dateVis = dayjs(fVisita);
    diaVisita = dateVis.diff(dateIni, "day") + 1;
  } catch (error) {
    console.log(error);
  }

  return diaVisita;
}

// Función para cargar los manifiestos de clientes según la clave y fecha proporcionadas
export async function loadClientesManif(clave, fecha, isUpdate = false, setListaPaquetesInsumos) {
  let cManif = [];
  // Si se proporcionan clave y fecha, se intenta obtener los manifiestos según estos datos
  if (clave !== "" && fecha !== "") {
    cManif = await ObtenerClientesManifiestosPorFecha(clave, fecha);
  }
  // Si no se encontraron manifiestos según la fecha, se obtiene solo por clave
  if (!isUpdate) {
    const clientesManif = await ObtenerClientesManifiestos(clave);
    const auxManifest = cManif.length > 0 ? clientesManif.map(clManif => {
      const manifFecha = cManif.find(cf => cf.cliente === clManif.cliente);
      if (manifFecha) {
        return {
          ...manifFecha,
          manif: manifFecha.clave
        }
      } else return clManif
    }) : clientesManif;
    //consultando los paquetes de insumos asociado a la ruta por fecha
    const paquetesInsumos = fecha !== "" ? await ObtenerSalidasFechaRuta(clave, fecha) : [];
    setListaPaquetesInsumos(paquetesInsumos)
    //si existen paquetes de salidas
    if (paquetesInsumos.length) {
      const newClientsManif = await Promise.all(
        auxManifest.map(async (res) => {
          const paqInsumo = paquetesInsumos.find(paq => paq.cliente === res.cliente)
          //se valida si no trae salida se le agrega en base al paquete asignado de insumos
          if ((!res.salida) && paqInsumo) {
            return {
              ...res,
              salida: paqInsumo.numero,
            };
          } else
            return { ...res }
        })
      );
      //se actualiza el arreglo de clientes con las salidas asociadas a la ruta
      cManif = newClientsManif
    } else
      cManif = auxManifest
  }

  // if (cManif.length === 0 && !isUpdate) {
  //   cManif = await ObtenerClientesManifiestos(clave);
  // }

  let rowsNew = [];

  // Mapeo de los datos recibidos para crear una estructura adecuada
  if (cManif && cManif.length) {

    rowsNew = await Promise.all(
      cManif.map(async (res) => {
        return {
          id: res.id,
          clave: res.cliente,
          ciudad: res.ciudad,
          familia: res.fam,
          nombre: res.nombre,
          ruta: res.ruta,
          orden: res.orden,
          servicio: res.serv,
          dia: res.dia ?? obtenerDiaVisita(fecha, res.fvisita),
          fvisita: res.fvisita,
          salida: res.salida,
          manifiesto: res.manif, // Se obtiene de la consulta por clave y fecha
          planta: res.planta,
          rutaplanta: res.rutaplanta ? res.rutaplanta : `${res?.ciudad ?? ""} / ${res?.estado ?? ""}`
        };
      })
    );
  }
  return rowsNew;
}

// Función para reestablecer la fecha de visita basada en la fecha de inicio y día proporcionado
function reeestablecerFechaVisita(fInicio, dia) {
  console.log(fInicio, dia);
  if (dia === "") return "";

  const date = dayjs(fInicio);
  let days = Number(dia);
  if (isNaN(days)) return "";

  return date.add(days - 1, "days").format("YYYY-MM-DD");
}

// Función para recargar los manifiestos de clientes con nuevas fechas de visita
export async function reloadClientesManif(data, fInicio) {
  let rowsNew = [];
  console.log("lista de Clientes Manifiestis: ", data);

  if (data) {
    rowsNew = data.map((res) => {
      const fechaVisita = reeestablecerFechaVisita(fInicio, res.dia);
      return {
        id: res.id,
        clave: res.clave,
        ciudad: res.ciudad,
        familia: res.familia,
        nombre: res.nombre,
        ruta: res.ruta,
        orden: res.orden,
        servicio: res.servicio,
        dia: res.dia ?? obtenerDiaVisita(fInicio, fechaVisita),
        fvisita: fechaVisita,
        salida: res.salida,
        manifiesto: res.manifiesto,
        planta: res.planta,
        rutaplanta: res.rutaplanta
      };
    });
  }
  console.log(rowsNew);
  return rowsNew;
}

// Hook principal para manejar la lógica de los manifiestos
const useManifiestos = () => {
  // Definición de los estados necesarios para el manejo de los manifiestos
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [dataRowSelected, setDataRowSelected] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [agentes, setAgentes] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  //para almacenar los insumos del paquete
  const [lisatPaquetesInsumos, setListaPaquetesInsumos] = useState([]);
  const [listaClientesMasivo, setListaClientesMasivo] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);// para lops filtro de la tabla manifiestos

  const initValuesRutaForm = {
    nombre: "",
    dias: "",
    oper: "",
    operador: "",
    aux: "",
    auxiliar: "",
    noEco: "",
    vehiculo: "",
    id: "",
    claveSearch: ""
  }
  // Estado inicial para la ruta
  const [rutaData, setRutaData] = useState([initValuesRutaForm]);

  // Definición de los IDs de los modales utilizados
  const idModalAgregarInsumo = "modalAgregarInsumo";
  const idModalSalidas = "modalSalidas";
  const idModalGenerarMasivos = "modalGenerarMasivos";

  // Función para manejar el envío del formulario de alta de manifiestos
  async function onSubmitAltaManifestos(values) {
    console.log("onsubmit....");
    console.log("action alta");
    // Validación de que todas las fechas de visita han sido capturadas
    if (data.some((m) => m.fvisita === "")) {
      console.log(
        "Favor de completar todas las fechas de visita antes de guardar"
      );
    } else {
      // Validación de que los días agregados estén dentro del rango establecido
      data.every((clm) => {
        const inputDia = clm.dia === "" ? 0 : Number(clm.dia);
        console.log("dia capturado:", inputDia);
        if (inputDia > values.dias) {
          return false;
        } else {
          // Creación del manifiesto por cada registro
          return true;
        }
      });
    }
  }

  // Función para obtener los datos de manifiestos según la clave y fecha
  async function getDataFunction(clave, fecha, isUpdate = false) {
    setLoadingData(true);
    setData(await loadClientesManif(clave, fecha, isUpdate, setListaPaquetesInsumos));
    setLoadingData(false);
  }

  // Función para buscar la información de una ruta específica
  async function BuscarRuta(clave) {
    console.log("consultando datos de la ruta:", clave);
    setLoadingData(true);
    let cRuta = [
      {
        clave: clave,
        nombre: "",
        duracion: "",
        oper: "",
        operador: "",
        aux: "",
        auxiliar: "",
        noEco: "",
        vehiculo: "",
        id: ""
      }
    ];
    if (clave && clave !== "") {
      const ruta = clave ? await BuscarRutaClave(clave) : null;
      console.log(ruta);

      if (ruta !== null && ruta.length > 0) {
        cRuta = ruta.map((r) => {
          const auxRuta = {
            clave: r.clave,
            nombre: r.nombre,
            duracion: r.duracion,
            oper: r.operador,
            operador: buscarAgente("OPE", r.operador),
            aux: r.auxiliar,
            auxiliar: buscarAgente("AUX", r.auxiliar),
            noEco: r.vehiculo,
            vehiculo: buscarVehiculo(r.vehiculo),
            id: r.id
          };
          return auxRuta;
        });
      }
    }
    console.log(cRuta);
    setRutaData(...cRuta);
    setLoadingData(false);
    return cRuta[0];
  }

  // Función para buscar un agente según su puesto y clave
  const buscarAgente = (puesto, clave) => {
    const selected = agentes
      ? agentes.find((op) => (op.puesto = puesto && op.clave === clave))
      : undefined;
    return selected?.nombre ?? "";
  };

  // Función para buscar un vehículo según su clave económica
  const buscarVehiculo = (claveEco) => {
    if (!claveEco) return "";

    const veh = vehiculos
      ? vehiculos.find((op) => op.CLAVE.trim() === claveEco.trim())
      : undefined;

    if (veh) return veh.MARCA + " " + veh.MODELO;
    else return "";
  };

  // Función para agregar una salida al manifiesto
  async function addSalida(noSalida, noManif) {
    setLoadingData(true);
    let rowsNew = [];
    console.log(noSalida, noManif)
    if (data) {
      rowsNew = data.map((res, index) => {
        return {
          id: res.id,
          clave: res.clave,
          ciudad: res.ciudad,
          familia: res.familia,
          nombre: res.nombre,
          ruta: res.ruta,
          orden: res.orden,
          servicio: res.servicio,
          dia: res.dia,
          fvisita: res.fvisita,
          salida: dataRowSelected.index === index ? noSalida : res.salida,
          manifiesto:
            dataRowSelected.index === index ? noManif : res.manifiesto,
          planta: res.planta,
          rutaplanta: res.rutaplanta
        };
      });
    }
    setData(rowsNew);
    setLoadingData(false);
    return rowsNew;
  }

  // Función para mostrar el modal de salidas
  function consultarSalidas() {
    console.log("action click Consultar:", idModalSalidas);
    const myModal = new Modal(document.getElementById(idModalSalidas));
    myModal.show();
  }

  // Función para mostrar el modal de agregar insumo
  function actionElaborar() {
    const myModal = new Modal(document.getElementById(idModalAgregarInsumo));
    myModal.show();
  }
  // Función para mostrar el modal de crear manifiestos Masivos
  function actionElaborarMasivos() {

    //se valida que no existan registros sin dia/ fecha visita
    const isNotDay = data.find(clMas => (!clMas.dia || !clMas.fvisita))
    console.log(isNotDay)
    if (isNotDay) {
      console.log('mostrando clidaicon dia')
      CustomToastify({
        title: "Elaborar Manifiestos Masivos",
        message: "Existen clientes no asignados en la creación de manifiestos para la ruta asignada, favor de completar.",
        type: "warning"
      })
      //se deben marcan cas casillas en rojo indicando que son incorrectas
      setData(prevData => {
        return prevData.map(row => {
          let newRow = { ...row }
          let errors = {
            dia: {
              hasError: false,
              message: '',
            },
            // manifiesto: {
            //     hasError: false,
            //     message: '',
            // },
          }
          if (!row.dia || !row.fvisita) {
            errors.dia.hasError = true
            errors.dia.message = "Dato requerido."
            newRow = {
              ...row,
              ['dia']: '0', ['errors']: errors
            }
            setColumnFilters([
              {
                id: "dia",
                value: "0"
              }
            ])
          }
          return newRow
        })
      }
      )
      return;
    }

    let newClientsMas = [];
    for (let clientsManif of data) {
      if (clientsManif.fvisita && !clientsManif.manifiesto) {
        newClientsMas.push(clientsManif)
        //break;
      }
    }

    if (newClientsMas.length) {
      setListaClientesMasivo(newClientsMas)
      const myModal = new Modal(document.getElementById(idModalGenerarMasivos));
      myModal.show();
    }
  }

  const { runAction, loadingAction } = useExecutePromise();
  const title = "Alta de Manifiesto";
  const pendingText = "Enviando manifiesto, por favor espere.";
  const successText = "Datos de manifiestos y Viaje agregados correctamente.";
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";
  // Función para mostrar el modal de agregar insumo
  async function elaborarMasivo(listaClientes, listInsumos, fInicio, callBackAction) {
    console.log("action click Elaborar Masivo:", data);
    await runAction({
      promiseAction: async () => {
        for (let cl of listaClientes) {
          const datosManif = {
            noManifiesto: cl?.manifiesto,
            familia: cl?.familia,
            cliente: cl?.clave,
            orden: cl?.orden,
            servicio: cl?.servicio,
            fvisita: cl?.fvisita,
            planta: cl?.planta,
            rutaplanta: cl?.rutaplanta
          }
          const datosViaje = {
            fInicio: fInicio,
            oper: rutaData.oper,
            aux: rutaData.aux,
            veh: rutaData.noEco,
            duracion: rutaData.duracion
          }

          const listaInsPorCliente = listInsumos.filter(ins => ins.cliente === cl.clave);

          await GenerarRutaManifiesto(cl.salida, rutaData.clave, datosManif, datosViaje, listaInsPorCliente, callBackAction, idModalAgregarInsumo)
        }
        if (callBackAction) {
          await callBackAction();
        }
        if (idModalAgregarInsumo) {
          const myModalEl = document.getElementById(idModalAgregarInsumo);
          if (myModalEl) Modal.getOrCreateInstance(myModalEl).hide();
        }
      },
      title,
      pendingText,
      successText,
      errorText
    });
    return true;
  }

  function isMasivoEnabled() {
    let validate = false;
    for (let clientsManif of data) {
      if (clientsManif.fvisita && !clientsManif.manifiesto) {
        validate = true
        console.log("clientsManif.salida:", clientsManif.clave, clientsManif.salida)
        break;
      }
    }

    return !loadingData && validate
  }

  // Definición de los botones del grupo de acciones
  const buttonsGroup = {
    buttonPrimary: {
      action: actionElaborar,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        !dataRowSelected?.fvisita ||
        (dataRowSelected?.salida || (dataRowSelected?.manifiesto &&
          (
            dataRowSelected?.servicio === "CB" ||
            dataRowSelected?.servicio === "CI" ||
            dataRowSelected?.servicio === "TI")
        )) ||
        (!dataRowSelected?.manifiesto &&
          !(
            dataRowSelected?.servicio === "CB" ||
            dataRowSelected?.servicio === "CI" ||
            dataRowSelected?.servicio === "TI")
        ),
      type: "button",
      label: "Elaborar"
    },
    buttonSecondary: {
      action: consultarSalidas,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        dataRowSelected?.salida === "",
      type: "button",
      label: "Consultar"
    },
    getButtonTertiary: (ruta, fechaInicio) => ({
      action: async () => {
        setLoadingData(true)
        console.log(ruta, fechaInicio)
        if (!ruta || !fechaInicio.current) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar el reporte.');
          return;
        }
        await generarReporteConClaveYFecha(ruta, fechaInicio.current);
        setLoadingData(false)
      },
      disabled: loadingData || data.length <= 0 || !ruta || !fechaInicio.current,
      type: "button",
      label: "Generar Reporte"
    }),
    buttonCuatriary: {
      action: actionElaborarMasivos,
      disabled: !isMasivoEnabled(),
      type: "button",
      label: "Elaborar Masivo"
    },
    buttonSextenary: (clave, fInicio, nombre, duracion, operador, auxiliar, vehiculo) => ({
      action: async () => {

        if (!clave || !fInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar el reporte.');
          return;
        }
        // Aquí puedes agregar cualquier lógica adicional
        const fechaFin = dayjs(fInicio).add(duracion, 'day').format("YYYY-MM-DD");

        const datosReporte = {
          rutaF: "Ruta-" + clave + ' ' + nombre,
          fechaInicio: dayjs(fInicio).format("DD-MM-YYYY"),
          fechaFin: dayjs(fechaFin).format("DD-MM-YYYY"),
          vehiculo: vehiculo,
          marca: vehiculo,
          placas: vehiculo,
          fechaGeneracion: dayjs().format("DD-MM-YYYY"), // Fecha actual
          horaGeneracion: dayjs().format("hh:mm A"), // Hora actual

        };

        try {
          setLoadingData(true)

          // Llama a la función para generar el reporte
          await generarPDFGastosViaje(datosReporte); // Asegúrate de que esta función sea async si es necesario
        } catch (error) {
          console.error("Error al generar el reporte:", error);
        }
        setLoadingData(false)


      },
      disabled: loadingData || !(clave && fInicio),
      type: "button",
      label: "Gastos de Ruta"
    }),
    buttonSevenary: (clave, fInicio, nombre, duracion, oper, aux, noEco) => ({
      action: async () => {
        if (!clave || !fInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar la bitácora.');
          return;
        }
        console.log('Datos enviados al botón Sevenary:', { clave, fInicio, nombre, duracion, oper, aux, noEco });
        setLoadingData(true)

        await generarBitacoraRuta(clave, fInicio, fInicio, nombre, duracion, oper, aux, noEco);
        setLoadingData(false)

      },
      disabled: !clave || !fInicio, // Botón deshabilitado si clave o fInicio están vacíos
      type: "button",
      label: "Bitacora de Ruta",
    }),
    buttonEightary: (clave, fInicio, nombre, duracion, oper, aux, noEco) => ({
      action: async () => {
        if (!clave || !fInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar la bitácora.');
          return;
        }
        console.log('Datos enviados al botón buttonEightary:', { clave, fInicio, nombre, duracion, oper, aux, noEco });
        setLoadingData(true)

        await generarRelacionRuta(clave, fInicio, fInicio, nombre, duracion, oper, aux, noEco);
        setLoadingData(false)

      },
      disabled: !clave || !fInicio, // Botón deshabilitado si clave o fInicio están vacíos
      type: "button",
      label: "Relacion de Ruta",
    }),
    buttonNovenarary: (clave, fInicio, nombre, duracion, oper, aux, noEco) => ({
      action: async () => {
        if (!clave || !fInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar la bitácora.');
          return;
        }
        console.log('Datos enviados al botón buttonNovenarary:', { clave, fInicio, nombre, duracion, oper, aux, noEco });
        setLoadingData(true)

        await generarCartaResponsiva(clave, fInicio, fInicio, nombre, duracion, oper, aux, noEco);
        setLoadingData(false)

      },
      disabled: !clave || !fInicio, // Botón deshabilitado si clave o fInicio están vacíos
      type: "button",
      label: "Carta Responsiva",
    }),
    buttonDecenary: (clave, fInicio, nombre, duracion, oper, aux, noEco) => ({
      action: async () => {
        if (!clave || !fInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar la bitácora.');
          return;
        }
        console.log('Datos enviados al botón buttonDecenary:', { clave, fInicio, nombre, duracion, oper, aux, noEco });
        setLoadingData(true)

        await generarReporteDiario(clave, fInicio, fInicio, nombre, duracion, oper, aux, noEco);
        setLoadingData(false)

      },
      disabled: !clave || !fInicio, // Botón deshabilitado si clave o fInicio están vacíos
      type: "button",
      label: "Reporte Diario Fallas",
    }),
    buttonUndecenary: (clave, fInicio, nombre, duracion, oper, aux, noEco) => ({
      action: async () => {
        if (!clave || !fInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar la bitácora.');
          return;
        }
        console.log('Datos enviados al botón buttonDecenary:', { clave, fInicio, nombre, duracion, oper, aux, noEco });
        setLoadingData(true)

        await generarBitacoraHorasServicio(clave, fInicio, fInicio, nombre, duracion, oper, aux, noEco);
        setLoadingData(false)

      },
      disabled: !clave || !fInicio, // Botón deshabilitado si clave o fInicio están vacíos
      type: "button",
      label: "Bitacora Horas Servicio",
    }),
    buttonDuocenary: (clave, fInicio, nombre, duracion, oper, aux, noEco) => ({
      action: async () => {
        if (!clave || !fInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar la bitácora.');
          return;
        }
        console.log('Datos enviados al botón buttonDecenary:', { clave, fInicio, nombre, duracion, oper, aux, noEco });
        setLoadingData(true)

        await generarRevisionOcultarVehiculo(clave, fInicio, fInicio, nombre, duracion, oper, aux, noEco);
        setLoadingData(false)

      },
      disabled: !clave || !fInicio, // Botón deshabilitado si clave o fInicio están vacíos
      type: "button",
      label: "Revision Vehiculo",
    }),

  };

  async function generarRevisionOcultarVehiculo(ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo, nombreconductor) {
    const placas = "";
    const origen = "";
    const destino = "";
    const datosReporte = {
      nombreconductor,
      ruta,
      fecha: dayjs(fecha).format("DD-MM-YYYY"),
      fInicio: dayjs(fInicio).add(duracion, 'day').format("DD-MM-YYYY"),
      nombre,
      duracion,
      operador,
      auxiliar,
      vehiculo,
      placas,
      origen,
      destino,
    };



    console.log('Datos enviados al componente BitacoraHorasServicio:', datosReporte);

    // Genera el PDF y descarga
    const pdfInstance = pdf(<ReporteDiarioFallas data={datosReporte} />);
    const blob = await pdfInstance.toBlob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'ReporteDiarioFallas.pdf';
    link.click();
  }

  async function generarBitacoraHorasServicio(ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo, nombreconductor) {
    const placas = "";
    const origen = "";
    const destino = "";
    const datosReporte = {
      nombreconductor,
      ruta,
      fecha: dayjs(fecha).format("DD-MM-YYYY"),
      fInicio: dayjs(fInicio).add(duracion, 'day').format("DD-MM-YYYY"),
      nombre,
      duracion,
      operador,
      auxiliar,
      vehiculo,
      placas,
      origen,
      destino,
    };



    console.log('Datos enviados al componente BitacoraHorasServicio:', datosReporte);

    // Genera el PDF y descarga
    const pdfInstance = pdf(<BitacoraHorasServicio data={datosReporte} />);
    const blob = await pdfInstance.toBlob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'BitacoraHorasServicio.pdf';
    link.click();
  }


  async function generarReporteDiario(ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo) {
    const datosReporte = {
      ruta,
      fecha: dayjs(fecha).format("DD-MM-YYYY"),
      fInicio: dayjs(fInicio).add(duracion, 'day').format("DD-MM-YYYY"),
      nombre,
      duracion,
      operador,
      auxiliar,
      vehiculo,
    };

    console.log('Datos enviados al componente CartaResponsiva:', datosReporte);

    // Genera el PDF y descarga
    const pdfInstance = pdf(<ReporteDiario data={datosReporte} />);
    const blob = await pdfInstance.toBlob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'ReporteDiarioDeFallas.pdf';
    link.click();
  }

  async function generarCartaResponsiva(ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo) {
    const datosReporte = {
      ruta,
      fecha,
      fInicio: dayjs(fInicio).add(duracion, 'day').format("DD-MM-YYYY"),
      nombre,
      duracion,
      operador,
      auxiliar,
      vehiculo,
    };

    console.log('Datos enviados al componente CartaResponsiva:', datosReporte);

    // Genera el PDF y descarga
    const pdfInstance = pdf(<CartaResponsiva data={datosReporte} />);
    const blob = await pdfInstance.toBlob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'CartaResponsiva.pdf';
    link.click();
  }

  async function generarRelacionRuta(ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo) {
    console.log('Generando bitacora para la ruta:', ruta, 'y fecha:', fecha);
    console.log('Fecha de inicio:', fInicio);

    try {
      const manifiestos = await ObtenerClientesManifiestosPorFechaNative(ruta, fecha);
      console.log('Manifiestos obtenidos para generar el reporte:', manifiestos);

      const manifiestosFiltrados = manifiestos.filter(manifiesto => {
        return manifiesto.manifClave !== null && manifiesto.manifClave !== "";
      });
      console.log('Manifiestos filtrados:', manifiestosFiltrados);


      // Ordenar los manifiestos filtrados por 'manifClave' de menor a mayor
      const manifiestosOrdenados = manifiestosFiltrados.sort((a, b) => {
        return a.manifClave.localeCompare(b.manifClave); // Ordenar como cadenas
      });

      console.log('Manifiestos ordenados:', manifiestosOrdenados);

      if (manifiestos.length === 0) {
        console.log('No se encontraron manifiestos para la clave y fecha proporcionadas.');
        return;
      }

      // Generar el PDF utilizando el componente BitacoraRuta
      const pdfInstanceRelacion = pdf(
        <RelacionRuta
          data={{
            manifiestos: manifiestosOrdenados,
            ruta: ruta,
            fecha: fecha,
            fInicio: dayjs(fInicio).add(duracion, 'day').format("DD-MM-YYYY"),

            nombre: nombre,
            duracion: duracion,
            operador: operador,
            auxiliar: auxiliar,
            vehiculo: vehiculo
          }}
        />
      );
      const blobBitacora = await pdfInstanceRelacion.toBlob(); // Crear el blob para BitacoraRuta


      // Descargar el archivo PDF generado para BitacoraRuta
      const linkBitacora = document.createElement('a');
      linkBitacora.href = URL.createObjectURL(blobBitacora);
      linkBitacora.download = 'relacion_ruta.pdf'; // Nombre del archivo para BitacoraRuta
      linkBitacora.click(); // Iniciar la descarga
      console.log('Reporte generado exitosamente en Relacion ruta.pdf');

    } catch (error) {
      console.error('Error al generar la bitacora:', error);
    }
  }



  async function ReporteGastosViajes(datosReporte) {
    console.log("Nuevo botón clickeado. Generando reporte...con los datos:", datosReporte);

    // Datos de prueba en caso de que no se proporcionen
    const datos = {
      rutaF: "Ruta 5 - FB CHIAPAS-pruebas",
      fechaInicio: "2024-01-01",
      fechaFin: "2024-01-31",
      vehiculo: "Camioneta ABC123",
      marca: "Toyota",
      placas: "XYZ-987",
      fechaGeneracion: dayjs().format("YYYY-MM-DD"), // Fecha actual
      horaGeneracion: dayjs().format("hh:mm A"), // Hora actual

    };


    try {
      // Llama a la función para generar el reporte
      await generarPDFGastosViaje(datos); // Asegúrate de que esta función sea async si es necesario
      console.log("Reporte generado exitosamente.");
    } catch (error) {
      console.error("Error al generar el reporte:", error);
    }
  }


  // Hook para inicializar y listar agentes y vehículos al cargar el componente
  useEffect(() => {
    initTE({ Modal });

    async function listarVehiculos() {
      setLoadingData(true);
      const listVehic = await ObtenerVehiculos();
      setVehiculos(listVehic);
      setLoadingData(false);
    }

    async function listarAgentes() {
      setLoadingData(true);
      const listaAgent = await ObtenerAgentes();
      setAgentes(listaAgent);
      setLoadingData(false);
    }

    // Consultar ambos catálogos (agentes y vehículos) al mismo tiempo
    const consultarTodo = async () => {
      const inicio = performance.now();
      await Promise.all([listarAgentes(), listarVehiculos()]);
      const fin = performance.now();
      console.log(fin - inicio);
    };
    consultarTodo();
  }, []);

  // Hook para actualizar la fila seleccionada cuando cambie la selección de filas
  useEffect(() => {
    if (data && data.length >= 0) {
      const indexKey = Object.keys(rowSelection);
      if (indexKey >= 0) {
        setDataRowSelected({
          ...data[Number(indexKey)],
          index: Number(indexKey)
        });
      }
    }
  }, [rowSelection, data]);

  // Retorno del hook con todas las funciones y estados necesarios
  return {
    getDataFunction,
    BuscarRuta,
    loadingData,
    loadingAction,
    rutaData,
    data,
    rowSelection,
    setRowSelection,
    dataRowSelected,
    columnFilters,
    setColumnFilters,
    setData,
    elaborarMasivo,
    lisatPaquetesInsumos,
    listaClientesMasivo, setListaClientesMasivo,
    idModalGenerarMasivos,
    idModalAgregarInsumo,
    idModalSalidas,
    addSalida,
    onSubmitAltaManifestos,
    buttonsGroup
  };
};

export const useActualizarManifiestos = () => {
  let dataSalidas = useRef([]);
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [dataRowSelected, setDataRowSelected] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  // const [agentes, setAgentes] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);

  const [, setRutaData] = useState([
    {
      clave: "",
      nombre: "",
      duracion: "",
      oper: "",
      aux: "",
      vehiculo1: "",
      id: ""
    }
  ]);

  const idModalAgregarInsumo = "modalAgregarInsumo";
  const idModalSalidas = "modalSalidas";

  async function onSubmitActManifestos(values) {
    console.log("action actualizar");
    //se valida que se cuente con todas las fechas de visita ya capturadas
    if (data.some((m) => m.fvisita === "")) {
      console.log(
        "Favor de completar todas las fechas de visita antes de guardar"
      );
    } else {
      data.every((clm) => {
        const inputDia = clm.dia === "" ? 0 : Number(clm.dia);
        //se valida si el dia agregado esta dentro de los dias establecidos en el manifiesto
        console.log("dia capturado:", inputDia);
        if (inputDia > values.dias) {
          return false;
        } else {
          //aqui se crea el manifiesto por cada registro
          return true;
        }
      });
    }
  }

  async function getDataFunction(clave, fecha, isUpdate = false) {
    setLoadingData(true);
    setData(await loadClientesManif(clave, fecha, isUpdate));
    setLoadingData(false);
  }

  async function BuscarRuta(clave) {
    console.log("consultando datos de la ruta:", clave);
    setLoadingData(true);
    let cRuta = [
      {
        clave: clave,
        nombre: "",
        duracion: "",
        oper: "",
        operador: "",
        aux: "",
        auxiliar: "",
        noEco: "",
        vehiculo: "",
        id: ""
      }
    ];
    if (clave && clave !== "") {
      const ruta = clave ? await BuscarRutaClave(clave) : null;
      console.log(ruta);

      if (ruta !== null && ruta.length > 0) {
        cRuta = ruta.map((r) => {
          const auxRuta = {
            clave: r.clave,
            nombre: r.nombre,
            duracion: r.duracion,
            oper: r.operador,
            //operador: buscarAgente("OPE", r.operador),
            aux: r.auxiliar,
            //auxiliar: buscarAgente("AUX", r.auxiliar),
            noEco: r.vehiculo,
            vehiculo: buscarVehiculo(r.vehiculo),
            id: r.id
          };
          return auxRuta;
        });
      }
    }
    console.log(cRuta);
    setRutaData(...cRuta);
    setLoadingData(false);
    return cRuta[0];
  }

  // Función para buscar un vehículo según su clave económica
  const buscarVehiculo = (claveEco) => {
    if (!claveEco) return "";

    const veh = vehiculos
      ? vehiculos.find((op) => op.CLAVE.trim() === claveEco.trim())
      : undefined;

    if (veh) return veh.MARCA + " " + veh.MODELO;
    else return "";
  };

  // Función para agregar una salida al manifiesto
  async function addSalida(noSalida, noManif) {
    setLoadingData(true);
    let rowsNew = [];

    if (data) {
      rowsNew = data.map((res, index) => {
        return {
          id: res.id,
          clave: res.clave,
          ciudad: res.ciudad,
          familia: res.familia,
          nombre: res.nombre,
          ruta: res.ruta,
          orden: res.orden,
          servicio: res.servicio,
          dia: res.dia,
          fvisita: res.fvisita,
          salida: dataRowSelected.index === index ? noSalida : res.salida,
          manifiesto:
            dataRowSelected.index === index ? noManif : res.manifiesto,
          planta: res.planta,
          rutaplanta: res.rutaplanta
        };
      });
    }
    setData(rowsNew);
    setLoadingData(false);
    return rowsNew;
  }

  // Función para mostrar el modal de salidas
  function consultarSalidas() {
    const myModal = new Modal(document.getElementById(idModalSalidas));
    myModal.show();
  }

  // Función para mostrar el modal de agregar insumo
  function actionElaborar() {
    const myModal = new Modal(document.getElementById(idModalAgregarInsumo));
    myModal.show();
  }

  // Definición de los botones del grupo de acciones
  const buttonsGroup = {
    buttonPrimary: {
      action: actionElaborar,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        dataRowSelected?.salida ||
        !dataRowSelected?.fvisita ||
        (!dataRowSelected?.manifiesto &&
          !(
            dataRowSelected?.servicio === "CB" ||
            dataRowSelected?.servicio === "CI" ||
            dataRowSelected?.servicio === "TI")
        ),
      type: "button",
      label: "Elaborar"
    },
    buttonSecondary: {
      action: consultarSalidas,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        dataRowSelected?.salida === "",
      type: "button",
      label: "Consultar"
    },
    buttonTertiary: (ruta, fechaInicio) => ({
      action: async () => {
        console.log(ruta, fechaInicio)
        if (!ruta || !fechaInicio.current) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar el reporte.');
          return;
        }
        await generarReporteConClaveYFecha(ruta, fechaInicio.current);
      },
      disabled: loadingData || data.length <= 0 || !ruta || !fechaInicio.current,
      type: "button",
      label: "Generar Reporte"
    })
  };

  // Hook para inicializar y listar agentes y vehículos al cargar el componente
  useEffect(() => {
    initTE({ Modal });

    async function listarVehiculos() {
      setLoadingData(true);
      const listVehic = await ObtenerVehiculos();
      setVehiculos(listVehic);
      setLoadingData(false);
    }

    // async function listarAgentes() {
    //   setLoadingData(true);
    //   const listaAgent = await ObtenerAgentes();
    //   setAgentes(listaAgent);
    //   setLoadingData(false);
    // }

    // Consultar ambos catálogos (agentes y vehículos) al mismo tiempo
    const consultarTodo = async () => {
      const inicio = performance.now();
      //await Promise.all([listarAgentes(), listarVehiculos()]);
      await listarVehiculos();
      const fin = performance.now();
      console.log(fin - inicio);
    };

    consultarTodo();
  }, []);

  // Hook para actualizar la fila seleccionada cuando cambie la selección de filas
  useEffect(() => {
    if (data && data.length >= 0) {
      const indexKey = Object.keys(rowSelection);
      if (indexKey >= 0) {
        setDataRowSelected({
          ...data[Number(indexKey)],
          index: Number(indexKey)
        });
      }
    }
  }, [rowSelection, data]);

  // useEffect(() => {
  //   if((dataRowSelected?.salida && dataRowSelected?.manifiesto) && dataRowSelected?.salida !== ""){
  //     GetDataSalidas();
  //   }else dataSalidas.current= [];

  // }, [dataRowSelected, dataRowSelected?.salida, dataRowSelected?.manifiesto, GetDataSalidas]);

  // Retorno del hook con todas las funciones y estados necesarios
  return {
    actionElaborar,
    getDataFunction,
    BuscarRuta,
    loadingData,
    data,
    dataSalidas,
    rowSelection,
    setRowSelection,
    dataRowSelected,
    setData,
    idModalAgregarInsumo,
    idModalSalidas,
    addSalida,
    onSubmitActManifestos,
    buttonsGroup
  };
};

export default useManifiestos;