import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logoPlantilla from "../../imagenes/logo_plantilla.png"; // Ruta válida del logo

const styles = StyleSheet.create({
    page: {
        padding: 10,
        fontSize: 8,
        fontFamily: "Helvetica",
    },
    header: {
        flexDirection: "column",
        marginBottom: 10,
    },
    logo: {
        width: 50,
        height: 50,
        marginRight: 10,
    },
    headerTop: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headerLeft: {
        flexDirection: "row",
        alignItems: "center",
    },
    headerDetails: {
        marginTop: 5,
        fontSize: 9,
    },
    subHeaderRight: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        fontSize: 8,
        textAlign: "right",
        marginTop: 5,
        marginRight: 10,
    },
    tableContainer: {
        marginTop: 10,
    },
    tableRow: {
        flexDirection: "row",
        minHeight: 15,
        borderBottom: "1 solid black",
    },
    tableHeader: {
        fontWeight: "bold",
        fontSize: 7,
        textAlign: "center",
        padding: 2,
    },
    tableCell: {
        fontSize: 5,
        textAlign: "left",
        padding: 2,
    },
    tableCellSmall: {
        fontSize: 5,
        textAlign: "center",
        padding: 2,
        width: "5%",
    },
    tableCellCheckbox: {
        width: "3%",
        textAlign: "center",
        padding: 2,
    },
    tableCellObservaciones: {
        fontSize: 7,
        textAlign: "left",
        padding: 2,
        width: "20%",
    },
    checkbox: {
        width: 8,
        height: 8,
        border: "1 solid black",
        margin: "auto",
    },
    boldLine: {
        borderTop: "2 solid black",
        marginVertical: 5,
    },
});

const ReporteRuta = ({ NombreRuta, datosReporte }) => {
    if (!NombreRuta || !datosReporte) {
        console.error("Error: Datos insuficientes para generar el reporte.");
        return <Text>Error: Datos insuficientes para generar el reporte.</Text>;
    }

    // Configuración de márgenes dinámicos
    const alturaHoja = 841.89; // Altura en puntos para A4
    const margenSuperior = 120; // Altura aproximada del encabezado
    const margenInferior = 50; // Altura aproximada del footer
    const alturaFila = 15; // Altura promedio de una fila
    const espacioDisponible = alturaHoja - margenSuperior - margenInferior;
    const registrosPorPagina = Math.floor(espacioDisponible / alturaFila);

    // Dividir los datos en páginas
    const paginas = [];
    for (let i = 0; i < datosReporte.length; i += registrosPorPagina) {
        paginas.push(datosReporte.slice(i, i + registrosPorPagina));
    }

    return (
        <Document>
            {paginas.map((pagina, pageIndex) => (
                <Page size="A4" style={styles.page} key={pageIndex}>
                    {/* Encabezado */}
                    <View style={styles.header}>
                        <View style={styles.headerTop}>
                            {/* Logo e información izquierda */}
                            <View style={styles.headerLeft}>
                                <Image style={styles.logo} src={logoPlantilla} />
                                <View>
                                    <Text>Sistema de Administración de Suvalsa</Text>
                                    <Text>
                                        Clientes en la {NombreRuta.nombre} - {NombreRuta.clave}
                                    </Text>
                                    <Text>
                                        Duración: {NombreRuta.duracion} días{"\n"}
                                        Operadores: {NombreRuta.oper} y {NombreRuta.aux}{"\n"}
                                        Vehículo: {NombreRuta.vehiculo1} - {NombreRuta.marca}
                                    </Text>
                                </View>
                            </View>

                            {/* Subencabezado en el lado derecho */}
                            <View style={styles.subHeaderRight}>
                                <View>
                                    <Text>ET: En Tiempo</Text>
                                    <Text>D1: Desfase 1</Text>
                                    <Text>D2: Desfase 2</Text>
                                </View>
                            </View>
                        </View>

                        {/* Encabezado de la Tabla */}
                        <View style={styles.boldLine}></View> {/* Línea superior */}
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>#</Text>
                            <Text style={[styles.tableHeader, { width: "5%" }]}>Clave</Text>
                            <Text style={[styles.tableHeader, { width: "35%" }]}>Nombre</Text>
                            <Text style={[styles.tableHeader, { width: "3%" }]}>Fam.</Text>
                            <Text style={[styles.tableHeader, { width: "10%" }]}>Ciudad</Text>
                            <Text style={[styles.tableHeader, { width: "3%" }]}>Freq. Serv.</Text>
                            <Text style={[styles.tableHeader, { width: "3%" }]}>Tipo</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>Status</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>ET</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>D1</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>D2</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>S1</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>S2</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>S3</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>S4</Text>
                            <Text style={[styles.tableHeader, { width: "2%" }]}>S5</Text>
                            <Text style={[styles.tableHeader, { width: "15%" }]}>Observación</Text>
                        </View>
                        <View style={styles.boldLine}></View> {/* Línea debajo del encabezado */}
                    </View>

                    {/* Tabla de Clientes */}
                    <View style={styles.tableContainer}>
                        {pagina.map((cliente, index) => (
                            <View style={styles.tableRow} key={index}>
                                <Text style={[styles.tableCellSmall, { width: "2%" }]}>{cliente.orden}</Text>
                                <Text style={[styles.tableCell, { width: "5%" }]}>{cliente.cliente}</Text>

                                <Text style={[styles.tableCell, { width: "35%" }]}>{cliente.nombre}</Text>
                                <Text style={[styles.tableCellSmall, { width: "3%" }]}>{cliente.fam}</Text>
                                <Text style={[styles.tableCell, { width: "10%" }]}>{cliente.ciudad}</Text>
                                <Text style={[styles.tableCellSmall, { width: "3%" }]}>{cliente.frec}</Text>
                                <Text style={[styles.tableCellSmall, { width: "3%" }]}>{cliente.serv}</Text>
                                <Text style={[styles.tableCellSmall, { width: "2%" }]}>{cliente.status}</Text>
                                {/* Checkboxes */}
                                {Array(7)
                                    .fill()
                                    .map((_, idx) => (
                                        <View key={idx} style={styles.tableCellCheckbox}>
                                            <View style={styles.checkbox}></View>
                                        </View>
                                    ))}
                                <Text style={styles.tableCellObservaciones}>{cliente.observacion ?? ""}</Text>
                            </View>
                        ))}
                        <View style={styles.boldLine}></View> {/* Línea inferior */}
                    </View>
                </Page>
            ))}
        </Document>
    );
};

export default ReporteRuta;