import React from 'react'
import CardNavigate from '../../../componentes/Cards/CardNavigate.jsx';
import ConsultaGastos from "./ConsultaGastos.Form.jsx"

const GastosViajesRecoleccion = () => {
  return (
    <CardNavigate title="Gastos de la ruta">
      <ConsultaGastos />
    </CardNavigate>
  )
}

export default GastosViajesRecoleccion
