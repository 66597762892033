import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import CustomModal from "../../../componentes/Modals/CustomModal";
import useCatalogo from "../../../Hooks/UseCatalogos";
import useCatVehiculos from "./UseVehiculos";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoVehiculos = () => {
  const {
    tituloCatVehiculos,
    columnsData,
    opcionesTabla,
    GetVehiculos,
    idModalDelete,
    pathAltaVehic,
    pathCatVehic,
    actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  } = useCatVehiculos();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: vehiculos,
    setData: setVehiculos,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetVehiculos,
    idModalDelete,
    pathNavTo: pathAltaVehic,
    pathNavBack: pathCatVehic,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <CardNavigate title={tituloCatVehiculos}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={vehiculos}
            setData={setVehiculos}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            id={idModalDelete}
            title={"Eliminar vehículo"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar el Vehículo con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoVehiculos;
