import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, initTE } from "tw-elements";
import { useExecutePromise } from "./UsePromiseAction";
import { sleep } from "../utils/CommonUtils";
import { useCallback } from "react";

const useCatalogo = ({
  optionsTable,
  getData,
  dataRowSelection,
  setDataRowSelection,
  idModalDelete,
  pathNavTo,
  pathNavBack,
  deleteData,
  notification,
  autoLoad = true
}) => {
  initTE({ Modal });

  const [loading, setLoading] = useState(false);
  const[data, setData] = useState([])

  const [rowSelection, setRowSelection] = useState({});

  const navegar = useNavigate();

  const { runAction, loadingAction } = useExecutePromise();

  const successTextDef = `Elemento ${dataRowSelection[0]?.clave} eliminado correctamente.`;

  const title = notification?.title  ?? "Eliminar";
  const pendingText = notification?.pendingText ?? 'Eliminando, por favor espere.';
  const successText = notification?.successText ?? successTextDef;
  const errorText= notification?.errorText ?? "Ocurrió un error inesperado. Por favor reinténtalo más tarde."

  const GetRowsData = useCallback( async()=> {
    let rowsNew = [];
    setLoading(true);
    console.log("obteniendo Datos de ws");
    rowsNew = await getData();
    console.log("ROWS DATA: ", rowsNew);
    setData(rowsNew)
    setLoading(false);
    return rowsNew;
  }, [getData])

  function actionAlta() {
    console.log("action click alta");
    navegar(pathNavTo, {
      state: { urlBack: pathNavBack }
    });
  }

  function actionReporte() {
    console.log("action click Reporte");
  }

  function actionEliminar() {
    console.log("action click Eliminar");
    const myModal = new Modal(document.getElementById(idModalDelete));
    myModal.show();
  }

  async function confirmarEliminacion() {
    console.log(dataRowSelection[0].id);
    await runAction({
      promiseAction: async () => {
        await sleep(1000);
        await deleteData(dataRowSelection[0].id);
        setRowSelection({});
        //aqui se debe de validar si se elimina o no,
        await GetRowsData();
        console.log("dato eliminado");
      },
      title,
      pendingText,
      successText,
      errorText
    });
  }

  function getDataSelected() {
    return dataRowSelection[0];
  }

  const buttonsGroup = {
    buttonPrimary: {
      action: actionAlta,
      disabled: loading,
      type: "submit",
      label: "Alta"
    },
    buttonSecondary: {
      action: actionReporte,
      disabled: loading,
      type: "button",
      label: "Reporte"
    },
    buttonTertiary: {
      action: actionEliminar,
      disabled: loading || !dataRowSelection.length,
      type: "button",
      label: "Eliminar"
    }
  };

  useEffect( () => {
    if(autoLoad)
      GetRowsData()
  }, [autoLoad, GetRowsData])

  return {
    loading,
    loadingAction,
    optionsTable,
    data, 
    setData,
    GetRowsData,
    rowSelection, 
    setRowSelection,
    getDataSelected, 
    setDataRowSelection,
    confirmarEliminacion,
    buttonsGroup
  };
};

export default useCatalogo;
