import React from "react";
// Initialization for ES Users
import {
    Modal, Ripple ,
    initTE,
  } from "tw-elements";
import { ButtonGroup } from "../Button/GenericButton";

const CustomModal = (props) => {
    initTE({ Modal, Ripple });
    const {buttonsGroup, withForm} = props
  return (
    <>
      {/* Modal */}
      <div
        data-te-modal-init
        className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id={props.id ?? "staticBackdrop"}
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
          <div
            data-te-modal-dialog-ref
            className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]"
          >
            <div className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
              <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 bg-primary-gradient border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                {/*Modal title*/}
                <h5
                  className="header-title"
                  id="staticBackdropLabel"
                >
                  {props.title}
                </h5>
                {/*Close button*/}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-8 w-8 text-slate-50 hover:text-suva-azul-fuerte"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              {/*Modal body*/}
                <div className="relative p-4">
                  {props.children}
                </div>

              {/*Modal footer*/}
              {
                ! withForm &&
                  <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                  {
                    props.buttonsGroup 
                    ?
                    <>
                      <ButtonGroup {...buttonsGroup}
                      />
                    </>
                    : <>
                      <button
                    type="button"
                    className="btn-secondary"
                    data-te-modal-dismiss
                    data-te-target={`#${props.id ?? "staticBackdrop"}`}
                  >
                    Cancelar
                  </button>
                  <button onClick={props.actionAccept}
                    type= "button"
                    className="btn-primary"
                    data-te-target={`#${props.id ?? "staticBackdrop"}`}
                    data-te-modal-dismiss
                  >
                    Aceptar
                  </button>
                    </>
                  }
                  </div>
              }
              
            </div>
          </div>

      </div>
    </>
  );
};

export default CustomModal;
