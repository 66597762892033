import React from 'react'

const ReportesRecolecciones = () => {
  return (
    <div>
      ReportesRecolecciones
    </div>
  )
}

export default ReportesRecolecciones
