import { useCallback, useMemo, useState } from "react";
import {
  ObtenerRutasPorPlanta,
  EliminarRutasPorPlanta
} from "../../../Apis/FetchCatalogos";
import { columnSize } from "../../../Constantes";

const useCatRutasPlantas = () => {
  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatRutasPlanta = "Catálogo de Rutas por Planta";
  const idModalDelete = "staticBackdrop";
  const pathAltaRutasPlanta = "/suvalsa/catalogos/rutas-para-plantas/alta";
  const pathCatRutasPlanta = "/suvalsa/catalogos/rutas-para-plantas";

  const title = "Eliminar Ruta para Planta";
  const pendingText = "Eliminando ruta, por favor espere.";
  const successText = `Ruta ${dataRowSelection[0]?.clave} eliminada correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };
  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ruta",
        header: "Ruta",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      }
    ],
    []
  );

  const opcionesTabla = { ofText: "Rutas por Planta de" };

  const GetRutasPlanta= useCallback(async () =>{
    let rowsNew = [];
    console.log("obteniendo Datos de ws Rutas por Planta");
    const rutasPlanta = await ObtenerRutasPorPlanta();
    console.log("lista de Rutas por Planta: ", rutasPlanta);

    if (rutasPlanta) {
      rowsNew = rutasPlanta.map((res) => ({
        clave: res.planta,
        id: res.idCatrxp,
        nombre: res.nombreCliente,
        ruta: res.rutaPlanta
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarRutasPorPlanta(id);
  }

  return {
    tituloCatRutasPlanta,
    idModalDelete,
    pathAltaRutasPlanta,
    pathCatRutasPlanta,
    columnsData,
    opcionesTabla,
    GetRutasPlanta,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  };
};

export default useCatRutasPlantas;
