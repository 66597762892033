import React from "react";
import { flexRender } from "@tanstack/react-table";
import "./table.css";
import { useEffect, useState } from "react";
import useTable from "./useTable";
import { useTable as useVirualTable } from "../../componentes/Tables/VirtualTable/useTable";
import VirtualTableTable from "./VirtualTable/VirtualTable.table";

const CustomTable = (props) => {
  const { table, setSorting, actionRefreshData, optionsTable, isTableSelected = false, columnsHidden = [] } = { ...props };

  const customStyles = optionsTable?.styles ?? {
  };

  const customClass = optionsTable?.classNames ?? "max-h-[75vh] md:max-h-[85vh] overflow-auto";
  //"bg-white dark:bg-neutral-800 relative perfect-scrollbar ps--active-x ps--active-y group/ps overflow-hidden [overflow-anchor:none] touch-none"

  const getCommonPinningStyles = ({ column, isColumn, isPair }) => {
    //   const isPinned = column.getIsPinned()
    //   const isLastLeftPinnedColumn =
    //     isPinned === 'left' && column.getIsLastColumn('left')
    //   const isFirstRightPinnedColumn =
    //     isPinned === 'right' && column.getIsFirstColumn('right')
    const isSticky = column.id === "select" || column.id === "clave" || column.id === "actions";
    const isSelect = column.id === "select";
    const minWidth = isSelect
      ? "35px"
      : column.getSize().toString().concat("px");

    //para validar si se debe ocultar o no la columna, esto depende del arreglo recibido
    const isHidden = columnsHidden.length > 0 ? columnsHidden.some(c => c === column.id) : false;

    return {
      // boxShadow: isLastLeftPinnedColumn
      //   ? '-4px 0 4px -4px gray inset'
      //   : isFirstRightPinnedColumn
      //     ? '4px 0 4px -4px gray inset'
      //     : undefined,
      left: isSelect
        ? `${column.getStart("left")}px`
        : isSticky && isTableSelected
          ? "35px"
          : isSticky ? '0px' : undefined,
      right: isSticky && column.id === "actions" ? '0px' : undefined,
      maxWidth: column.id === "actions" ? `${minWidth}` : undefined,
      // right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
      //opacity: isSticky ? 0.95 : 1,
      position: isSticky ? "sticky" : "relative", //isPinned ? 'sticky' : 'relative',
      width: isSelect ? minWidth : column.getSize(),
      minWidth: `${minWidth}`,
      zIndex: isSticky ? 1 : 0,
      //background: isSticky && !isColumn ? "" : isSticky && isColumn ? '#D1E3FF': '',
      backgroundColor: column.id !== "actions" && isColumn ? "#D1E3FF"
        : !isColumn && isPair ? "#F4F6F8" : isSticky && !isColumn && !isPair ? "white" : 'transparent',
      textAlign: isSelect ? "center" : "left",
      // paddingLeft: isSelect ? "1rem" : column.id === "actions" ? "0px" : " 0.75rem",
      display: isHidden ? 'none' : ''
    }
  };

  return (
    <>
      <div className={customClass} style={{ ...customStyles }}>
        <table>
          <thead>
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        ...getCommonPinningStyles({
                          column: header.column,
                          isColumn: true
                        })
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={
                            header.column.getCanSort()
                              ? "cursor-pointer select-none flex flex-row group"
                              : "flex flex-row group"
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Orden ascendente"
                                : header.column.getNextSortingOrder() === "desc"
                                  ? "Orden descendente"
                                  : "Clear sort"
                              : undefined
                          }
                        >
                          {/* add filter component
                        {header.column.getCanFilter() ? (
                                <div>
                                <Filter column={header.column} table={table} />
                                </div>
                            ) : null} */}
                          {/** Add sorting component */}
                          {{
                            asc: (
                              <div
                                className="inline-flex"
                                onClick={() =>
                                  setSorting([
                                    {
                                      id: header.column.id,
                                      desc: "true"
                                    }
                                  ])
                                }
                              >
                                <span
                                  className="w-[15px] h-[10px] origin-bottom font-black mr-1 text-neutral-500 group-hover:opacity-100 transition hover:ease-in-out transform ease-linear duration-300 motion-reduce:transition-none dark:text-neutral-400 opacity-100"
                                  data-te-sort="orden"
                                  data-te-datatable-sort-icon-ref=""
                                  style={{ transform: "rotate(180deg)" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    strokeWidth="3"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                            ),
                            desc: (
                              <div
                                className="inline-flex"
                                onClick={() =>
                                  setSorting([
                                    {
                                      id: header.column.id,
                                      asc: "true"
                                    }
                                  ])
                                }
                              >
                                <span
                                  className="w-[15px] h-[10px] origin-bottom font-black mr-1 text-neutral-500 group-hover:opacity-100 transition hover:ease-in-out transform ease-linear duration-300 motion-reduce:transition-none dark:text-neutral-400 opacity-100"
                                  data-te-sort="orden"
                                  data-te-datatable-sort-icon-ref=""
                                  style={{ transform: "rotate(0deg)" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    strokeWidth="3"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                            )
                          }[header.column.getIsSorted()] ?? null}

                          <span>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </span>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table?.getRowModel().rows.map((row, index) => {
              return (
                <tr key={row.id} onClick={row.getToggleSelectedHandler()}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          ...getCommonPinningStyles({
                            column: cell.column,
                            isColumn: false,
                            isPair: index % 2 === 1,
                          })
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="h-2" />
      <div className="flex md:flex-row justify-end items-center py-2 space-x-4 text-sm flex-col leading-[1.6]  border-neutral-200 dark:border-neutral-500 bg-transparent dark:bg-neutral-800">
        <div className="flex items-center space-x-4 order-3 md:order-none">
          {actionRefreshData ? (
            <button type="button"
              onClick={() => actionRefreshData()}
              style={{ fontSize: "24px" }}
            >
              <svg
                fill="#706e6efc"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                viewBox="0 0 383.748 383.748"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30 C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593 L2.081,34.641v113.365h113.91L62.772,95.042z"></path>
                    <path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042 c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888 c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z"></path>
                  </g>
                </g>
              </svg>
            </button>
          ) : (
            ""
          )}
          <span className="font-light">Registros por página</span>
          <div className="w-[70px] ">
            <select
              style={{ WebkitAppearance: "none" }}
              value={table?.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[1000, 1250, 1500, 3000, 5000].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="font-normal order-2 mb-3 md:order-none md:mb-0 ">
          <p>
            {table?.getState().pagination.pageIndex *
              table?.getState().pagination.pageSize +
              1}
            {" - "}
            {table?.getState().pagination.pageIndex *
              table?.getState().pagination.pageSize +
              table?.getRowModel().rows.length}{" "}
            {optionsTable && optionsTable?.ofText
              ? optionsTable.ofText
              : " Registros de "}{" "}
            {table?.getRowCount()}
          </p>
        </div>

        <div className="order-1 my-3 md:order-none md:my-0 md:pr-1
          w-full md:w-max
          justify-around
          flex md:block">
          <button type="button"
            onClick={() => table.setPageIndex(0)}
            disabled={!table?.getCanPreviousPage()}
            className="button-pagination"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.75 20.5L1.25 12l7.5-7.5"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              ></path>
            </svg>
          </button>
          <button type="button"
            onClick={() => table.previousPage()}
            disabled={!table?.getCanPreviousPage()}
            className="button-pagination"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              ></path>
            </svg>
          </button>
          <button type="button"
            onClick={() => table.nextPage()}
            disabled={!table?.getCanNextPage()}
            className="button-pagination"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              ></path>
            </svg>
          </button>
          <button type="button"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table?.getCanNextPage()}
            className="button-pagination"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.25 4.5l7.5 7.5-7.5 7.5"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              ></path>
            </svg>
          </button>
        </div>

        {/* <span className="flex items-center gap-1">
        <div>Página</div>
        <strong>
          {table?.getState().pagination.pageIndex + 1} de{" "}
          {table?.getPageCount()}
        </strong>
      </span>
      <span className="flex items-center gap-1">
        | Ir a la página:
        <input
          type="number"
          defaultValue={table?.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
          className="border p-1 rounded w-16"
        />
      </span> */}

        {/* <div>{table?.getRowModel().rows.length} Registros</div> */}
      </div>
      {/* <div>
      <button
        className="border rounded p-2 mb-2"
        onClick={() =>
          console.info(
            'table.getSelectedRowModel().flatRows',
            table.getSelectedRowModel().flatRows
          )
        }
      >
        Log table.getSelectedRowModel().flatRows
      </button>
    </div>
    <div>
      <label>Row Selection State:</label>
      <pre>{JSON.stringify(table.getState().rowSelection, null, 2)}</pre>
    </div> */}
    </>
  );
};

export const CustomGenericTable = ({
  columnsData,
  data,
  optionsTable,
  setData,
  getData,
  newDataRows = [],
  defaultColumn,
  columnCustomFilters,
  rowSelection,
  setRowSelection,
  conditionalSelection,
  isTableSelected = false,
  isMultiRowSelection = false,
  //callBackAction,
  sorting, setSorting,
  setDataRowSelection,
  columnsHidden = []
}) => {

  const [globalFilter] = useState("");
  const [columnFilters] = useState("");

  //const [rowSelected, setRowSelected] = useState("")
  const [sortingDefault, setSortingDefault] = useState([
    {
      id: "clave",
      desc: "true"
    },
  ]);

  //para el componente de la tabla y acceder a los valores de l a fila seleccionada
  //const [rowSelection, setRowSelection] = useState({});

  const { table, addData } = useTable({
    isSelected: isTableSelected,
    isMultiRowSelection: isMultiRowSelection,
    rowSelection,
    setRowSelection,
    setData,
    data,
    columns: columnsData,
    defaultColumn,
    globalFilter,
    conditionalSelection,
    columnFilters: columnCustomFilters ?? columnFilters,
    sorting: sorting ?? sortingDefault,
    setSorting: setSorting ?? setSortingDefault
  });

  // function CallToAction() {
  //   console.log("action click Eliminar proveedores");
  //   console.log(table.getSelectedRowModel().flatRows);
  //   setRowSelected(table.getSelectedRowModel().flatRows[0].original);
  //   if (callBackAction) callBackAction(table.getSelectedRowModel().flatRows[0].original)
  // }

  // const crearFiltro = () => {
  //   const headerCl = table.getHeaderGroups().at(0).headers[1];
  //   console.log(headerCl);
  //   return (
  //     <div>
  //       <Filter column={headerCl.column} table={table} />
  //     </div>
  //   );
  // };

  // const buttonTertiary = {
  //   action: CallToAction,
  //   disabled: table?.getSelectedRowModel().flatRows.length <= 0,
  //   type: "button",
  //   label: "Eliminar"
  // };
  useEffect(() => {
    if (setDataRowSelection) {
      setDataRowSelection(
        table.getSelectedRowModel().flatRows.map(rows => {
          return {
            ...rows.original,
            customUUID: window.crypto.randomUUID()
          }
        })
      )
    }
  }, [rowSelection, table, setDataRowSelection])

  useEffect(() => {
    if (newDataRows.length > 0)
      addData(newDataRows)
  }, [newDataRows])

  return (
    <>
      {/* <div>
        <input
          value={globalFilter ?? ''}
          onChange={e => setGlobalFilter(e.target.value)}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search all columns..."
        />
      </div> */}
      <CustomTable
        table={table}
        setSorting={setSorting ?? setSortingDefault}
        actionRefreshData={() => getData()}
        optionsTable={optionsTable}
        columnsHidden={columnsHidden}
      // optionsTable={
      //   {
      //     styles : {
      //       width: '100%', height: '600px'
      //     },
      //     classNames : 'overflow-auto'
      //   }
      // }
      />
    </>
  );
};      // Asumo que es tu custom hook

export const CustomGenericVirtualTable = ({
  columnsData,
  data,
  optionsTable,
  setData,
  getData,
  newDataRows = [],
  defaultColumn = {},
  columnFilters,         // Puede venir o no desde el padre
  setColumnFilters,      // Puede venir o no desde el padre
  rowSelection,
  setRowSelection,
  conditionalSelection,
  isTableSelected = false,
  isMultiRowSelection = false,
  sorting,              // Puede venir o no desde el padre
  setSorting,           // Puede venir o no desde el padre
  setDataRowSelection,
  withPagination
}) => {
  /**
   * ESTADOS internos de fallback
   * -------------------------------------
   * Si el padre NO pasa columnFilters/sorting, usaremos estos
   * Si el padre SÍ los pasa, usaremos lo que venga del padre
   */
  const [internalColumnFilters, setInternalColumnFilters] = useState([]);
  const [internalSorting, setInternalSorting] = useState([]);

  // useVirualTable
  const { table, addData } = useVirualTable({
    isSelected: isTableSelected,
    isMultiRowSelection: isMultiRowSelection,
    setData,
    data,
    rowSelection,
    setRowSelection,
    columns: columnsData,
    defaultColumn,
    conditionalSelection,

    // 1) Filtros:
    // Si el padre nos pasa columnFilters, lo usamos,
    // de lo contrario usamos nuestro estado interno:
    columnFilters: columnFilters ?? internalColumnFilters,

    // 2) Seteo de filtros:
    // Si el padre nos pasa setColumnFilters, lo usamos,
    // de lo contrario usamos nuestro setState local:
    setColumnFilters: setColumnFilters ?? setInternalColumnFilters,

    // 3) Sorting:
    sorting: sorting ?? internalSorting,

    // 4) Seteo de sorting:
    setSorting: setSorting ?? setInternalSorting,
  });

  // Resetea la selección de filas cuando cambie "data"
  // useEffect(() => {
  //   table?.resetRowSelection();
  // }, [data, table]);

  // Actualizar la data de selección en el padre (si se define setDataRowSelection)
  useEffect(() => {
    if (setDataRowSelection) {
      setDataRowSelection(
        table.getSelectedRowModel().flatRows.map((row) => {
          return {
            ...row.original,
            customUUID: window.crypto.randomUUID(),
          };
        })
      );
    }
  }, [rowSelection, table, setDataRowSelection]);

  // Si llegan nuevas filas desde fuera, las agregamos
  useEffect(() => {
    if (newDataRows.length > 0) {
      addData(newDataRows);
    }
  }, [newDataRows, addData]);

  return (
    <VirtualTableTable
      table={table}
      actionRefreshData={getData}
      optionsTable={optionsTable}
      isTableSelected={isTableSelected}
      withPagination={withPagination}
    />
  );
};


export default CustomTable;
