// filterFns.js
export const includesString = (row, id, filterValue) => {
  const rowValue = row.getValue(id);

  if (filterValue === undefined || filterValue === null || filterValue === "") {
    return true;
  }
  return rowValue?.toString().toLowerCase().includes(filterValue.toLowerCase());
};

export const betweenNumbers = (row, id, filterValue) => {
  const [min, max] = filterValue;
  const rowValue = row.getValue(id);

  if (min !== "" && max !== "") {
    return rowValue >= Number(min) && rowValue <= Number(max);
  } else if (min !== "") {
    return rowValue >= Number(min);
  } else if (max !== "") {
    return rowValue <= Number(max);
  }
  return true;
};
export const equals = (row, id, filterValue) => {
  const rowValue = row.getValue(id);

  if (filterValue === undefined || filterValue === null || filterValue === "") {
    return true;
  }

  // Convertir a cadenas y a minúsculas para una comparación insensible a mayúsculas
  return rowValue?.toString().toLowerCase() === filterValue.toString().toLowerCase();
};