import { useCallback, useMemo, useState } from "react";
import {
  ObtenerProveedores,
  EliminarProveedor
} from "../../../Apis/FetchCatalogos";

const useCatProveedores = () => {
  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatProveedores = "Catálogo de Proveedores";
  const idModalDelete = "staticBackdrop";
  const pathAltaProv = "/suvalsa/portal-proveedores/altas-bajas";
  const pathCatProv = "/suvalsa/catalogos/proveedores";

  const title = "Eliminar Proveedor";
  const pendingText = "Eliminando proveedor, por favor espere.";
  const successText = `Proveedor ${dataRowSelection[0]?.clave} eliminado correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";
  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };

  const propsModal = {
    id: idModalDelete,
    title: "Eliminar proveedor",
    setMessage: (selectedRowData) =>
      "¿Deseas eliminar el Proveedor con clave " + selectedRowData?.clave + "?"
  };

  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: true
      },
      {
        accessorKey: "empresa",
        header: "Empresa",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "calle",
        header: "Calle",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "colonia",
        header: "Colonia",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "cp",
        header: "CP",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "contacto",
        header: "Contacto",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "tel",
        header: "Telefono",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const opcionesTabla = { ofText: "Proveedores de" };

  const GetProveedores = useCallback(async () =>{
    let rowsNew = [];
    console.log("obteniendo Datos de ws Proveedores");
    const proveedores = await ObtenerProveedores();
    console.log("lista de provedores: ", proveedores);

    if (proveedores) {
      rowsNew = proveedores.map((proveedor) => ({
        clave: proveedor.clave,
        id: proveedor.id,
        empresa: proveedor.empresa,
        calle: proveedor.trial_calle_3,
        colonia: proveedor.colonia,
        ciudad: proveedor.ciudad,
        cp: proveedor.cp,
        contacto: proveedor.contacto,
        tel: proveedor.telefono
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarProveedor(id);
  }

  return {
    tituloCatProveedores,
    idModalDelete,
    pathAltaProv,
    pathCatProv,
    columnsData,
    opcionesTabla,
    GetProveedores,
    dataRowSelection,
    setDataRowSelection,
    propsModal,
    actionDelete,
    notification
  };
};

export default useCatProveedores;
