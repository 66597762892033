import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { IndeterminateCheckbox } from "./utils";

export const useTable = ({
  isSelected = false,
  rowSelection,
  setRowSelection,
  setData,
  data,
  columns,
  defaultColumn,
  globalFilter,
  conditionalSelection,
  isMultiRowSelection,
  columnFilters,
  sorting,
  setSorting
}) => {
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [columnVisibility, setColumnVisibility] = useState({
    id: false //hide this column by default
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 1000, //default page size
  });

  const [rowDefaultSelection, setDefaultRowSelection] = useState({});

  const customColumns = useMemo(() => {
    if (isSelected)
      return [
        {
          id: "select",
          //para multiselect
          header: ({ table }) =>
            isMultiRowSelection ? (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler()
                }}
              />
            ) : (
              <></>
            ),
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler()
                }}
              />
            </div>
          )
        },
        ...columns
      ];
    else return [...columns];
  }, [columns, isMultiRowSelection, isSelected]);

  const table = useReactTable({
    data,
    columns: customColumns,
    defaultColumn: defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      rowSelection : rowSelection ?? rowDefaultSelection,
      columnVisibility,
      globalFilter,
      columnFilters,
      sorting,
      pagination
    },
    onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
    enableMultiRowSelection: isMultiRowSelection ?? false,
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: (row) => conditionalSelection ? conditionalSelection(row) : true, // or enable row selection conditionally per row
    //enableMultiSort: true,
    //isMultiSortEvent: (e) => true,
    onRowSelectionChange: setRowSelection ?? setDefaultRowSelection,
    autoResetPageIndex,
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    enableSortingRemoval: false, // disable the ability to remove sorting on columns (always none -> asc -> desc -> asc)
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    // sortingFns: {
    //   sortStatusFn, //or provide our custom sorting function globally for all columns to be able to use
    // },
    //no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value, rowsData =undefined) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        if(rowsData){
          setData(rowsData)
        }else
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...(old[rowIndex] ?? []),
                [columnId]: value
              };
            }
            return row;
          })
        );
      }
    },
    debugTable: true
  });

  const getData= useCallback(()=>{
    return table.getRowModel().rows
  },[table])

  function addData(newData){
    setData( prevState => [
      ...prevState,
      ...newData
      ]
    );
  }
  return {
    table,
    getData,
    addData
  };
};

function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip];
}

export default useTable;
