import { useNavigate } from "react-router-dom";
import { Input, initTE, Select } from "tw-elements";
import { useExecutePromise } from "../Hooks/UsePromiseAction";
//import { sleep } from "../utils/CommonUtils";

//const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const useAlta = ({ addData, backUrl, actiosOK, notification }) => {
  const title = notification.title ?? "Alta";
  const pendingText = notification.pendingText ?? 'Enviando Datos, por favor espere.';
  const successText = notification.successText;
  const errorText = notification.errorText ?? "Ocurrió un error inesperado. Por favor reinténtalo más tarde."

  const navegar = useNavigate();

  const { runAction, loadingAction } = useExecutePromise();

  async function actionRegresar() {
    console.log("aqui regresa al catalogo: ", backUrl);
    if (backUrl)
      navegar(backUrl);
  }

  function actionImprimir() {
    console.log("clic imprimir contrato: ");
  }

  async function onSubmitAlta(values, { resetForm }) {
    console.log("onsubmit....");
    await runAction(
      {
        promiseAction: async () => {
          //await sleep(5000);
          await addData(values);
          resetForm();
          if (actiosOK) await actiosOK();
        },
        title, pendingText, successText, errorText,
        //onCloseAction: actionRegresar
      }
    )
  }

  const buttonsGroup = {
    buttonPrimary: {
      disabled: loadingAction,
      type: "submit",
      label: "Grabar"
    },
    buttonSecondary: {
      action: actionImprimir,
      disabled: loadingAction,
      type: "button",
      label: "Imprimir Contrato"
    },
    buttonTertiary: {
      action: actionRegresar,
      disabled: loadingAction,
      type: "button",
      label: `${backUrl === undefined ? "Ver Catálogo" : "Cancelar"}`
    }
  };

  initTE({ Input, Select });

  return {
    loadingAction,
    onSubmitAlta,
    buttonsGroup
  };
}

export default useAlta;