// ConfigFetch.js

const USERNAME = 'admin';
const PASSWORD = 'admin123';

const basicAuth = 'Basic ' + btoa(`${USERNAME}:${PASSWORD}`);

// Encabezados por defecto con autorización básica
const defaultHeaders = {
  "Content-Type": "application/json",
  "Authorization": basicAuth,
};


/**
 * Función genérica para realizar solicitudes GET.
 */
async function FetchApi(url) {
  let data = null;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: defaultHeaders
    });

    if (response.status !== 200) {
      data = [];
    } else {
      data = await response.json();
    }
  } catch (error) {
    console.error("Error ejecutando método GET:", error);
    return { data: null };
  }
  return { data };
}

/**
 * Función para realizar solicitudes GET con parámetros.
 */
export async function FetchApiWithParams(url, params) {
  let data = null;
  try {
    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${url}?${queryString}`;

    const response = await fetch(fullUrl, {
      method: "GET",
      headers: defaultHeaders
    });

    data = await response.json();
  } catch (error) {
    console.error("Error ejecutando método GET con parámetros:", error);
    return { data: null };
  }
  return { data };
}

/**
 * Función para realizar solicitudes POST.
 * @param {string} url - URL de la solicitud.
 * @param {Object} data - Datos a enviar en el cuerpo de la solicitud.
 * @param {Object} customHeaders - Encabezados personalizados para esta solicitud.
 * @returns {Object} - Respuesta de la solicitud.
 */
export async function FetchApiPost(url, data, customHeaders = {}) {
  let resp = null;
  let statusOk = false;
  let statusCode = -1;
  try {
    const options = {
      method: "POST",
      headers: { ...defaultHeaders, ...customHeaders }, // Combina encabezados por defecto con los personalizados
      body: data
    };

    const response = await fetch(url, options);
    statusOk = response.ok;
    statusCode = response.status;

    let responseBody = {};
    try {
      responseBody = await response.json();
    } catch (e) {
      // Si la respuesta no es JSON, deja responseBody como objeto vacío
      console.warn("Respuesta no es JSON:", e);
    }

    resp = {
      headerResponse: {
        statusOk,
        statusCode
      },
      bodyResponse: { ...responseBody }
    };
  } catch (error) {
    console.log("Error ejecutando método POST:", error);
    resp = {
      headerResponse: {
        statusOk: false,
        statusCode: -1
      },
      bodyResponse: {
        error: error.message
      }
    };
  }
  return resp;
}

/**
 * Función para realizar solicitudes DELETE.
 */
export async function FetchApiDelete(url) {
  let resp = null;
  let statusOk = false;
  let statusCode = -1;
  try {
    const options = {
      method: "DELETE",
      headers: defaultHeaders
    };

    const response = await fetch(url, options);
    statusOk = response.ok;
    statusCode = response.status;

    let responseBody = {};
    
    try {
      responseBody = await response.json();
    } catch (error) {
      console.error("Error ejecutando método PUT:", error);
    }
    
    resp = {
      headerResponse: {
        statusOk,
        statusCode
      },
      bodyResponse: { ...responseBody }
    };
  } catch (error) {
    console.error("Error ejecutando método DELETE:", error);
    resp = {
      headerResponse: {
        statusOk: false,
        statusCode: -1
      },
      bodyResponse: {
        error: error.message
      }
    };
  }

  return resp ;
}

/**
 * Función para realizar solicitudes PUT.
 */
export async function FetchApiPut(url, data, customHeaders = {}) {
  let resp = null;
  let statusOk = false;
  let statusCode = -1;

  try {
    const options = {
      method: "PUT",
      headers: { ...defaultHeaders, ...customHeaders }, // Combina encabezados por defecto con los personalizados
      body: data
    };

    const response = await fetch(url, options);
    statusOk = response.ok;
    statusCode = response.status;

    let responseBody = {};

    try {
    responseBody = await response.json();
  } catch (error) {
    console.error("Error ejecutando método PUT:", error);
  }

  resp = {
    headerResponse: {
      statusOk,
      statusCode
    },
    bodyResponse: { ...responseBody }
  };
} catch (error) {
  console.log("Error ejecutando método PUT:", error);
  resp = {
    headerResponse: {
      statusOk: false,
      statusCode: -1
    },
    bodyResponse: {
      error: error.message
    }
  };
}
return resp;
}

/**
 * Función para realizar solicitudes PATCH.
 */
export async function FetchApiPatch(url, data) {
  let resp = null;
  try {
    const options = {
      method: "PATCH",
      headers: defaultHeaders,
      body: data
    };
    const response = await fetch(url, options);
    resp = await response.json();
  } catch (error) {
    console.error("Error ejecutando método PATCH:", error);
    return { resp: null };
  }
  return { resp };
}

export default FetchApi;
