import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import AltaProveedorForm from "./AltaProveedor.Form";

const AltaBajaProveedores = () => {
  const tituloAProveedor = "Alta de Proveedores";

  return (
    <CardNavigate title={tituloAProveedor}>
      <AltaProveedorForm/>
    </CardNavigate>
  );
};

export default AltaBajaProveedores;
