import React from 'react'

const ReportesGastos = () => {
  return (
    <div>
      ReportesGastos
    </div>
  )
}

export default ReportesGastos
