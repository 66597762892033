import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export function createOption(key, value, label) {
    return (
      <option className="text-base" key={key} value={value}>
        {label}
      </option>
    )
  }

  export function parseNullToEmpty(obj) {
    const newObj = {};
    for (const key in obj) {
      newObj[key] = obj[key] ?? ""; 
    }
    return newObj;
  }

  export function obtenerFechaActual(format = 'YYYY-MM-DD') {
    // Extender Day.js con el plugin para manejar formatos personalizados
  dayjs.extend(customParseFormat);
    const fechaActual = dayjs().format(format); // Formato compatible con input type="date"

    // Convertir a formato YYYY-MM-DD
    const fechaFormatoCorrecto = dayjs(fechaActual, 'YYYY-MM-DD').format('DD/MM/YYYY');

    return fechaFormatoCorrecto;
  }

  export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));