import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { createUserSlice } from './userSlice';
import { createSalidasManifiestoSlice } from './salidasManifiestoSlice';
import { createAuthSlice } from './authSlice';
import { createAppSlice } from './appSlice';

export const useAppStore = create(
  devtools(
    persist(
      (set, get) => ({
        // combines slices
        ...createAppSlice(set,get),
        ...createAuthSlice(set,get),
        ...createSalidasManifiestoSlice(set, get),
        ...createUserSlice(set, get),
      }),
      {
        name: 'main-storage', 
        // partialize / blacklist / etc si quieres filtrar
      }
    )
  )
);
