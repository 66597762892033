import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaAltaSalidaAlmacen = Yup.object({
    //id: Yup.string(),
  fInicio: Yup.date().required(MSJ_DATO_REQ),
  tipo: Yup.string().required(MSJ_DATO_REQ),
  ruta: Yup.string().required(MSJ_DATO_REQ),
  nombreRuta: Yup.string(),
  referencia: Yup.string().required(MSJ_DATO_REQ)
});

export default schemaAltaSalidaAlmacen;
