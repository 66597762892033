import { pdf } from '@react-pdf/renderer';
import ReporteGastosViajePDF from './ReporteGastosViajePDF';
import { datos } from '../../Constantes';

const generarPDFGastosViaje = async (datosReporte) => {

    console.log('estoy dentro del generador de pdf y los datos son :', datosReporte);
    const pdfInstance = pdf(<ReporteGastosViajePDF datosReporte={datosReporte} />);
    const blob = await pdfInstance.toBlob();
    console.log('estoy dentro del generador de pdf')
    // Descargar el PDF
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Reporte_Gastos_Viaje.pdf';
    link.click();
};

export default generarPDFGastosViaje;